import React, {Component} from 'react'
import {Media, CardHeader, Card, CardBody, Col, Row} from 'reactstrap'
import {translate} from 'react-i18next'

import logo from '../../assets/img/brand/oms.svg'

class GDPR extends Component {
  render() {
    const {t} = this.props
    return (
      <div className="pt4">
        <Row>
          <Col>
            <Card>
              <CardHeader>
                <i className="fa fa-info"></i><strong>{t('mainheader')}</strong>
              </CardHeader>
              <CardBody className="tj pt4 pb4 pr7 pl7">
                <Media>
                  <Media left href="#">
                    <Media object src={logo} className="pull-left" alt="oms log" style={{height: '100px'}} />
                  </Media>
                  <Media body className="text-right">
                    <Media heading>
                      <i>{t('common.portal')}</i>
                    </Media>
                    <h1><b>{t('common.protection')}</b></h1>
                  </Media>
                </Media>
                <hr className="gdpr-hr"/>
                <h1 className="pt4">{t('p1')}</h1>
                <p>{t('p1sp1p1')}</p>
                <p>{t('p1sp1p2')}</p>
                <p>{t('p1sp1p3')}</p>  
                <h1 className="pt4">{t('p2')}</h1>       
                <p>{t('p2sp2p1')}</p>
                <p className="mb0">{t('p2sp2p2')}</p>
                <p className="mb0">{t('p2sp2p3')}</p>
                <p className="mb0">{t('p2sp2p4')}</p>
                <p>{t('p2sp2p5')}</p>
                <p>{t('p2sp2p6')}</p>               
                <h1 className="pt4">{t('p3')}</h1>
                <p>{t('p3sp1p1')}</p>
                <p className="mb0">{t('p3sp1p2')}</p>
                <p className="mb0">{t('p3sp1p3')}</p>
                <p className="mb0">{t('p3sp1p4')}</p>
                <p>{t('p3sp1p5')}</p>
                <p>{t('p3sp1p6')}</p>
                <p className="mb0">{t('p3sp1p7')}</p>
                <h1 className="pt4">{t('p4')}</h1>               
                <p>{t('p4sp1p1')}</p>
                <p>{t('p4sp1p2')}</p>
                <p className="mb0"><b>{t('p4sp2')}</b></p>
                <p>{t('p4sp2p1')}</p>  
                <p className="mb0"><b>{t('p4sp3')}</b></p>
                <p>{t('p4sp3p1')}</p> 
                <p className="mb0"><b>{t('p4sp4')}</b></p>
                <p>{t('p4sp4p1')}</p> 
                <p className="mb0"><b>{t('p4sp5')}</b></p>
                <p>{t('p4sp5p1')}</p>
                <p className="mb0"><b>{t('p4sp6')}</b></p>
                <p>{t('p4sp6p1')}</p> 
                <p className="mb0"><b>{t('p4sp7')}</b></p>
                <p>{t('p4sp7p1')}</p>
                <p className="mb0"><b>{t('p4sp8')}</b></p>
                <p>{t('p4sp8p1')}</p> 
                <p className="mb0"><b>{t('p4sp9')}</b></p>
                <p>{t('p4sp9p1')}</p> 
                <p className="mb0"><b>{t('p4sp10')}</b></p>
                <p>{t('p4sp10p1')}</p> 
                <p className="mb0"><b>{t('p4sp11')}</b></p>
                <p>{t('p4sp11p1')}</p> 
                <h1 className="pt4">{t('p5')}</h1>               
                <p>{t('p5sp1')}</p>
                <p>{t('p5sp2')}</p>
                <p>{t('p5sp3')}</p>
                <p>{t('p5sp4')}</p>
                <p>{t('p5sp5')}</p>
                <h1 className="pt4">{t('p6')}</h1> 
                <h3 className="pt2">{t('p6sp1')}</h3> 
                <p>{t('p6sp1p1')}</p>
                <p>{t('p6sp1p2')}</p>
                <h3 className="pt2">{t('p6sp2')}</h3> 
                <p>{t('p6sp2p1')}</p>
                <ol>
                  <li>{t('p6sp2p2')}</li>
                  <li>{t('p6sp2p3')}</li>
                  <li>{t('p6sp2p4')}</li>
                  <li>{t('p6sp2p5')}</li>
                  <li>{t('p6sp2p6')}</li>
                  <li>{t('p6sp2p7')}</li>
                  <li>{t('p6sp2p8')}</li>
                </ol>
                <p>{t('p6sp2p9')}</p>
                <ol>
                  <li>{t('p6sp2p10')}</li>
                  <li>{t('p6sp2p11')}</li>
                  <li>{t('p6sp2p12')}</li>
                  <li>{t('p6sp2p13')}</li>                 
                </ol>
                <p>{t('p6sp2p14')}</p>
                <p>{t('p6sp2p15')}</p>
                <h1 className="pt4">{t('p7')}</h1> 
                <h3 className="pt2">{t('p7sp1')}</h3> 
                <p>{t('p7sp1p1')}</p>
                <p>{t('p7sp1p2')}</p>
                <p>{t('p7sp1p3')}</p>
                <p>{t('p7sp1p4')}</p>
                <p>{t('p7sp1p5')}</p>
                <h1 className="pt4">{t('p8')}</h1> 
                <h3 className="pt2">{t('p8sp1')}</h3> 
                <p>{t('p8sp1p1')}</p>
                <p>{t('p8sp1p2')}</p>
                <p>{t('p8sp1p3')}</p>
                <p>{t('p8sp1p4')}</p>
                <p>{t('p8sp1p5')}</p>
                <p>{t('p8sp1p6')}</p>
                <h3 className="pt2">{t('p8sp2')}</h3> 
                <p>{t('p8sp2p1')}</p>
                <h1 className="pt4">{t('p9')}</h1> 
                <h3 className="pt2">{t('p9sp1')}</h3> 
                <p>{t('p9sp1p1')}</p>
                <h3 className="pt2">{t('p9sp2')}</h3> 
                <p>{t('p9sp2p1')}</p>
                <h3 className="pt2">{t('p9sp3')}</h3> 
                <p>{t('p9sp3p1')}</p>
                <h3 className="pt2">{t('p9sp4')}</h3> 
                <p>{t('p9sp4p1')}</p>
                <h3 className="pt2">{t('p9sp5')}</h3> 
                <p>{t('p9sp5p1')}</p>
                <h3 className="pt2">{t('p9sp6')}</h3> 
                <p>{t('p9sp6p1')}</p>
                <p>{t('p9sp6p2')}</p>
                <h3 className="pt2">{t('p9sp7')}</h3> 
                <p>{t('p9sp7p1')}</p>
                <p>{t('p9sp7p2')}</p>
                <p>{t('p9sp7p3')}</p>
                <p>{t('p9sp7p4')}</p>
                <p>{t('p9sp7p5')}</p>
                <p>{t('p9sp7p6')}</p>
                <h3 className="pt2">{t('p9sp8')}</h3> 
                <p>{t('p9sp8p1')}</p>
                <h3 className="pt2">{t('p9sp9')}</h3> 
                <p>{t('p9sp9p1')}</p>
                <h3 className="pt2">{t('p10')}</h3> 
                <p>{t('p10sp1')}</p>
                <p>{t('p10sp2')}</p>
                <h3 className="pt2">{t('p11')}</h3> 
                <p>{t('p11sp1')}</p>      
                <h3 className="pt2">{t('p12')}</h3> 
                <p>{t('p12sp1')}</p>
                <p>{t('p12sp2')}</p>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

export default translate('gdpr', {wait: true})(GDPR)
