import React, { Component } from 'react'
import { ErrorBoundary } from '../../../components'
import ButterToast from 'butter-toast'

import AssetDetailView from '../../../components/PublicAssetDetail/PublicAssetDetailView'

class PublicAssetDetail extends Component {
    render() {               
        return (
            <div>
                <ErrorBoundary>
                    <AssetDetailView {...this.props } />
                    <ButterToast trayPosition="top-center" />
                </ErrorBoundary>
            </div>
        )
    }
}

export default PublicAssetDetail