import gql from 'graphql-tag'

/* QUERIES */

const GET_LOCATIONS_QUERY = gql`
query{
  locations{
    floors
    rooms
    sites
    buildings
  }
}
`
const CUSTOMER_PROJECTS_QUERY = gql`
query($selfTested: Boolean){
  customerProjects(selfTested:$selfTested){
    id
    caption
    selfTested
  }
}
`

const PROJECTS_QUERY = gql`
 query($params:ProjectParams) {
  userProjects(params: $params) {
    projects {
      id,
      caption,
      crmId,
      branchOfficeName,
      customerName,
      status,
      statistics {
        totalExported,
        totalFailed,
      },
      testDates
    },
    pager {
      page,
      pageSize,
      totalCount
    }
  }
}`

const PROJECT_DETAILS_QUERY = gql` 
  query($projectId: String!) {
    projectDetails(projectId: $projectId) {
      id,
      caption,
      statistics {
        totalExported,
        totalFailed,
        totalWithComments
      },
      resultGroups {
        id,
        approvedRevision,
        status,
        statistics {
          totalExported,
          totalFailed,
          totalWithComments
        }
      }
    }
}`

const PROJECT_RESULT_GROUPS = gql`
  query($resultGroups: [ID]) {
    resultGroups(resultGroups: $resultGroups) {
      id,
      caption,
      statistics{
        totalExported,
        totalFailed,
        totalWithComments
      },
      approvedRevision {
        id,
        status,
        uploadedFiles {
          fileId,
          category,
          approved,
          filename,
          fileExtension, 
          fileSize,
          downloadFileId,
          downloadType
        },
        generatedFiles {
          artifact,
          category,
          approved,
          filename,
          fileExtension, 
          fileSize,
          downloadFileId,
          downloadType
        }
      },
      status
    }
}`

const DOWNLOAD_RESULT_GROUP_FILE_QUERY = gql`
  mutation downloadResultGroupFile($downloadFileId: ID!,$filename:String!,$downloadType:DownloadType!) {
    downloadResultGroupFile(downloadFileId:$downloadFileId,filename:$filename,downloadType:$downloadType)
}`

const GET_SELECTED_PROJECT = gql`
query Project {
  Project @client {
    selectedProject,
  }
}
`

const GET_PROJECT_LIST_PAGER_DATA = gql`
query Project {
  Project @client {
    pager {
      totalCount,
      page,
      pageSize,
    }
  }
}
`

const GET_PROJECT_PARAMS = gql`
query Project {
  Project @client {
    searchString,
    pager {
      page,
      pageSize
    }
  }
}
`
const GET_CUSTOMER_LICENCES = gql`
query {
  licencedModules
}
`
const GET_MIGRATED_CUSTOMER = gql`
query {
  isCustomerMigrated{
    crmReference,
    elektraId
  }
}
`

/* MUTATIONS */

const PERSIST_SELECTED_PROJECT_STATE_MUTATION = gql`
  mutation SelectedProjectMutation($id: String!) {
    SetSelectedProject(id: $id) @client
  }
`

const PERSIST_SEARCH_PROJECT_STRING_STATE_MUTATION = gql`
  mutation SearchStringMutation($searchString: String!)  {
    SetSearchString(searchString: $searchString) @client
  }
`

const PERSIST_PROJECT_LIST_COUNT_STATE_MUTATION = gql`
  mutation ProjectListCountMutation($totalCount: Int!)  {
    SetProjectCount(totalCount: $totalCount) @client
  }
`

const PERSIST_PROJECT_PAGE_STATE_MUTATION = gql`
  mutation ProjectPageMutation($page: Int!)  {
    SetProjectPage(page: $page) @client
  }
`



const Queries = {
  PROJECTS_QUERY,
  PROJECT_DETAILS_QUERY,
  PROJECT_RESULT_GROUPS,
  GET_SELECTED_PROJECT,
  GET_PROJECT_LIST_PAGER_DATA,
  GET_PROJECT_PARAMS,
  CUSTOMER_PROJECTS_QUERY,
  GET_LOCATIONS_QUERY,
  GET_CUSTOMER_LICENCES,
  GET_MIGRATED_CUSTOMER
}
const Mutations = {
  PERSIST_SELECTED_PROJECT_STATE_MUTATION,
  PERSIST_SEARCH_PROJECT_STRING_STATE_MUTATION,
  PERSIST_PROJECT_LIST_COUNT_STATE_MUTATION,
  PERSIST_PROJECT_PAGE_STATE_MUTATION,
  DOWNLOAD_RESULT_GROUP_FILE_QUERY,
}

export {Queries, Mutations}

