import React, { Component } from 'react'
import { translate } from 'react-i18next'

import Consts from '../../constants'

class Language extends Component {
  constructor(props) {
    super(props)
    this.state = {
      systemLang: localStorage.getItem(Consts.I18N_LANGUAGE) && localStorage.getItem(Consts.I18N_LANGUAGE).substring(0, 2) === "en" ? "en" : 'de',
    }
  }

  isActive = (lang) => {
    return (this.state.systemLang.indexOf(lang) > -1).toString()
  }

  changeLanguage = (lang) => {
    this.props.i18n.changeLanguage(lang)
    this.setState(prevState => ({
      systemLang: localStorage.getItem(Consts.I18N_LANGUAGE) === "en" ? "en" : "de",
      mainMenuOpened: !prevState.mainMenuOpened,
    }))

  }

  render() {
    const { t } = this.props
    const langIcon = this.state.systemLang === "en" ? "gb" : "de"
    return (
      <div className="sl-nav">
        <ul>
          <li><i className={'flag-icon flag-icon-' + langIcon }></i><i className="fa fa-angle-down ml1" aria-hidden="true"></i>
            <ul>
              <li onClick={() => this.changeLanguage('de')}>
                <i className="flag-icon flag-icon-de">
                  <div id="germany"></div>
                </i>
                <span active={this.isActive('de')}>{t('viewer.german')}</span>
              </li>
              <li onClick={() => this.changeLanguage('en')}>
                <i className="flag-icon flag-icon-gb">
                  <div id="en"></div>
                </i>
                <span active={this.isActive('en')}>{t('viewer.english')}</span>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    )
  }
}


export default translate()(Language)
