import React, {Component} from 'react'
import {Card, CardBody, CardHeader, NavLink, Collapse} from 'reactstrap'

const AccordionList = (Collection, WrappedComponent, titleField) => {
  return class HOC extends Component {

    constructor(props) {
      super(props)
      this.state = {collapse: 0}
    }

    componentDidMount() {
      this.setState({collapse: 1})
    }

    toggle = (e) => {
      e.preventDefault()

      let event = e.target.dataset.event
      this.setState({collapse: this.state.collapse === Number(event) ? 0 : Number(event)})
    }

    render() {
      const {collapse} = this.state
      return (
        Collection.map((item, i) => {
          const itemKey = i + 1
          const collapseIcon = collapse === itemKey ? 'fa-chevron-down' : 'fa-chevron-right'

          return (
            <Card className="mb-2 animated fadeIn" key={itemKey}>
              <CardHeader onClick={this.toggle}>
                <NavLink onClick={this.toggle} href="#" className="black pa0" data-event={itemKey}>
                  <i className={'fa ' + collapseIcon} data-event={itemKey}></i>
                  <span className="ml-2 f5 mb0 pa0" data-event={itemKey}>{titleField ? item[titleField] : 'Accordion ' + itemKey}</span>
                </NavLink>
              </CardHeader>
              <Collapse isOpen={collapse === itemKey}>
                <CardBody>
                  <WrappedComponent {...item} />
                </CardBody>
              </Collapse>
            </Card>
          )
        })
      )
    }
  }
}

export default AccordionList
