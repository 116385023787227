import gql from 'graphql-tag'

/* MUTATIONS */

const RESET_PASSWORD_MUTATION = gql`
mutation resetPassword($email: String!) {
  resetPassword(email: $email)
}
`

const Queries = {}
const Mutations = {RESET_PASSWORD_MUTATION}

export {Queries, Mutations}

