import gql from 'graphql-tag'

/* QUERIES */

const SALES_PERSON_QUERY = gql`
 query($projectId: String!){
  projectSalesPerson(projectId: $projectId) {
    id
    name
    salesPersonRole
    email
    phoneNumber{
      type
      number
    },
    avatar
  }
}`

const BRANCH_QUERY = gql`
 query($projectId: String!){
  projectBranch(projectId: $projectId) {
    id
    name
    email
    phoneNumber{
      type
      number
    }
  }
}`

const GET_SELECTED_PROJECT = gql`
  query Project {
    Project @client {
      selectedProject,
    }
}`

const Queries = {GET_SELECTED_PROJECT, SALES_PERSON_QUERY, BRANCH_QUERY}
const Mutations = {}

export {Queries, Mutations}

