import React, { Component } from 'react'
import { Col, Row } from 'reactstrap'
import { translate } from 'react-i18next'

class Project extends Component {

  render() {
    const { t, statistics } = this.props

    const percentage = (statistics.totalExported && statistics.totalFailed ?
      (statistics.totalFailed / statistics.totalExported * 100) : 0).toFixed(2)

    if (statistics.totalExported === 0) {
      return (
        <div>
          <Row>
            <Col xs>
              <Row className="mb-2 f4 fw5">{this.props.caption}   </Row>

              <Row>
                <Col className="col-6 p-0">
                  {this.props.customerName}
                </Col>
              </Row>

              <Row>
                <Col className="col-6 p-0">
                  {this.props.crmId}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      )
    } else {
      return (
        <div>
          <Row>
            <Col xs>
              <Row className="mb-2 f4 fw5">{this.props.caption}   </Row>
              <Row>
                <Col className="pb1">
                  {this.props.customerName}
                </Col>
              </Row>
              <Row>
                <Col className="pb1">
                  {this.props.crmId}
                </Col>
              </Row>
              <Row>
                <Col className="pb1">
                  {statistics.totalExported + ' ' + t('project.item.assetsText')}
                </Col>
              </Row>
              <Row>
                <Col className="pb1">
                  {percentage + ' % ' + t('project.item.failedPercent')}
                </Col>
              </Row>
              <Row>
                <Col className="pb1">
                  {this.props.testDates ? this.props.testDates : t('common.notavailable')}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      )
    }
  }
}

export default translate()(Project)
