import React, { Component } from 'react'
import { compose, graphql } from 'react-apollo'
import { translate } from 'react-i18next'
import { Queries } from '../ProjectGQL'
import { Card, CardBody, CardHeader, Row } from 'reactstrap'
import ProjectStatistics from './ProjectStatistics'
import Spinner from '../../../base/Spinner'
import ProjectResultGroups from './ProjectResultGroups'

class ProjectDetailsCardWrapper extends Component {
  render() {
    const { title, children } = this.props

    return (
      <Card className="mb-2">
        <CardHeader>
          <h4>{title}</h4>
        </CardHeader>
        <CardBody>
          {children}
        </CardBody>
      </Card>
    )
  }
}

class ProjectDetails extends Component {
  render() {
    const { t } = this.props

    const hasQueryStarted = this.props.projectDetailsQuery || null //eslint-disable-line
    const isQueryLoading = this.props.projectDetailsQuery && this.props.projectDetailsQuery.loading
    const data = this.props.projectDetailsQuery && this.props.projectDetailsQuery.projectDetails

    if (!hasQueryStarted) {
      return (null)
    } else if (isQueryLoading) {
      return (
        <ProjectDetailsCardWrapper title={t('project.details.title')}>
          <Spinner />
        </ProjectDetailsCardWrapper>
      )
    } else {
      const { statistics, resultGroups } = data

      return (
        <React.Fragment>
          <ProjectDetailsCardWrapper title={t('project.details.title')}>
            <Row className="mb-none">
            </Row>
            <ProjectStatistics statistics={statistics} />
          </ProjectDetailsCardWrapper>
          <ProjectResultGroups resultGroups={resultGroups}></ProjectResultGroups>
        </React.Fragment>
      )
    }
  }
}

export default translate()(compose(
  graphql(Queries.GET_SELECTED_PROJECT, {
    name: 'selectedProjectClientQuery',
  }),
  graphql(Queries.PROJECT_DETAILS_QUERY, {
    name: 'projectDetailsQuery',
    skip: (props) => {
      return !props.id && (!props.selectedProjectClientQuery ||
        !props.selectedProjectClientQuery.Project ||
        !props.selectedProjectClientQuery.Project.selectedProject)
    },
    options: (props) => (
      {
        variables: {
          projectId: props.id || props.selectedProjectClientQuery.Project.selectedProject,
        },
      }),
  })
)(ProjectDetails))
