import gql from 'graphql-tag'


const RISK_ASSESSMENT_QUERY = gql`

query($params: ProjectParams) {
  customerRiskAssessment(params: $params) {
    riskGroups {
      revision
      title
      testInterval
    }
    pager {
      page
      totalCount
      pageSize
    }
  }
}`

const EDIT_RISK_ASSESSMENT = gql`
query($revision: ID!) {
  riskAssessment(revision: $revision) {
    revision
    title
    testInterval
    choices{
      question{
        revision
        title
        questionText
        answers{
         answerId
         answerText 
        }
      }
      selectedAnswer
    }
    history{
      revision
      title
    }
  }
}
`

const RISK_ASSESSMENT_COUNT = gql`
query{
  riskAssessmentCount
}
`
const QUESTION = gql`
query($revision: ID) {
  answeredQuestion(revision: $revision) {
    answeredQuestions {
      question {
        questionText
        revision
        title
        answers {
          answerText
          answerId
        }
      }
      selectedAnswer
    }
    interval
  }
}
`
const CALCULATE_INTERVAL = gql`
query  calculateInterval($questions:[QuestionParam]){
  calculateInterval(questions: $questions)
}
`
const CREATE_RISK_ASSESSMENT = gql`
mutation createRiskAssessment($data: RiskAssessmentInput) {
  createRiskAssessment(data: $data) {
    revision
    version
  }
}
`

const GET_MIGRATED_CUSTOMER = gql`
query {
  isCustomerMigrated{
    crmReference,
    elektraId
  }
}
`

const Queries = {
  EDIT_RISK_ASSESSMENT,
  RISK_ASSESSMENT_QUERY,
  RISK_ASSESSMENT_COUNT,
  QUESTION,
  CALCULATE_INTERVAL,
  GET_MIGRATED_CUSTOMER
}
const Mutations = {
  CREATE_RISK_ASSESSMENT
}

export { Queries, Mutations }