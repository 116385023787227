import React from 'react'

export const ClockIcon = () => (
    <div>
        <svg width="150" height="150" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M74.9375 12.5C40.4375 12.5 12.5 40.5 12.5 75C12.5 109.5 40.4375 137.5 74.9375 137.5C109.5 137.5 137.5 109.5 137.5 75C137.5 40.5 109.5 12.5 74.9375 12.5ZM75 125C47.375 125 25 102.625 25 75C25 47.375 47.375 25 75 25C102.625 25 125 47.375 125 75C125 102.625 102.625 125 75 125Z" fill="#333333" fill-opacity="0.38" />
            <path d="M78.125 43.75H68.75V81.25L101.562 100.938L106.25 93.25L78.125 76.5625V43.75Z" fill="#333333" fillOpacity="0.38" />
        </svg>
    </div>
)

export const LockIcon = () => (
    <div>
        <svg width="150" height="150" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M112.5 58.5938V42.1875C112.5 32.2419 108.549 22.7036 101.517 15.671C94.4839 8.63838 84.9456 4.6875 75 4.6875C65.0544 4.6875 55.5161 8.63838 48.4835 15.671C41.4509 22.7036 37.5 32.2419 37.5 42.1875V58.5938H25.7812V96.0938C25.7812 123.233 47.8605 145.312 75 145.312C102.139 145.312 124.219 123.233 124.219 96.0938V58.5938H112.5ZM46.875 42.1875C46.875 34.7283 49.8382 27.5746 55.1126 22.3001C60.3871 17.0257 67.5408 14.0625 75 14.0625C82.4592 14.0625 89.6129 17.0257 94.8874 22.3001C100.162 27.5746 103.125 34.7283 103.125 42.1875V58.5938H46.875V42.1875ZM114.844 96.0938C114.844 118.063 96.9697 135.938 75 135.938C53.0303 135.938 35.1562 118.063 35.1562 96.0938V67.9688H114.844V96.0938Z" fill="#333333" fillOpacity="0.38" />
            <rect x="70" y="80" width="9" height="33" fill="#333333" fillOpacity="0.38" />
        </svg>
    </div>

)

export const RiskIcon = ({ color }) => (

    <div>
        <svg width="150" height="150" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 1.25C7.73205 1.25 7.945 1.37856 8.05308 1.58391L14.3031 13.4589C14.405 13.6526 14.3982 13.8856 14.2851 14.073C14.1719 14.2604 13.9689 14.375 13.75 14.375H1.25C1.03107 14.375 0.828102 14.2604 0.71495 14.073C0.601798 13.8856 0.594961 13.6526 0.696926 13.4589L6.94693 1.58391C7.055 1.37856 7.26795 1.25 7.5 1.25ZM2.28523 13.125H12.7148L7.5 3.21693L2.28523 13.125Z" fill={color} />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.17018 7.07241C6.20196 6.95587 6.30781 6.87503 6.4286 6.87503H8.30358C8.38763 6.87503 8.46681 6.91448 8.51742 6.98158C8.56804 7.04869 8.58422 7.13565 8.56113 7.21647L8.12298 8.75001H9.375C9.47893 8.75001 9.57349 8.81014 9.61757 8.90426C9.66165 8.99838 9.64731 9.1095 9.58077 9.18934L6.90223 12.4036C6.82225 12.4996 6.68656 12.5275 6.57518 12.471C6.4638 12.4144 6.4063 12.2884 6.4366 12.1672L6.8891 10.3571H5.62503C5.54155 10.3571 5.46284 10.3182 5.41217 10.2519C5.3615 10.1855 5.34465 10.0994 5.36662 10.0188L6.17018 7.07241ZM6.63319 7.41074L5.97572 9.82143H7.23216C7.31465 9.82143 7.39253 9.85943 7.44329 9.92445C7.49405 9.98946 7.51203 10.0742 7.49202 10.1543L7.24031 11.1611L8.80312 9.28572H7.76787C7.68382 9.28572 7.60465 9.24627 7.55403 9.17917C7.50342 9.11207 7.48723 9.0251 7.51032 8.94428L7.94848 7.41074H6.63319Z" fill={color} />
        </svg>
    </div>

)







