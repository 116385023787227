import React, { Component } from "react";
import "./DashboardCard.css";
export default class DashboardCard extends Component {
  render() {
    return (
      <div className="emptyCard">
        <div className="H5-Rubik-Regular">{this.props.title}</div>
        <hr className="cardTitle"></hr>
        <div className="cardBody">
          <div>{this.props.body}</div>
        </div>
      </div>
    );
  }
}
