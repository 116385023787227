import React, { Component } from 'react'
import { Row, Col } from 'reactstrap'
import { translate } from 'react-i18next'
import { compose, graphql, Mutation } from 'react-apollo'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment/moment.js'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Mutations } from './TestResultGQL'

import { Queries } from '../Project/ProjectGQL'
import Consts from '../../constants'
import FormStepTwo from './FormStepTwo'
import FormStepOne from './FormStepOne'
import Spinner from '../../base/Spinner'

const testResult =
{
    id: "testresult-guid",
    date: "2029-02-19T03:00:00Z",
    result: true,
    asset: {
        id: "CV-2379-guid",
        projectId: "tenant-1/project-137",
        location: { site: "", building: "", floor: "", room: "R01" },
        manufacturer: { id: "" },
        deviceType: { name: "" },
        riskGroup: { testInterval: 10 },
        comment: ""
    },
    tester: { performedBy: { id: "" } },
    isComplete: true,
    testProfile: "LeadSk1",
    testProfileOptions: [
    ],
    testResultInfo: {
        applianceClass: "SK1",
        classification: "-"
    },
    testEquipment: {
        model: "-",
        serialNumber: "-",
        firmwareVersion: "-",
        appVersion: "-",
        probeVersion: "",
        deviceId: "",
    },
    steps: [

    ],
    approval: { testResultHash: "-" }
}

const initialState = {
    suggestions: {
        sites: [],
        buildings: [],
        rooms: [],
        floors: []
    },
    invalidAsset: false,
    assetId: '',
    assetType: '',
    manufacturer: '',
    site: '',
    building: '',
    floor: '',
    room: '',
    username: '',
    formStep: 0,
    project: "",
    testInterval: "",
    submitting: false,
    valid: false,
    questions:
    {
        mark: true,
        operation: true,
        suitable: true,
        setup: true,
        inscription: true,
        housing: true,
        line: true,
        plug: true,
        protective: true,
        function: true,
        general: true,
    },
    comments: {
        mark: {
            text: '',
            visible: false,
            type: "visual-inspection|provision-ce-sign"
        },
        operation: {
            text: '',
            visible: false,
            type: "visual-inspection|provision-operating-instruction"
        },
        suitable: {
            text: '',
            visible: false,
            type: "visual-inspection|provision-suitability-task-site"
        },
        setup: {
            text: '',
            visible: false,
            type: "visual-inspection|provision-commissioning"
        },
        inscription: {
            text: '',
            visible: false,
            type: "visual-inspection|provision-labeling"
        },
        housing: {
            text: '',
            visible: false,
            type: "visual-inspection|provision-case"
        },
        line: {
            text: '',
            visible: false,
            type: "visual-inspection|provision-mains-lead"
        },
        plug: {
            text: '',
            visible: false,
            type: "visual-inspection|provision-mains-plug"
        },
        protective: {
            text: '',
            visible: false,
            type: "visual-inspection|provision-func-protective-devices"
        },
        function: {
            text: '',
            visible: true,
            type: "visual-inspection|provision-func"
        },
        general: {
            text: '',
            visible: false
        }


    },
}

class ProvisionInspection extends Component {
    constructor(props) {
        super(props)
        const state = { ...initialState }
        state.username = props.username
        state.assetId = props.match.params && props.match.params.id ? props.match.params.id : ''

        this.state = state
    }

    success() {
        return toast.info('This asset has been successfully tested. Thank you. Test results will be available soon.', {});
    }

    error(message) {
        // add type: 'success' to options
        // positioning: https://github.com/fkhadra/react-toastify#positioning-toast
        return toast.error(message, {
        });
    }

    generateTestResult() {
        const newTestResult = Object.assign({}, testResult)

        const { valid, project, assetId, assetType, manufacturer, comments, site, building, floor, room,
            testInterval, questions } = this.state
        const { id } = this.props
        newTestResult.id = `testresult-${uuidv4()}`
        newTestResult.date = moment.utc().format()
        newTestResult.result = valid
        newTestResult.asset.id = assetId
        newTestResult.asset.projectId = project
        newTestResult.asset.deviceType.name = assetType
        newTestResult.asset.manufacturer.id = manufacturer
        newTestResult.asset.comment = comments.general.text
        newTestResult.tester.performedBy.id = id
        newTestResult.asset.location = {
            site,
            building,
            floor,
            room
        }
        newTestResult.asset.riskGroup.testInterval = parseInt(testInterval, 10)

        Object.keys(comments).filter(key => key !== 'general').map(key => {

            newTestResult.steps.push({
                stepType: comments[key].type,
                result: questions[key],
                comment: comments[key].text
            })
            return null
        })

        return newTestResult
    }

    async handleSubmit(e, testResultUpload) {

        this.setState({ submitting: true })
        try {
            const newTestResult = this.generateTestResult()
            const result = await testResultUpload({
                variables: {
                    data: [
                        newTestResult
                    ]
                }
            })
            const { errors } = result.data.testResultUpload
            if (errors && errors.length) {
                this.error(errors[0].message || 'There was an error submitting the test result.')
            } else {
                this.success()
                setTimeout(() => window.location.href = "/provision", 1000)
            }

        } catch (e) {
            console.log('err:', e)
        }
    }

    resetForm() {
        const state = { ...initialState }
        state.username = this.props.username
        this.setState(state)
    }

    handleQuestionChange(e) {
        const { value, name } = e.target
        const questions = Object.assign({}, this.state.questions)
        questions[name] = value === Consts.NO_ANSWER_GERMAN ? false : true
        this.setState({ questions, valid: this.validForm(questions) })
    }

    validForm(questions) {
        if (!this.state) return true

        return !Object.keys(questions).some(key => questions[key] === false)
    }
    handleTestIntervalChange(e) {
        const val = e.target.value
        this.setState({ testInterval: val.trim() }, () => this.toggleCss(Consts.TEST_INTERVAL))

    }

    handleProjectChange(e) {
        const val = e.target.value
        this.setState({ project: val.trim() }, () => this.toggleCss(Consts.PROJECT, val))
    }


    toggleCss(name) {

        const elm = document.getElementById(name)
        const elmText = this.state[name]

        if (elmText === "") {
            elm.classList.add(Consts.INVALID_CLASS_NAME)
        }
        else {
            elm.classList.remove(Consts.INVALID_CLASS_NAME)
        }
    }

    validateForm(e) {

        const { project, testInterval, room, assetId, assetType, manufacturer } = this.state

        const hasAssetError = document.getElementById('error-message').innerText
        this.toggleCss(Consts.PROJECT)
        this.toggleCss(Consts.TEST_INTERVAL)
        this.toggleCss(Consts.ROOM)
        this.toggleCss(Consts.ASSET_ID)
        this.toggleCss(Consts.ASSET_TYPE)
        this.toggleCss(Consts.MANUFACTURER)

        if (project === "" || testInterval === "" || room === "" || assetId === ""

            || assetType === "" || manufacturer === "" || hasAssetError) return

        this.setState({ formStep: 1, valid: true })
    }

    handleNext(e) {
        const projectSelect = document.getElementById("project")
        if (projectSelect && projectSelect.options.length === 1) {
            this.setState({ project: projectSelect.options[projectSelect.selectedIndex].value },
                (e) => {
                    this.validateForm(e)
                }
            )

        } else {
            this.validateForm(e)
        }
    }

    handleBackButton(e) {
        e.preventDefault()
        this.setState({ formStep: 0 })
    }

    handleCommentButton(name) {
        const comments = Object.assign({}, this.state.comments)
        const newVal = !comments[name].visible
        comments[name].visible = newVal
        this.setState(comments)

        const commentInput = document.getElementById(`${name}Group`)
        if (newVal) {
            commentInput.classList.remove(Consts.DISPLAY_NONE)
            commentInput.classList.add(Consts.DISPLAY_BLOCK)
        } else {
            commentInput.classList.remove(Consts.DISPLAY_BLOCK)
            commentInput.classList.add(Consts.DISPLAY_NONE)
        }

    }
    handleCommentChange(e, name) {
        const comments = Object.assign({}, this.state.comments)
        comments[name].text = e.target.value

        this.setState({ comments })

    }

    handleLocationChange(name, value) {

        this.setState({
            [name]: value.trim()
        }, () => {
            if (name === Consts.ROOM) {
                this.toggleCss(name)
            }
        })
    }
    handleInputChange(e) {
        const { name, value } = e.target

        this.setState({
            [name]: value
        }, () => {
            if (name === Consts.ROOM || name === Consts.MANUFACTURER || name === Consts.ASSET_TYPE || name === Consts.ASSET_ID) {
                this.toggleCss(name)
            }
        }

        )

    }

    getComment(name) {
        return this.state && this.state.comments && this.state.comments[name] ? this.state.comments[name] : ''
    }


    render() {

        const { t, username, flags, role } = this.props
        const { comments, testInterval, assetId, assetType, manufacturer, site,
            building, floor, room, project, formStep, valid, questions, submitting } = this.state

        const hasQueryStarted = this.props.projectQuery.loading || this.props.locationsQuery.loading || this.props.licencedModules.loading
        if (hasQueryStarted) {
            return <div className="mv7">
                <Spinner />
            </div>
        }

        let data = !this.props.projectQuery.customerProjects ?
            { projects: [] } :
            { projects: this.props.projectQuery.customerProjects }

        if (this.props.licencedModules.licencedModules) {
            data = { ...data, licencedModules: this.props.licencedModules.licencedModules }
        }

        if (this.props.locationsQuery.locations) {
            data.locations = this.props.locationsQuery.locations
        }

        const containerStyle = {
            zIndex: 1999
        }
        if (!flags.showProvision || role !== Consts.CONTACT_ROLE || data.licencedModules.indexOf(Consts.PROVISION) === -1) return null

        return (
            <Mutation mutation={Mutations.TEST_RESULT_UPLOAD}>
                {(testResultUpload, { loading, error }) => {
                    return (
                        <React.Fragment>

                            <ToastContainer position="top-right" autoClose={5000} style={containerStyle} />
                            <Row>
                                <Col lg="8" sm="12">
                                    <h1 className="pl2 mv4 fw4">{t('pageTitle')}</h1>
                                    <p>{t("subTitle")}</p>

                                    {
                                        formStep ?
                                            <FormStepTwo
                                                t={t}
                                                comments={comments}
                                                submitting={submitting}
                                                formStep={formStep}
                                                valid={valid}
                                                questions={questions}
                                                handleCommentButton={(name) => this.handleCommentButton(name)}
                                                handleCommentChange={(e, name) => this.handleCommentChange(e, name)}
                                                handleQuestionChange={(e) => this.handleQuestionChange(e)}
                                                handleBackButton={(e) => this.handleBackButton(e)}
                                                handleSubmit={(e) => this.handleSubmit(e, testResultUpload)}
                                                getComment={(name) => this.getComment(name)}
                                            />
                                            :
                                            <FormStepOne
                                                t={t}
                                                locations={data.locations}
                                                assetId={assetId}
                                                assetType={assetType}
                                                manufacturer={manufacturer}
                                                site={site}
                                                building={building}
                                                floor={floor}
                                                room={room}
                                                username={username}
                                                project={project}
                                                projects={data.projects}
                                                testInterval={testInterval}
                                                handleProjectChange={(e) => this.handleProjectChange(e)}
                                                handleTestIntervalChange={(e) => this.handleTestIntervalChange(e)}
                                                handleNext={() => this.handleNext()}
                                                handleInputChange={(e) => this.handleInputChange(e)}
                                                handleAssetIdChange={(e) => this.handleAssetIdChange(e)}
                                                handleLocationChange={(name, value) => this.handleLocationChange(name, value)}
                                            />
                                    }

                                </Col>
                            </Row>
                        </React.Fragment>
                    )
                }}

            </Mutation >
        )
    }
}

export default translate('provisionInspection', { 'wait': true })(compose(
    graphql(Queries.CUSTOMER_PROJECTS_QUERY, {
        name: 'projectQuery',
        options: () => (
            {
                variables: {
                    selfTested: true
                },
            }),
    }),
    graphql(Queries.GET_LOCATIONS_QUERY, {
        name: 'locationsQuery'
    }),
    graphql(Queries.GET_CUSTOMER_LICENCES, {
        name: 'licencedModules'
    })
)(ProvisionInspection))




