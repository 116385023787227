
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav,
} from '@coreui/react'

// sidebar nav config
import navigation from './_nav'

const propTypes = {
  children: PropTypes.node,
}

const defaultProps = {}

class DefaultSidebar extends Component {

  render() {
    // eslint-disable-next-line
    const { children, ...attributes } = this.props;

    return (
      <React.Fragment>
        <AppSidebarHeader />
        <AppSidebarForm />
        <AppSidebarNav navConfig={navigation} {...this.props} />
        <AppSidebarFooter />
        <AppSidebarMinimizer />
      </React.Fragment>
    )
  }
}

DefaultSidebar.propTypes = propTypes
DefaultSidebar.defaultProps = defaultProps

export default DefaultSidebar


