import React, { Component } from 'react'
import { translate } from 'react-i18next'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import LatestProjectResultsCard from './LatestProjectResults'

import DueDate from '../DueDate/DueDate'

class UserDashboard extends Component {

    render() {
        const { flags, t } = this.props
        return flags.showDashboard ? (
            <React.Fragment>
                <div className="userdashboard">{t('dashboard')}</div>

                <DueDate />
                <LatestProjectResultsCard t={t} />

            </React.Fragment>
        )
            : null
    }
}

export default withLDConsumer()(translate('dashboard')(UserDashboard))




