import {Dashboard, GDPR, FAQ, Imprint} from '../../../../components'
import DefaultLayout from '../DefaultLayout'
// import Asset from '../../Asset'
import CsmDashboard from '../../CsmDashboard/CsmDashboard'
import UserDashboard from '../../../../components/UserDashboard'
import AssetDetailView from '../../../../components/AssetDetail/AssetDetailView'
import Asset from '../../../../components/Asset/AssetView'
import ProvisionInspection from '../../../../components/ProvisionInspection/ProvisionInspection'
import RiskAssessment from '../../../../components/RiskAssessment/RiskAssessment'


const routes = [
  {path: '/', breadcrumb: '/', exact: true, name: 'routes:home', component: DefaultLayout},
  {path: '/assets', exact:  true, breadcrumb: '/assets', name: 'routes:assets', component: Asset},
  {path: '/assets/:id?', breadcrumb: '/assets', name: 'routes:assetDetail', component: AssetDetailView},
  {path: '/projects/:id?', breadcrumb: '/projects', name: 'routes:projects', component: Dashboard},
  {path: '/gdpr', breadcrumb: '/gdpr', name: 'routes:gdpr', component: GDPR},
  {path: '/imprint', breadcrumb: '/gdpr', name: 'routes:imprint', component: Imprint},
  {path: '/help', breadcrumb: '/gdpr', name: 'routes:faq', component: FAQ},
  { path: '/csm', breadcrumb: '/csm', name: 'routes:csm', component: CsmDashboard },
  {path: '/userdashboard', name: 'routes:userdashboard', component: UserDashboard },
  {path: '/provision/:id', name: 'routes:provision', component: ProvisionInspection },
  {path: '/provision', name: 'routes:provision', component: ProvisionInspection },
  {path: '/riskAssessment', name: 'routes:riskgroup', component: RiskAssessment }
]

export default routes
