import React from 'react'
import {Breadcrumb, BreadcrumbItem} from 'reactstrap'
import {withRouter} from 'react-router-dom'
import {translate} from 'react-i18next'

import routes from './ComponentRouter/routes'

const DefaultBreadCrumb = ({t, match, location}) => {

  const getPaths = (pathname) => {
    let paths = ['/']

    if (pathname === '/') return paths

    pathname.split('/').reduce((prev, curr) => {
      let currPath = prev + '/' + curr
      paths.push(currPath)
      return currPath
    })

    return paths
  }

  return (
    <Breadcrumb tag="nav">
      <div className="max-width">
        {
          getPaths(location.pathname).map((path, idx) => {
            const route = routes.find(r => {return r.breadcrumb === path})

            return route ?
              <BreadcrumbItem key={idx} tag="a" href={route.breadcrumb}>{t(route.name)}</BreadcrumbItem> :
              <BreadcrumbItem key={idx} tag="a">{path.slice(path.lastIndexOf('/') + 1, path.length)}</BreadcrumbItem>
          })
        }
      </div>
    </Breadcrumb>
  )

}

export default translate()(withRouter(DefaultBreadCrumb))
