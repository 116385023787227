import React, { Component } from 'react'
import { Nav, NavItem, Media } from 'reactstrap'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { AppNavbarBrand, AppSidebarToggler } from '@coreui/react'

import logo from '../../../assets/img/brand/oms-logo.png'
import search from '../../../assets/img/search/vector.svg'
import Viewer from '../../../components/Viewer'
import Language from '../../../components/Language'
import Help from '../../../components/Help'
import AssetTab from '../../../components/AssetTab'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import SearchContainer from '../../../components/SearchContainer'
import Search from '../../../components/Search'

const propTypes = {
  children: PropTypes.node,
}

const defaultProps = {}

class DefaultHeader extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isMobile: false
    }
  }
  updatePredicate = () => {
    this.setState({
      isMobile: window.innerWidth <= 768
    })
  }
  componentDidMount() {
    this.updatePredicate()
    window.addEventListener("resize", this.updatePredicate)
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updatePredicate)
  }
  render() {

    // eslint-disable-next-line
    const { name, avatar, client, flags, children, ...attributes } = this.props

    return (
      <React.Fragment>
        <AppSidebarToggler className="d-lg-none" display="md" mobile />
        <AppNavbarBrand tag={Link} to={"/projects"}
          full={{ src: logo, width: 88, height: 30, alt: 'OMS Customer Portal' }}
          minimized={{ src: logo, width: 30, height: 30, alt: 'Customer Portal' }}
        />
        <AppSidebarToggler className="d-md-down-none" display="lg" />
        {
          flags.searchAssets && !this.state.isMobile ?
            <SearchContainer>
              <Search width="500px" isMobile={this.state.isMobile}/>
            </SearchContainer>
            :
            flags.searchAssets ?
            <div className="search-button pr3 pt4">
              <Link to="/search">
                <Media>
                  <Media object src={search} alt="search" style={{ height: '28px' }} />
                </Media>
              </Link>
            </div>
            : null
        }
        {/* <AppSidebarToggler className="d-md-down-none" display="lg" /> */}
        {/* <AppSidebarToggler className="d-none" display="lg" /> */}
        {
          !this.state.isMobile ?
            <Nav className="ml-auto pr2 fr mt3" navbar>
              {

                flags.assetNavigationTab ?
                  <NavItem>
                    <AssetTab />
                  </NavItem>
                  : null

              }
              <NavItem>
                <Help />
              </NavItem>
              <NavItem>
                <Language />
              </NavItem>
              <NavItem>
                <Viewer name={name} avatar={avatar} client={client}/>
              </NavItem>
            </Nav>

            : null
        }
        {/* <AppAsideToggler className="d-none" /> */}
        {/* <AppAsideToggler className="d-md-down-none" /> Switching off for all devices with resolution md and lower */}
        {/* <AppAsideToggler className="d-lg-none" mobile /> */}
      </React.Fragment>
    )
  }
}

DefaultHeader.propTypes = propTypes
DefaultHeader.defaultProps = defaultProps

export default withLDConsumer()(DefaultHeader)
