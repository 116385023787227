import React, {Component} from 'react'

import PublicAssetDetail from './PublicAssetDetail/PublicAssetDetail'

class PublicAssetDetailView extends Component{
 
    render(){
        return(
            <PublicAssetDetail {...this.props }/>
        )
    }
}

export default PublicAssetDetailView
