
import usersnapen from '../locale/en/usersnap.json'
import usersnapde from '../locale/de/usersnap.json'
import Consts from '../constants'
import _ from 'lodash'

export default class UserSnap {
  constructor(Config) {
    if (UserSnap.instance) {
      return UserSnap.instance
    }
    UserSnap.instance = this

    this.key = Config.userSnapGlobalKey;
    return this;
  }

  getConfig = () => {
    const getLang = () => {
      return localStorage.getItem(Consts.I18N_LANGUAGE)
        ? localStorage.getItem(Consts.I18N_LANGUAGE).substring(0, 2)
        : "de";
    };

    return {
      button: {
        icon: null,
        label: {
          en: usersnapen.buttonText,
          de: usersnapde.buttonText,
        },
      },
      locale: getLang(),
      fields: {
        title: {
          label: {
            en: usersnapen.title,
            de: usersnapde.title,
          },
        },
        comment: {
          label: {
            en: usersnapen.comment,
            de: usersnapde.comment,
          },
        },
        email: {
          label: {
            en: usersnapen.email,
            de: usersnapde.email,
          },
        },
        assignee: {
          label: {
            en: usersnapen.assignee,
            de: usersnapde.assignee,
          },
        },
        label: {
          label: {
            en: usersnapen.label,
            de: usersnapde.label,
          },
        },
      },
    }
  }

  setup = (callback) => {
    window.UserSnap = this

    window.onUsersnapLoad = (api) => {
      api.init();
      window.UserSnap.api = api;   
    };



    const existingScript = document.getElementById('usersnap-scr')

    if (!existingScript) {
      const script = document.createElement("script");

      script.defer = 1;
      script.src = `https://widget.usersnap.com/global/load/${this.key}?onload=onUsersnapLoad`; 
      script.id = "usersnap-scr"; 
      document.getElementsByTagName("head")[0].appendChild(script);

      script.onload = () => {
        if (callback) callback()
      }
    }

    if (existingScript && callback) callback()
  }

}
