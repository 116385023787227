import React from 'react'
import { Container, Row, Col, Button } from 'reactstrap'

import { ClockIcon, LockIcon } from '../../assets/icons/index'

function Message({ text, buttonText, icon, handleLogin, assetId }) {
    return (
        <main className="main">
            <Container fluid>
                <Row className="mv4">
                    <Col md="3"></Col>
                    <Col md="6">
                        <div className="bg-white pv6 ph4 text-center ">
                            <div className="mb4">
                                {
                                    icon === "clock" ? < ClockIcon /> : <LockIcon />

                                }
                            </div>

                            <div className="gray">
                                <p className="f3">{text}</p>
                            </div>
                            <Button onClick={() => handleLogin(assetId)} color="primary">{buttonText}</Button>
                        </div>
                    </Col>
                    <Col md="3"></Col>
                </Row>
                <Row className="mv4">
                </Row>
            </Container>
        </main>
    )
}

export default Message