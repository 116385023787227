import React, {Component} from 'react'
import {Container} from 'reactstrap'
import {ErrorBoundary} from '../../../components'
import ButterToast from 'butter-toast'
import Notification from '../../../components/Notification/Notification'

import{
    AppFooter,
    AppHeader
}from '@coreui/react'

import DefaultFooter from '../DefaultLayout/DefaultFooter'
import DefaultHeader from '../DefaultLayout/DefaultHeader'
import DefaultBreadCrumb from '../DefaultLayout/DefaultBreadCrumb'
import AssetDetailView from '../../../components/AssetDetail/AssetDetailView'

import {ViewerProvider} from '../../Contexts/Viewer.context'

class AssetDetail extends Component {
    render() {
        return(
            <div className="app">
                <ErrorBoundary>
                    <ViewerProvider>
                        <AppHeader fixed>
                            <div className="max-width flex">
                                <DefaultHeader />
                            </div>
                        </AppHeader>
                        <div className="app-body">
                            <main className="main">
                                <DefaultBreadCrumb />
                                <Notification inApp={true}/>
                                <Container fluid>
                                  <div className="max-width">
                                      <AssetDetailView />
                                  </div>    
                                  <ButterToast trayPosition="top-center" />
                                </Container> 
                            </main>
                        </div>
                        <AppFooter>
                            <DefaultFooter />
                        </AppFooter>
                    </ViewerProvider>
                </ErrorBoundary>
            </div>
        )
    }
}

export default AssetDetail