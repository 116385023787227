import React, { Component } from 'react'
import { Card, CardBody, CardGroup, Col, Container, InputGroup, InputGroupAddon, InputGroupText, Row, NavLink } from 'reactstrap'
import { withRouter } from 'react-router-dom'
import { Form, Field } from 'react-final-form'
import { Mutation } from 'react-apollo'
import { translate } from 'react-i18next'
import { Mutations } from './LoginGQL'
import TextFieldAdapter from '../../base/Form/TextFieldAdapter'
import ButterToast, { CinnamonSugar } from 'butter-toast'
import Consts from '../../constants'
import LoadingButton from '../../base/LoadingButton'

import logo from '../../assets/img/brand/oms-logo-white.png'

//const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

const LoginTitle = ({ t, didPasswordReset }) => {
  return (
    didPasswordReset ?
      <React.Fragment>
        <div className="h2 white tc">{t('login.resettitle')}</div>
        <div className="h2 white tc mb4">{t('login.resettitle1')}</div>
        <p className="white tc f5">{t('login.resetp1')}</p>
      </React.Fragment> :
      <React.Fragment>
        <div className="h2 white tc mb4">{t('login.title')}</div>
        <p className="white tc f5">{t('login.p1')}</p>
      </React.Fragment>
  )
}

class Login extends Component {
  constructor(props) {
    super(props)
    this.state = Object.assign({ err: null }, { ...this.props.location.state })
  }

  componentDidMount() {
    const _self = this //eslint-disable-line
    setTimeout(() => {
      if (_self.state.invalidActivationLink) {
        const toast = CinnamonSugar.crisp({
          theme: 'error',
          icon: 'exclamation',
          title: <div className="tj">{_self.props.t('login.activationError')}</div>,
          toastTimeout: 14000,
          dismissOnClick: true,
        })

        ButterToast.raise(toast)

        _self.setState({ invalidActivationLink: null })
      }
    })
  }

  componentDidUpdate() {
    if (this.state.err) {
      const toast = CinnamonSugar.crisp({
        theme: 'error',
        icon: 'exclamation',
        title: <div className="tj">{this.props.t('login.error')}</div>,
        toastTimeout: 14000,
        dismissOnClick: true,
      })

      ButterToast.raise(toast)

      this.setState({ err: null })
    }
  }

  render() {
    const { t, history } = this.props
    return (
      <Mutation mutation={Mutations.LOGIN_USER_MUTATION}>
        {(login, { loading }) => {
          return (
            <div className="app flex-row align-items-center login-cover">
              <Container>
                <Row className="justify-content-center">
                  <Col md={6}>
                    <CardGroup>
                      <Card className="p-4" style={{ backgroundColor: 'transparent', border: 'none' }}>
                        <div className="text-center p-4"><img src={logo} width="200px" height="auto" alt="OMS Prüfservice GmbH - Logo" /></div>
                        <CardBody>
                          <div className="transparent-bg">
                            <Form
                              onSubmit={async values => {
                                const { username, password } = values

                                try {
                                  const result = await login({
                                    variables: {
                                      username,
                                      password,
                                    },
                                  })

                                  localStorage.setItem(Consts.AUTH_TOKEN, result.data.login.token)
                                  localStorage.setItem("Username", username)
                                  localStorage.setItem(Consts.SHOW_WOOTRIC, true)
                                  if (result.data.login.email && result.data.login.email.length > 0) {
                                    localStorage.setItem(Consts.USER_EMAIL, result.data.login.email[0])
                                  }
                                  const { location } = this.props

                                  if (location.search && location.search.includes("?self=true")) {
                                    const { match } = this.props

                                    if (match.params.asset) {
                                      window.location.href = "/provision/" + match.params.asset
                                    }
                                    else {
                                      window.location.href = "/provision"

                                    }

                                  }
                                  else if (this.props.match.params.asset) {
                                    window.location.href = `/assets/${this.props.match.params.asset}`
                                  }
                                  else {
                                    window.location.href = "/projects"
                                  }

                                } catch (e) {
                                  this.setState({ err: e })
                                }
                              }}
                              initialValues={this.state}
                              render={({ handleSubmit, reset, submitting, validating, pristine, values }) => (
                                <form onSubmit={handleSubmit}>
                                  <LoginTitle t={t} didPasswordReset={values.passwordReset} />
                                  <InputGroup className="mb-2">
                                    <InputGroupAddon addonType="prepend">
                                      <InputGroupText>
                                        <i className="icon-user"></i>
                                      </InputGroupText>
                                    </InputGroupAddon>
                                    <Field
                                      disabled={submitting}
                                      name="username"
                                      id="username"
                                      type="username"
                                      placeholder={t('login.emailPlaceholder')}
                                      component={TextFieldAdapter}
                                    />
                                  </InputGroup>
                                  <InputGroup className="mb-2">
                                    <InputGroupAddon addonType="prepend">
                                      <InputGroupText>
                                        <i className="icon-lock"></i>
                                      </InputGroupText>
                                    </InputGroupAddon>
                                    <Field
                                      disabled={submitting}
                                      name="password"
                                      id="password"
                                      type="password"
                                      placeholder={t('login.passwordPlaceholder')}
                                      component={TextFieldAdapter}
                                    />
                                  </InputGroup>
                                  <Row>
                                    <Col xs="12">
                                      <div className="button">
                                        <LoadingButton type="submit" color="primary" className="px-4 fl" style={{ width: '90%' }}
                                          loading={submitting || loading ? 'true' : undefined}>
                                          {t('login.loginSubmit')}
                                        </LoadingButton>
                                        <i className="fr fa fa-question-circle-o fa-2x white mt1" onClick={() => history.push('/loginfaq')} />
                                      </div>
                                    </Col>
                                    <Col xs="12">
                                      <NavLink className="white fw6 f5 underline" href="#"
                                        onClick={() => this.props.history.push('/resetpassword')}>{t('resetpass.title')}
                                      </NavLink>
                                    </Col>
                                  </Row>
                                </form>
                              )}
                            />
                          </div>
                        </CardBody>
                      </Card>
                    </CardGroup>
                  </Col>
                </Row>
              </Container>
              <ButterToast trayPosition="top-center" />
            </div>
          )
        }}
      </Mutation>
    )
  }
}

export default translate()(withRouter(Login))
