import React, { Suspense } from 'react'
import { Container } from 'reactstrap'
import { ErrorBoundary } from '../../../components'
import ButterToast from 'butter-toast'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import { translate } from 'react-i18next'
import { ApolloConsumer, compose, graphql } from 'react-apollo'

import { Queries } from '../../../components/Project/ProjectGQL'
import Notification from '../../../components/Notification'
import Spinner from '../../../base/Spinner'

import {
  AppAside,
  AppFooter,
  AppHeader,
  //AppAside,
  AppSidebar,
  AppSidebarHeader,
  AppSidebarForm,
  AppSidebarFooter,
  // AppSidebarMinimizer,
  AppSidebarNav

} from '@coreui/react'

// import DefaultSidebar from './DefaultSidebar'
import ComponentRouter from './ComponentRouter'
import DefaultFooter from './DefaultFooter'
import DefaultHeader from './DefaultHeader'
import Wootric from '../../../components/Wootric/Wootric'

import { ViewerProvider } from '../../Contexts/Viewer.context'
import Consts from '../../../constants'

import { ViewerConsumer } from '../../Contexts/Viewer.context'

function setNav(licences, showDashboard, showRiskgroup, role, isMigrated, t) {

  const items = [
    {
      name: t('projects'),
      url: '/projects',
      icon: 'icon-new-layers'
    },
  ]
  const dashboard = {
    name: t('dashboard'),
    url: '/userdashboard',
    icon: 'icon-new-speedometer-1'
  }

  const provision = {
    name: t('provision'),
    url: '/provision',
    icon: 'icon-new-provision'
  }
  const riskAssessments = {
    name: t('riskAssessments'),
    url: '/riskAssessment',
    icon: 'icon-new-risk'
  }

  const isContact = role === Consts.CONTACT_ROLE
  if (showDashboard && isContact) {
    items.push(dashboard)
  }

  if (!isMigrated && licences.indexOf(Consts.PROVISION) !== -1 && isContact) {
    items.push(provision)
  }
  
  if (showRiskgroup && isContact) {
    items.push(riskAssessments)
  }

  return { items }
}

function DefaultLayout({ t, loading, licencedModules, isCustomerMigrated, flags }) {

  if (loading) return <Spinner />

  let licences = []
  if (licencedModules.licencedModules) {
    licences = licencedModules.licencedModules
  }

  const { showDashboard, showRiskgroup } = flags
  const isMigrated = isCustomerMigrated.isCustomerMigrated && isCustomerMigrated.isCustomerMigrated.elektraId !== null;


  return (
    <div className="app">
      <ErrorBoundary>
        <ViewerProvider>
          <ApolloConsumer>
            {client =>
              <ViewerConsumer>
                {
                  ({ role, name, avatar, id }) => {

                    const nav = setNav(licences, showDashboard, showRiskgroup, role, isMigrated, t)

                    return (
                      <div>
                        <AppHeader fixed>
                          <div className="max-width flex">
                            <DefaultHeader name={name} avatar={avatar} client={client} />
                          </div>
                        </AppHeader>
                        <div className="app-body">
                          {/* <AppSidebar display="lg" className="d-none">
                <DefaultSidebar />
              </AppSidebar*/}
                          <AppSidebar fixed display="lg">
                            <AppSidebarHeader />
                            <AppSidebarForm />
                            <Suspense>
                              <AppSidebarNav navConfig={nav} />
                            </Suspense>
                            <AppSidebarFooter />
                            {/* <AppSidebarMinimizer /> */}
                          </AppSidebar>
                          <main className="main">
                            {/* <DefaultBreadCrumb /> */}
                            <Notification inApp={true} />
                            <Container fluid>
                              <div className="max-width">
                                <ComponentRouter name={name} id={id} role={role} flags={flags} />
                              </div>
                              <ButterToast trayPosition="top-center" />
                            </Container>
                          </main>
                          <AppAside />
                          {/* Whenever right side hand side menu is necessary can be switched on <AppAside fixed hidden><DefaultAside /></AppAside>*/}
                        </div>
                        <AppFooter>
                          <DefaultFooter />
                        </AppFooter>
                      </div>
                    )
                  }
                }
              </ViewerConsumer>
            }
          </ApolloConsumer>
        </ViewerProvider>
      </ErrorBoundary>
      <Wootric />
    </div>
  )

}

export default translate("routes")(withLDConsumer()(
  compose(
    graphql(Queries.GET_CUSTOMER_LICENCES, {
      name: 'licencedModules'
    }),
    graphql(Queries.GET_MIGRATED_CUSTOMER, {
      name: 'isCustomerMigrated'
    }) 
    )(DefaultLayout)))
