import React, {Component} from 'react'
import PropTypes from 'prop-types'
import ProjectDetails from '../Project/ProjectDetails/ProjectDetails'
import {Redirect} from 'react-router-dom'
import {Queries} from '../Project/ProjectGQL'
import {graphql, compose} from 'react-apollo'
import {i18n} from '../../config'
import SalesContact from '../SalesContact/SalesContact'

class SalesOrder extends Component {
  render() {
    const hasQueryStarted = this.props.projectQuery || null
    const isQueryLoading = (this.props.projectQuery && this.props.projectQuery.loading) || !this.props.projectQuery.userProjects

    if (!hasQueryStarted || isQueryLoading) {
      return (
        <React.Fragment>
          <div className="loading-in-app">Loading&#8230;</div>
          <div className="loading-in-app-message"><h1>{i18n.t('login.loading')}</h1></div>
        </React.Fragment>
      )
    } else {
      if (!this.props.projectQuery.userProjects.projects) {
        return <Redirect to="/projects" />
      } else {
        const project = this.props.projectQuery.userProjects.projects[0]

        return (
          <div className="dashboard">
            <div className="middle">
              <ProjectDetails id={project.id} />
            </div>
            <SalesContact id={project.id} />
          </div>
        )
      }
    }
  }
}

SalesOrder.propTypes = {
  id: PropTypes.string.isRequired,
}

export default compose(
  graphql(Queries.PROJECTS_QUERY, {
    name: 'projectQuery',
    options: (props) => ({
      variables: {
        params: {
          searchString: props.id,
          pager: {page: 1, pageSize: 1},
        },
      },
    }),
  })
)(SalesOrder)
