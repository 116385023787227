import React, {Component} from 'react'
import {translate} from 'react-i18next'
import {withRouter} from 'react-router-dom'

class Help extends Component {

  render() {
    const {t, history} = this.props

    return (
      <div className="sl-nav">
        <ul>
          <li onClick={() => history.push('/assets')}>
            <i className="cui-list fw6 mb-1" aria-hidden="true"></i>
            <ul>
              <li onClick={() => history.push('/assets')} className="pb-2">
                <span>{t('layout.header.assets')}</span>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    )
  }
}


export default translate()(withRouter(Help))
