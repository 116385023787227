import React, { Component } from 'react'
import { translate } from 'react-i18next'
import { compose, graphql } from 'react-apollo'
import { Row, Col, Alert, Card, CardBody, CardHeader, Button } from 'reactstrap'
import Moment from 'react-moment'

import FileSaver from 'file-saver'
import { Config } from '../../../config'
import Consts from '../../../constants'


import { Queries } from '../AssetDetailGQL'
import { withRouter } from 'react-router-dom'
import Spinner from '../../../base/Spinner'
import axios from 'axios'
import ButterToast, { CinnamonSugar } from 'butter-toast'


class AssetDetail extends Component {
  constructor(props) {
    super(props)

    this.state = { ...this.initialState() };
    this.cert = null;
    this.riskAssessment = null;
  }

  initialState = () => ({
    downloading: false,
  })

  onStartSend(assetToGet) {
    this.downloadFile(assetToGet);
  }

  downloadFile = async (fileData) => {

    this.setState({ downloading: true })

    const fileListB64 = Buffer.from(JSON.stringify([fileData])).toString('base64')

    console.log('end:---', `${Config.gqlHttp}downloadFiles?fileList=${fileListB64}`)

    axios({
      url: `${Config.gqlHttp}downloadFiles?fileList=${fileListB64}`,
      method: 'GET',
      responseType: 'blob',
      headers: { Authentication: localStorage.getItem(Consts.AUTH_TOKEN) },

    }).then(response => {
      FileSaver.saveAs(response.data, fileData.filename)

      const file = new Blob([response.data], { type: "application/pdf" });
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      this.setState({ ...this.initialState() })
      this.props.history.push({
        pathname: "/pdf/viewer/",
        state: {
          url: fileURL
        }
      })

    }).catch(e => {
      const toast = CinnamonSugar.crisp({
        theme: 'error',
        icon: 'exclamation',
        title: <div className="tj">{this.props.t('project.details.downloads.error')}</div>,
        toastTimeout: 15000,
        dismissOnClick: true,
      })

      ButterToast.raise(toast)

      this.setState({ ...this.initialState() })
    })
  }


  render() {

    const { t,
      match: {
        params: {
          id: assetId
        }
      }
    } = this.props

    const hasQueryStarted = this.props.assetDetailQuery || null
    const isQueryLoading = this.props.assetDetailQuery && this.props.assetDetailQuery.loading
    const data = this.props.assetDetailQuery.assetDetail
    if (this.props.assetDetailQuery.assetDetail) {
      this.cert = this.props.assetDetailQuery.assetDetail.certificate;
    }

    if (!hasQueryStarted) {
      return null
    }
    else if (isQueryLoading) {
      return <Spinner />
    }
    else {

      if (data && data.id) {

        const { testSuccessful, riskAssessment } = data


        return (

          <React.Fragment>
            <div>
              <Card>
                <CardHeader>
                  <h3>{t('assetDetail.assetId')}: {data.id}</h3>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col sm="12" md="4" className="mb4">
                      <Row className="mb4">
                        <Col>
                          <p className="silver ttu f7 pmb-3">{t('assetDetail.assetType')}</p>
                          <span>{data.assetType}</span>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <p className="silver ttu f7 pmb-3">{t('assetDetail.manufacturer')}</p>
                          <span >{data.assetManufacturer}</span>
                        </Col>
                      </Row>

                    </Col>
                    <Col sm="12" md="4" className="mb4">

                      <p className="silver ttu f7 pmb-3">{t('assetDetail.assetLocation')}</p>
                      <span className="ttc">
                        {
                          ((data.assetSite === '-') ? '' : data.assetSite + " ") +
                          ((data.assetBuilding === '-') ? '' : data.assetBuilding + " ") +
                          ((data.assetFloor === '-') ? '' : data.assetFloor + " ") +
                          ((data.assetRoom === '-') ? '' : data.assetRoom)
                        }
                      </span>
                    </Col>
                    <Col sm="12" md="4" className="mb-4">
                      <p className="silver ttu f7 pmb-3">{t('assetDetail.operator')}</p>
                      <p className="m pmb-3">{data.operatorName}</p>
                      {
                        data.operatorAddress.map((address, i) => <p key={i} className="gray pmb-3 ttc">{address}</p>)
                      }
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  <Row>
                    <Col sm="12" md="6" className="mb4">
                      <h3 className="">{t('assetDetail.testDetail')}</h3>
                    </Col>
                    <Col lg="2"></Col>
                    <Col sm="12" md="12" lg="12" xl="4" className="mt1">
                      <h3>
                        <i className={"h1 pr-4 align-middle" + (testSuccessful ? ' icon-new-check-1 text-success ' : ' icon-new-warning-1 text-danger ')}></i>
                        <span className={(testSuccessful ? 'text-success' : 'text-danger')}>{testSuccessful ? t('assetDetail.testSuccessfull') : t('assetDetail.testFailed')}</span>
                      </h3>
                    </Col>
                  </Row>
                </CardHeader>

                <CardBody>
                  <Row sm="12" md="4">
                    <Col>
                      <Row>
                        <Col className="mb4">
                          <Row className="mb4">
                            <Col>
                              <div>
                                <p className="silver pmb-3 ttu">{t('assetDetail.testDate')}</p>
                                <span>
                                  <Moment format="D.MM.YYYY" >{data.testDate}</Moment>
                                </span>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <p className="silver ttu pmb-3">{t('assetDetail.nextTestDate')}</p>
                              <span>
                                <Moment format="D.MM.YYYY" >{data.nextTestDate}</Moment>
                              </span>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                    <Col sm="12" md="4" className="mb4">
                      <p className="silver pmb-3 ttu">{t('assetDetail.testBasis')}</p>
                      <span className="normal">{data.testRegulation}</span>
                    </Col>
                    <Col sm="12" md="4" className="mb4">
                      <p className="silver pmb-3 ttu">{t('assetDetail.testServiceProvider')}</p>
                      <p className="pmb-3">OMS Prüfservice GmbH</p>
                      <p className="pmb-3">Mühlstraße 90</p>
                      <p className="pmb-3">73547 Lorch-Waldhausen</p>

                      <img src="/static/media/oms-logo.8271baaa.png" width="76" alt="OMS" className="mt1" />
                      <p className="ttu f7"  >Prüfservice</p>

                    </Col>

                  </Row>

                  <Row className="mt3">
                    <Col className="mb4" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                      <div className="d-flex justify-content-center mt4">
                        <Button type="button" color="primary" className="bmw-400" onClick={() => this.onStartSend(this.cert)}
                          disabled={this.state.downloading}><i className="fa fa-download mobile-nd">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</i>
                          {t('assetDetail.certificate')}</Button>
                      </div>

                      <div className="d-flex justify-content-center mt4">
                        <Button type="button" outline color="primary" className={'bmw-400' + ( riskAssessment === null ? ' dn' : '')}
                          onClick={() => this.onStartSend(riskAssessment)}><i className="fa fa-download mobile-nd">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</i>
                          {t('assetDetail.riskAssessment')}</Button>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>

          </React.Fragment>

        )
      } else {
        return (
          <div className="pa5">
            <Alert color="danger">
              <h4 className="alert-heading">WRONG ASSET ID!</h4>
              <p>
                Asset with id <span className="b">{assetId}</span> was not found.
            </p>
            </Alert>
          </div>
        )
      }
    }
  }
}

export default translate('assetDetail', { wiat: true })(compose(
  withRouter,
  graphql(Queries.ASSETDETAIL_QUERY, {
    name: 'assetDetailQuery',
    options: ({ match }) => {
      return (
        {
          variables: {
            assetId: match.params.id,
          },
        })
    },
  })
)(AssetDetail))

