import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import DashboardCard from "../../../CSM/Components/DashboardCard/DashboardCard";
import CabinetOverview from "../../../CSM/Components/DashboardCard/CabinetCard/CabinetOverview";
import "../../../CSM/Components/DashboardCard/CSMStyle/PortalStyle.css";
import EventsTable from "../../../CSM/Components/DashboardCard/EventsCard/EventsTable";

class CsmDashboard extends Component {
  render() {
    return (
      <div className="CSM">
        <DashboardCard title="Events" body={<EventsTable />} />
        <DashboardCard
          title="Electrical Cabinet Overview"
          body={<CabinetOverview />}
        />
      </div>
    );
  }
}

export default withRouter(CsmDashboard);
