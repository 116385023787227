import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import Login from '../../../components/Login'
import Notification from '../../../components/Notification'

class LoginPage extends Component {
  render() {
    return (
      <div>
        <Notification />
        <Login />
      </div>
    )
  }
}

export default (withRouter(LoginPage))
