import React, { Component } from 'react'
import { translate } from 'react-i18next'
import { Query } from 'react-apollo'
import { Queries } from '../AssetGQL'
import AssetCard from '../AssetCard/AssetCard'
import { Row } from 'reactstrap'
import { withRouter } from 'react-router-dom'

class AssetList extends Component {
  render() {
    const { t } = this.props

    return (
      <Query query={Queries.ASSET_QUERY}>
        {({ data, loading }) => {
          if (loading || !data || !data.assets) {
            return (<div>
              <span>{t('common.nodatafound')}</span>
            </div>)
          }

          const { assets } = data

          const cards = assets.map((asset, i) =>
            <AssetCard key={i} asset={asset} />
          )

          if (!cards || cards.length === 0) {
            return (<div>
              <span>{t('common.nodatafound')}</span>
            </div>)
          }

          return (
            <Row>
              {cards}
            </Row>
          )
        }
        }
      </Query>
    )

  }
}

export default translate('common', { wait: true })(withRouter(AssetList))