import GAnalytics from './googleAnalytics'
import GTagManager from './googleTagManager'
import USnap from './userSnap'
import RAClient from './apolloClient'

const env = process.env
const IntegrationConfiguration = {
  gqlSocket: env.REACT_APP_GQL_ENDPOINT_SOCKET,
  gqlHttp: env.REACT_APP_GQL_ENDPOINT_HTTP,
  userSnapKey: env.REACT_APP_USERSNAP_KEY,
  userSnapGlobalKey: env.REACT_APP_GLOBAL_USERSNAP_KEY,
  googleAnalyticsKey: env.REACT_APP_GOOGLE_ANALYTICS_KEY,
  googleTagManagerKey: env.REACT_APP_GOOGLE_TAG_MANAGER_KEY,
  googleTagManagerAuth: env.REACT_APP_GOOGLE_TAG_MANAGER_AUTH,
  googleTagManagerPreview: env.REACT_APP_GOOGLE_TAG_MANAGER_PREVIEW,
}

let GoogleAnalytics = new GAnalytics(IntegrationConfiguration)
let UserSnap = new USnap(IntegrationConfiguration)
let ReactApolloClient = new RAClient(IntegrationConfiguration)
let GoogleTagManager = new GTagManager(IntegrationConfiguration)

export {
  GoogleAnalytics,
  UserSnap,
  ReactApolloClient,
  GoogleTagManager
}
