import React from 'react'
import { translate } from 'react-i18next'
import { Row, CardBody, Card, CardHeader, Col, Input, Form } from 'reactstrap'

import ListContainer from './ListContainer'


function RiskAssessmentContainer({
    t,
    page,
    setPage,
    setSearchTerm,
    searchTerm,
    totalCount,
    pageSize,
    riskAssessmentCount,
    selectedRiskAssessment,
    setRiskAssessmentCount,
    setSelectedRiskAssessment
}) {

    return (
        <div className="animated fadeIn">
            <Row>
                <Col xm="12">
                    <Card>
                        <CardHeader>
                            <div>{t("listTitle")}{` (${riskAssessmentCount})`}</div>
                        </CardHeader>
                        <CardHeader>
                            <RiskAssessmentSearch t={t} searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
                        </CardHeader>
                        <CardBody className="m-0">
                            <ListContainer
                                t={t}
                                page={page}
                                totalCount={totalCount}
                                pageSize={pageSize}
                                setPage={setPage}
                                searchTerm={searchTerm}
                                selectedRiskAssessment={selectedRiskAssessment}
                                setRiskAssessmentCount={setRiskAssessmentCount}
                                setSelectedRiskAssessment={setSelectedRiskAssessment}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    )

}

function RiskAssessmentSearch({ t, searchTerm, setSearchTerm }) {
    return (
        <div>
            <Form>
                <Input placeholder={t("filterMessage")} value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                />
            </Form>
        </div>
    )
}



export default translate("riskAssessments")(RiskAssessmentContainer)
