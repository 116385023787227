import React, { Component } from "react";
import { SvgIcon, bell } from "hyperio.react.library";

export default class BellIcon extends Component {
  render() {
    const NotificationSent = this.props.NotificationSent;

    if (NotificationSent) {
      return (
        <div>
          <SvgIcon style={{ color: "var(--error)" }} icon={bell} />
        </div>
      );
    }
    return <div></div>;
  }
}
