import React, { Component } from 'react'
import { Row, Col } from 'reactstrap'
import { translate } from 'react-i18next'

class ProjectStatistics extends Component {
  render() {
    const { t, statistics } = this.props

    const percentage = (statistics.totalExported && statistics.totalFailed ?
      (statistics.totalFailed / statistics.totalExported * 100) : 0).toFixed(2)

    switch (statistics.totalExported) {
      case 0: {
        return (null)
      }
      default: {
        return (
          <React.Fragment>
            <Row>
              <Col><span className="ttu silver f7">{t('project.details.statistics.overview')}</span></Col>
            </Row>
            <div className="mw7 center text-center">
            <Row className="mv3 mh f2 ">
              <Col xs="12" lg="6">
                <p className="f1 pmb-3">{statistics.totalExported}</p>
                <p className="f5 ttc">{t('layout.header.assets')}</p></Col>
              <Col xs="12" lg="6" >
                <p className="f1 pmb-3">{percentage + ' % '}</p>
                <p className="f5 ttc">{t('project.item.failedPercent')}</p></Col>
            </Row>
            </div>

            <Row className="text-center">
              <Col sm="4" md="4">
                <div className="custom-card custom-card-green">
                  <p className="ttu silver f7">{t('project.details.statistics.passedTest')}</p>
                  <p className="f1 pmb-3">{statistics.totalExported - statistics.totalFailed - statistics.totalWithComments}</p>
                  <p className="f4 ttc">{t('layout.header.assets')}</p>
                </div>
              </Col>
              <Col sm="4" md="4">
                <div className="custom-card custom-card-yellow">
                  <p className="ttu silver f7">{t('project.details.statistics.withComment')}</p>
                  <p className="f1 pmb-3">{statistics.totalWithComments}</p>
                  <p className="f4 ttc">{t('layout.header.assets')}</p>
                </div>
              </Col>
              <Col sm="4" md="4">
                <div className="custom-card custom-card-red">
                  <p className="ttu silver f7">{t('project.details.statistics.failedTest')}</p>
                  <p className="f1 pmb-3">{statistics.totalFailed}</p>
                  <p className="f4 ttc">{t('layout.header.assets')}</p>
                </div>
              </Col>
            </Row>
          </React.Fragment>
        )
      }
    }
  }
}

export default translate()(ProjectStatistics)
