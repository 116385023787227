import gql from 'graphql-tag'

/* QUERIES */

const CHECK_USER_ACTIVATION_QUERY = gql` 
  query checkUserActivation($link: String!) {
    checkUserActivation(link: $link) {
      activationStatus,
      username
      passwordRules {
        minLength,
        minLowercaseCount,
        minUppercaseCount,
        minDigitCount
      }
    }
  }`

/* MUTATIONS */

const ACTIVATE_USER_MUTATION = gql`
mutation activateUser($activationLink: String!, $password: String!) {
  activateUser(activationLink: $activationLink, password: $password)
}
`

const Queries = {CHECK_USER_ACTIVATION_QUERY}
const Mutations = {ACTIVATE_USER_MUTATION}

export {Queries, Mutations}

