import React, {Component} from 'react'
import {ListGroupItem, ListGroup} from 'reactstrap'
import _ from 'lodash'
import Spinner from './Spinner'
import {i18n} from '../config'

const List = (Collection, WrappedComponent, IsLoading) => {
  return class HOC extends Component {

    constructor(props) {
      super(props)
      this.state = {
        activeCard: 0,
      }
    }

    componentDidMount() {
      (Collection && Collection.length > 0) ?
        this.props.onClick({variables: {id: _.head(Collection).id}}) :
        this.props.onClick({variables: {id: null}})
    }

    toggle = (card) => {
      if (this.state.activeCard !== card) {
        this.setState({
          activeCard: card,
        })
      }
    }
    render() {
      let body
      if (IsLoading) {
        body = <Spinner />
      } else {
        if (Collection.length === 0) {
          body = <h2 className="text-center p-3"><b>{i18n.t('common.nodatafound')}</b></h2>
        } else {
          body = <React.Fragment>

            <ListGroup>
              { Collection.map((item, i) => {
                return (
                  <ListGroupItem className="no-br" onClick={() => {
                    this.toggle(i)
                    this.props.onClick({variables: {id: item.id}})
                  }} active={(() => {
                    return this.state.activeCard === i
                  })()} key={item.id}>
                    <WrappedComponent {...item} />
                  </ListGroupItem>
                )
              })}
            </ListGroup>
          </React.Fragment>
        }
      }

      return (body)
    }
  }
}

export default List
