import React, {Component} from 'react'
import {Collapse, Container, NavLink, CardHeader, Card, CardBody, Col, Row, Button} from 'reactstrap'
import {translate} from 'react-i18next'
import {Config} from '../../../config'

class FAQ extends Component {
  constructor(props) {
    super(props)
    this.state = {collapse: 0}
  }

  toggle = (e) => {
    e.preventDefault()

    let event = e.target.dataset.event
    this.setState({collapse: this.state.collapse === Number(event) ? 0 : Number(event)})
  }

  componentDidMount() {
    this.setState({collapse: 1})
  }

  render() {
    const {t} = this.props
    const {collapse} = this.state

    return (
      <div className="app flex-row align-items-center login-cover">
        <Container>
          <Row className="justify-content-center">
            <Col md={7}>
              <div className="transparent-bg">
                <div className="card-body">

                  <div className="h2 white mb4 text-center">{t('title')}</div>
                  <div className="white mb4 tj">
                    <p className="f5 fw5 mb0 tc">{t('p2')}</p>
                    <p className="f5 fw5 tc">{t('p3')}</p>
                  </div>
                </div>

                {
                  [...Array(Number(Config.loginFaqCount) || 0).keys()].map((item) => {
                    return (
                      <Card className="mb-2 wg-box-shadow animated fadeIn" key={item + 1}>
                        <CardHeader onClick={this.toggle}>
                          <NavLink onClick={this.toggle} href="#" className="f5 mb0 pa0 black" data-event={item + 1}>
                            <b data-event={item + 1}>{t('q' + (item + 1).toString())}</b>
                          </NavLink>
                        </CardHeader>
                        <Collapse isOpen={collapse === item + 1}>
                          <CardBody className="tc">

                            <span dangerouslySetInnerHTML={{__html: t('a' + (item + 1).toString())}} />

                          </CardBody>
                        </Collapse>
                      </Card>
                    )
                  })
                }
                <div className="h2 white mt5 mb3 text-center">{t('contact')}</div>
                <div className="f5 white">{t('email')}
                  <a href="mailto:support.portal@oms-pruefservice.de">support.portal@oms-pruefservice.de</a>
                </div>
                <div className="f5 white">{t('tel')}  <a href="tel:+49071721839218">+49 (0)7172 / 1839218</a></div>
                <div className="f5 white">{t('schedule')}</div>
                <div className="mt5 text-center">
                  <div className="button">
                    <Button color="btn btn-primary" onClick={() => this.props.history.push('/login')}>
                      <span>{t('login')}</span>
                    </Button>
                  </div>
                </div></div>
            </Col>
          </Row>

        </Container>
      </div>

    )
  }
}

export default translate('loginfaq', {wait: true})(FAQ)
