import React, {Component} from 'react'
import ResetPassword from '../../../components/ResetPassword'

class PasswordReset extends Component {
  render() {
    return (
      <ResetPassword />
    )
  }
}

export default PasswordReset
