import React, {Component} from 'react'
import {Card, CardBody, CardGroup, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row} from 'reactstrap'
import {withRouter} from 'react-router-dom'
import {Form, Field} from 'react-final-form'
import {Mutation} from 'react-apollo'
import {translate} from 'react-i18next'
import {Mutations} from './ContactUserActivationGQL'
import TextFieldAdapter from '../../base/Form/TextFieldAdapter'
import validators from '../../base/Form/Validators'
import PasswordValidationRules from '../PasswordValidationRules'
import Consts from '../../constants'

import LoadingButton from '../../base/LoadingButton'
import ButterToast, {CinnamonSugar} from 'butter-toast'

import logoEn from '../../assets/img/brand/oms-logo-white.png'
import logoDe from '../../assets/img/brand/oms-logo-white.png'

//const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

class ContactUserActivationForm extends Component {
  constructor(props) {
    super(props)

    this.state = {err: null}
  }

  passValidator = (value, values) => {
    return validators.required(value) ||
           validators.regex(new RegExp('(.){' + this.props.passwordRules.minLength + ',}'))(value) ||
           validators.regex(new RegExp('(\\w*[a-z]\\w*){' + this.props.passwordRules.minLowercaseCount + ',}'))(value) ||
           validators.regex(new RegExp('(\\w*[A-Z]\\w*){' + this.props.passwordRules.minUppercaseCount + ',}'))(value) ||
           validators.regex(new RegExp('(\\D*\\d\\D*){' + this.props.passwordRules.minDigitCount + ',}'))(value)
  }

  checkPassValidator = (value, values) => {
    return validators.required(value) || validators.equal(values.password)(value)
  }

  componentDidUpdate() {
    if (this.state.err) {
      const toast = CinnamonSugar.crisp({
        theme: 'error',
        icon: 'exclamation',
        title: <div className="tj">{this.props.t('useractivation.activationForm.error')}</div>,
        toastTimeout: 8000,
        dismissOnClick: true,
      })

      ButterToast.raise(toast)

      this.setState({err: null})
    }
  }

  render() {
    const {activationLink, t, history, isStaffMember} = this.props
    const logo = localStorage.getItem(Consts.I18N_LANGUAGE) === 'en' ? logoEn : logoDe

    return (
      <Mutation mutation={Mutations.ACTIVATE_USER_MUTATION}>
        {(activateUser, {loading}) => {
          return (
            <div className="app flex-row align-items-center login-cover">
              <Container>
                <Row className="justify-content-center">
                  <Col md="7">
                    <div className="text-center p-4"><img src={logo} width="200" height="auto" alt="oms logo"/></div>
                    <CardGroup>
                      <Card className="p-2 no-bg">
                        <CardBody>
                          <div className="transparent-bg">
                            <Form
                              onSubmit={async values => {
                                const {password} = values

                                try {
                                  await activateUser({
                                    variables: {
                                      activationLink: activationLink,
                                      password: password,
                                    },
                                  })

                                  const location = {
                                    pathname: isStaffMember ? '/staff' : '/login',
                                    state: {passwordReset: true, username: this.props.username, password: password},
                                  }

                                  history.push(location)
                                } catch (e) {
                                  this.setState({err: e})
                                }
                              }}
                              render={({handleSubmit, reset, submitting, validating, pristine, values}) => (
                                <form onSubmit={handleSubmit}>
                                  <div className="h2 mb4 white">{t('useractivation.activationForm.title')}</div>
                                  <p className="white f5">{t('useractivation.activationForm.message')}</p>
                                  <InputGroup className="mb-3">
                                    <InputGroupAddon addonType="prepend">
                                      <InputGroupText>
                                        <i className="icon-user"></i>
                                      </InputGroupText>
                                    </InputGroupAddon>
                                    <Input type="text"
                                      disabled={true}
                                      value={this.props.username} />
                                  </InputGroup>
                                  <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                      <InputGroupText>
                                        <i className="icon-lock"></i>
                                      </InputGroupText>
                                    </InputGroupAddon>
                                    <Field
                                      disabled={submitting}
                                      name="password"
                                      id="password"
                                      type="password"
                                      placeholder={t('useractivation.activationForm.passwordPlaceholder')}
                                      component={TextFieldAdapter}
                                      validate={this.passValidator}
                                    />
                                  </InputGroup>
                                  <InputGroup className="mb-4 mt-2">
                                    <InputGroupAddon addonType="prepend">
                                      <InputGroupText>
                                        <i className="icon-lock"></i>
                                      </InputGroupText>
                                    </InputGroupAddon>
                                    <Field
                                      disabled={submitting}
                                      name="checkpassword"
                                      id="checkpassword"
                                      type="password"
                                      placeholder={t('useractivation.activationForm.repeatPasswordPlaceholder')}
                                      component={TextFieldAdapter}
                                      validate={this.checkPassValidator}
                                    />
                                  </InputGroup>
                                  <Row>
                                    <Col xs="12">

                                      <PasswordValidationRules passwordRules={this.props.passwordRules}></PasswordValidationRules>
                                    </Col>
                                  </Row>
                                  <Row className="mt-3">
                                    <Col xs="12">
                                      <div className="button">
                                        <LoadingButton type="submit" color="primary" className="px-4" style={{width: 'auto'}}
                                          loading={submitting || loading ? 'true' : undefined}>
                                          {t('useractivation.activationForm.activateSubmit')}
                                        </LoadingButton>
                                      </div>
                                    </Col>
                                  </Row>
                                </form>
                              )}
                            />
                          </div>
                        </CardBody>
                      </Card>
                    </CardGroup>
                  </Col>
                </Row>
              </Container>
              <ButterToast trayPosition="top-center"/>
            </div>
          )
        }}
      </Mutation>
    )
  }
}

export default translate()(withRouter(ContactUserActivationForm))
