import gql from 'graphql-tag'

/* MUTATIONS */

const LOGIN_USER_MUTATION = gql`
  mutation login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      token
      email
    }
  }
`

const Queries = {}
const Mutations = {LOGIN_USER_MUTATION}

export {Queries, Mutations}

