import React, {Component} from 'react'
import ContactUserActivation from '../../../components/ContactUserActivation'

class UserActivation extends Component {
  render() {
    return (
      <ContactUserActivation link={this.props.match.params.link} />
    )
  }
}

export default UserActivation
