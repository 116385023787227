import Errors from '../errors'

const gqlErrorHandler = (graphQLErrors) => {
  if (graphQLErrors) {
    const gqlErr = graphQLErrors.shift()

    return Errors[gqlErr.name]
  }

  return null
}

export {
  gqlErrorHandler,
}
