import React, { useState } from 'react'
import { compose, graphql } from 'react-apollo'
import { Redirect } from 'react-router-dom'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import { translate } from 'react-i18next'
import { Button, Row, Col } from 'reactstrap'

import { RiskIcon } from '../../assets/icons'
import RiskAssessmentContainer from './RiskAssessmentList/RiskAssessmentContainer'
import NewRiskAssessmentForm from './RiskAssessmentForm/NewRiskAssessmentForm'
import EditRiskAssessmentForm from './RiskAssessmentForm/EditRiskAssessmentForm'
import { Queries } from './RiskAssessmentGQL'
import Spinner from '../../base/Spinner'

function RiskAssessment({ t, loading, error, riskGroupCount, isCustomerMigrated, flags }) {
    const [page, setPage] = useState(1)
    let [searchTerm, setSearch] = useState('')
    const [riskAssessmentCount, setRiskAssessmentCount] = useState(0)
    const [selectedRiskAssessment, setSelectedRiskAssessment] = useState(null)

    const [isEdit, setIsEdit] = useState(false)
    const [isCreate, setIsCreate] = useState(false)

    const handleSetSelectedRiskAssessment = (revision) => {
        setIsEdit(false)
        setSelectedRiskAssessment(revision)
    }

    const handleSetPage = page => {
        setPage(page)
        setSelectedRiskAssessment(null)
    }
    const setSearchTerm = (val) => {
        setPage(1)
        setSearch(val)
        setSelectedRiskAssessment(null)
    }  
    const isMigrated = isCustomerMigrated && isCustomerMigrated.isCustomerMigrated && isCustomerMigrated.isCustomerMigrated.elektraId !== null ;

    if (!flags.showRiskgroup) return <Redirect to="/project" />

    if (loading) return <Spinner />

    return (
        <div style={{ height: "90vh" }}>
            <Row className="mb4">
                <Col sm="6" className=""> <h1 className="pl2 mv4 fw4">{t('riskGroup')}</h1></Col>
                <Col sm="6" className="b-sm-align-left">
                {isMigrated ? 
                    <div/> :
                    <Button color="primary" size="lg"
                        onClick={() => setIsCreate(true)}
                    >
                       {t("createRiskGroup")}
                    </Button>}
                </Col>
            </Row>
            {

    (riskGroupCount.riskAssessmentCount === 0 && !isCreate) ?
                    <div>

                        <Row className="">
                            <Col className="d-flex justify-content-center align-items-center" style={{ minHeight: 500 }}>
                                <div>
                                    <RiskIcon color="#ababa9" />
                                    <p className="mt3">{t('noRiskGroup')}</p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    :
                    <Row>
                        <Col sm="12" md="4" xl="3">
                            <RiskAssessmentContainer
                                t={t}
                                page={page}
                                setPage={handleSetPage}
                                searchTerm={searchTerm}
                                setSearchTerm={setSearchTerm}
                                riskAssessmentCount={riskAssessmentCount}
                                selectedRiskAssessment={selectedRiskAssessment}
                                setRiskAssessmentCount={setRiskAssessmentCount}
                                setSelectedRiskAssessment={handleSetSelectedRiskAssessment}
                            />
                        </Col>
                        <Col sm="12" md="8" xl="9">
                            {
                                isCreate ?
                                    <NewRiskAssessmentForm
                                        t={t}
                                        setIsCreate={setIsCreate}
                                    />
                                    :
                                    selectedRiskAssessment ? <EditRiskAssessmentForm t={t}
                                        isEdit={isEdit}
                                        isCreate={isCreate}
                                        setIsEdit={setIsEdit}
                                        isCustomerMigrated = {isMigrated}
                                        selectedRiskAssessment={selectedRiskAssessment}
                                        handleSetSelectedRiskAssessment={handleSetSelectedRiskAssessment}
                                    />
                                        :

                                        <Spinner />

                            }

                        </Col>
                    </Row>
            }
        </div>
    )
}

export default withLDConsumer()(translate('riskAssessments')(
    compose(
        graphql(Queries.RISK_ASSESSMENT_COUNT, {
            name: "riskGroupCount",
            options: {}
        }),
        graphql(Queries.GET_MIGRATED_CUSTOMER, {
        name: 'isCustomerMigrated'
        }) 
    )(RiskAssessment)))

