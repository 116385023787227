import React from "react";
import { SvgIcon, notification } from "hyperio.react.library";
import "./StatusIcon.css";
export default ({ status, visible }) => {
  return (
    <div className={`status-icon ${status}`}>
      {visible && <SvgIcon style={{ fontSize: 10 }} icon={notification} />}
    </div>
  );
};
