import React, { Component } from 'react'
import { translate } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { Query } from 'react-apollo'
import { Queries } from '../../AssetGQL'
import _ from 'lodash'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import Consts from '../../../../constants'

class FailedAssetsGrid extends Component {

  renderTableHeader(t) {
    return (
      <thead>
        <tr>
          <th>{t('assetView.failedAssetsGrid.columns.id')}</th>
          <th>{t('assetView.failedAssetsGrid.columns.type')}</th>
          <th>{t('assetView.failedAssetsGrid.columns.location')}</th>
          <th>{t('assetView.failedAssetsGrid.columns.testDate')}</th>
          <th>{t('assetView.failedAssetsGrid.columns.reason')}</th>
        </tr>
      </thead>
    )
  }

  renderTableRows(data) {
    return _.map(data.failedAssets, (asset) => {
      let locale = localStorage.getItem(Consts.I18N_LANGUAGE) ?
        localStorage.getItem(Consts.I18N_LANGUAGE).substring(0, 2) :
        'de'
      return (
        !asset ? {} :
          <tr key={asset.id}>
            <td>{asset.id}</td>
            <td>{asset.type}</td>
            <td>{asset.location}</td>
            <td>{new Date(asset.testDate).toLocaleDateString(locale)}</td>
            <td>{asset.failureReason}</td>
          </tr>
      )
    })
  }

  render() {
    const { flags, t } = this.props
    if (flags.failedAssetsGrid)
      return (
        <Query query={Queries.FAILEDASSETS_QUERY}>
          {({ data, loading }) => {
            if (loading || !data || !data.failedAssets || data.failedAssets.length === 0) {
              return null
            }

            return (
              <div>
                <h5>
                  {t('assetView.failedAssetsGrid.title')}
                </h5>
                <table id="failedAssets" className="table">
                  {this.renderTableHeader(t)}
                  <tbody>
                    {this.renderTableRows(data)}
                  </tbody>
                </table>
              </div>
            )
          }
          }
        </Query>
      )
    else
      return null
  }
}

export default withLDConsumer()(translate('assets', { wait: true })(withRouter(FailedAssetsGrid)))