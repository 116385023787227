import React from 'react'
import {i18n} from '../../config'

const PasswordValidationRules = ({passwordRules}) => {
  if (!passwordRules) {
    return <b></b>
  } else {
    return <p className="white f5">
      {i18n.t('common:useractivation.validationRules.minLength', {minLength: passwordRules.minLength})}
      {passwordRules.minLowercaseCount || passwordRules.minUppercaseCount || passwordRules.minDigitCount ?
        i18n.t('common:useractivation.validationRules.containText') : ''}
      {passwordRules.minLowercaseCount ?
        i18n.t('common:useractivation.validationRules.minLowercaseCount', {minLowercaseCount: passwordRules.minLowercaseCount}) : ''}
      {passwordRules.minUppercaseCount ?
        i18n.t('common:useractivation.validationRules.minUppercaseCount', {minUppercaseCount: passwordRules.minUppercaseCount}) : ''}
      {passwordRules.minDigitCount ?
        i18n.t('common:useractivation.validationRules.minDigitCount', {minDigitCount: passwordRules.minDigitCount}) : ''}
    </p>
  }
}

export default PasswordValidationRules
