import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import {reactI18nextModule} from 'react-i18next'
import Consts from './constants'

import faqDE from './locale/de/faq.json'
import loginFaqDE from './locale/de/loginfaq.json'
import gdprDE from './locale/de/gdpr.json'
import imprintDE from './locale/de/imprint.json'
import commonDE from './locale/de/common.json'
import routesDE from './locale/de/routes.json'
import errorsDE from './locale/de/errors.json'
import assetsDE from './locale/de/assets.json'
import assetDetailDE from './locale/de/assetDetail.json'
import dashboardDE from './locale/de/dashboard.json'
import dueDatesDE from './locale/de/dueDates.json'
import provisionInspectionDE from './locale/de/provisionInspection.json'
import riskAssessmentsDE from './locale/de/riskAssessments.json'


import faqEN from './locale/en/faq.json'
import loginFaqEN from './locale/en/loginfaq.json'
import gdprEN from './locale/en/gdpr.json'
import imprintEN from './locale/en/imprint.json'
import commonEN from './locale/en/common.json'
import routesEN from './locale/en/routes.json'
import errorsEN from './locale/en/errors.json'
import assetsEN from './locale/en/assets.json'
import assetDetailEN from './locale/en/assetDetail.json'
import dashboardEN from './locale/en/dashboard.json'
import dueDatesEN from './locale/en/dueDates.json'
import provisionInspectionEN from './locale/en/provisionInspection.json'
import riskAssessmentsEN from './locale/en/riskAssessments.json'





const lngDetector = new LanguageDetector()
lngDetector.addDetector({
  name: 'customDetector',
  lookup(options) {
    let nav = window.navigator,
      browserLanguagePropertyKeys = ['language', 'browserLanguage', 'systemLanguage', 'userLanguage'],
      i,
      language

    // support for HTML 5.1 "navigator.languages"
    if (Array.isArray(nav.languages)) {
      for (i = 0; i < nav.languages.length; i++) {
        language = nav.languages[i]
        if (language && language.length) {
          return language
        }
      }
    }

    // support for other well known properties in browsers
    for (i = 0; i < browserLanguagePropertyKeys.length; i++) {
      language = nav[browserLanguagePropertyKeys[i]]
      if (language && language.length) {
        return language
      }
    }

    return 'de_DE'
  },

  cacheUserLanguage(lng, options) {
    if (lng.substring(0, 2) === 'en') {
      localStorage.setItem(Consts.I18N_LANGUAGE, 'en')
    } else {
      localStorage.setItem(Consts.I18N_LANGUAGE, 'de')
    }
  },
})

i18n
  .use(lngDetector)
  .use(reactI18nextModule)
  .init({
    ns: [
      'common',
      'gdpr',
      'faq',
      'loginfaq',
      'routes',
      'errors',
      'assets',
      'assetDetail',
      'dashboard'
    ],
    interpolation: { escapeValue: false },
    defaultNS: 'common',
    resources: {
      en: {
        common: commonEN, // 'common' is our custom namespace
        gdpr: gdprEN,
        imprint: imprintEN,
        faq: faqEN,
        loginfaq: loginFaqEN,
        routes: routesEN,
        errors: errorsEN,
        assets: assetsEN,
        assetDetail: assetDetailEN,
        dashboard: dashboardEN,
        dueDates: dueDatesEN,
        provisionInspection: provisionInspectionEN,
        riskAssessments: riskAssessmentsEN
      },
      de: {
        common: commonDE,
        gdpr: gdprDE,
        imprint: imprintDE,
        faq: faqDE,
        loginfaq: loginFaqDE,
        routes: routesDE,
        errors: errorsDE,
        assets: assetsDE,
        assetDetail: assetDetailDE,
        dashboard: dashboardDE,
        dueDates: dueDatesDE,
        provisionInspection: provisionInspectionDE,
        riskAssessments: riskAssessmentsDE

      },
    },
    detection: {
      order: ['localStorage', 'customDetector'],
      lookupLocalStorage: Consts.I18N_LANGUAGE,
      caches: ['localStorage'],
    },
  })

export default i18n
