import React, { Component } from 'react'
import Autosuggest from 'react-autosuggest'


export default class AutoSuggestion extends Component {
  constructor() {
    super()

    this.state = {
      value: '',
      suggestions: [],
      options: []
    }
  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue
    })
  }

  getSuggestionValue = suggestion => suggestion

  renderSuggestion = suggestion => (
    <div>
      {suggestion}
    </div>
  )

  getSuggestions = ({ value }) => {
    const { name, options, handleLocationChange } = this.props

    const inputValue = value.trim().toLowerCase()
    const inputLength = inputValue.length
    handleLocationChange(name, value)

    return inputLength === 0 || !options ? [] :  options.filter(option =>
      option.toLowerCase().includes(inputValue)
    )
  }
  onSuggestionsFetchRequested = (value) => {
    this.setState({
      suggestions: this.getSuggestions(value)
    })
  }

  onSuggestionsClearRequested = () => {
    const { name, handleLocationChange } = this.props
    handleLocationChange(name, this.state.value)

    this.setState({
      suggestions: []
    })
  }

  render() {
    const { value, suggestions } = this.state
    const {name, val} = this.props

    const inputProps = {
      id:name,
      placeholder: '',
      value: value === '' ? val : value,
      // value: value,
      onChange: this.onChange
    }
    const theme = {
      container: 'autosuggest',
      input: 'form-control',
      suggestionsContainer: 'dropdown',
      suggestionsList: `dropdown-menu ${suggestions.length ? 'show' : ''}`,
      suggestion: 'dropdown-item',
      suggestionHighlighted: 'active'
    }

    return (
      <Autosuggest
        theme={theme}
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        inputProps={inputProps}
      />
    )
  }
}