import {HttpLink, InMemoryCache, ApolloClient} from 'apollo-client-preset'
import {WebSocketLink} from 'apollo-link-ws'
import {ApolloLink, split} from 'apollo-link'
import {onError} from 'apollo-link-error'
import {getMainDefinition} from 'apollo-utilities'
import {withClientState} from 'apollo-link-state'

import Consts from '../constants'
import defaults from '../defaults'
import resolvers from '../resolvers'
import typeDefs from '../typeDefs'
import Errors from '../errors'
import {helpers} from '../helpers'

export default class ReactApolloClient {

  constructor(Config) {
    if (ReactApolloClient.instance) {
      return ReactApolloClient.instance
    }
    ReactApolloClient.instance = this

    this.config = Config
    this.client = null
    this.initialize()

    return this
  }

  static client() {
    return this.client
  }

    initialize = () => {
      const token = localStorage.getItem(Consts.AUTH_TOKEN) || null
      const httpLink = new HttpLink({uri: `${this.config.gqlHttp}`})

      const middlewareLink = new ApolloLink((operation, forward) => {
        // return the headers to the context so httpLink can read them
        operation.setContext({
          headers: {
            authentication: `Bearer ${token}`,
          },
        })
        return forward(operation)
      })

      // authenticated httplink
      const httpLinkAuth = middlewareLink.concat(httpLink)

      const wsLink = new WebSocketLink({
        uri: `${this.config.gqlSocket}`,
        options: {
          reconnect: true,
          connectionParams: {
            Authorization: `Bearer ${token}`,
          },
        },
      })

      const cache = new InMemoryCache()

      const stateLink = withClientState({
        cache: cache,
        resolvers,
        defaults,
        typeDefs,
      })

      const errorLink = onError(({networkError, graphQLErrors, forward, operation}) => {
        // networkError is defined when the response is not a valid GraphQL response, e.g. the server is completely down
        if (networkError) {
          cache.reset().then(() => {
            cache.writeData({
              data: {
                ErrorState: {
                  __typename: 'ERRORSTATE_CACHE',
                  isInError: true,
                  error: {__typename: 'ERROR', ...Errors.NetworkError},
                },
              },
            })
          })
        }

        if (graphQLErrors) {
          const reactErr = helpers.gqlErrorHandler(graphQLErrors)

          if (reactErr) {
            switch (reactErr.name) {
              case Errors.AuthorizationError.name:
              case Errors.AuthenticationError.name:
              case Errors.ServerError.name: {
                cache.writeData({
                  data: {
                    ErrorState: {
                      __typename: 'ERRORSTATE_CACHE',
                      isInError: true,
                      error: {__typename: 'ERROR', ...reactErr},
                    },
                  },
                })
                break
              }
              default: {
                cache.writeData({
                  data: {
                    ErrorState: {
                      __typename: 'ERRORSTATE_CACHE',
                      isInError: false,
                      error: {__typename: 'ERROR', name: null, message: null, title: null},
                    },
                  },
                })

                return forward(operation)
              }
            }
          }

          return forward(operation)
        }
      })

      const link = split(

        // split based on operation type
        ({query}) => {
          const {kind, operation} = getMainDefinition(query)
          return kind === 'OperationDefinition' && operation === 'subscription'
        },
        wsLink,
        httpLinkAuth,
      )

      // apollo client setup
      this.client = new ApolloClient({
        link: ApolloLink.from([errorLink, stateLink, link]),
        cache: cache,
        connectToDevTools: true,
        defaultOptions: {
          watchQuery: {
            fetchPolicy: 'cache-and-network',
            errorPolicy: 'all',
          },
          query: {
            fetchPolicy: 'cache-and-network',
            errorPolicy: 'all',
          },
          mutate: {
            errorPolicy: 'all',
          },
        },
      })

      this.client.onResetStore(stateLink.writeDefaults)
    }
}
