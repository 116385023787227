import React, {Component} from 'react'

import { Row, Col } from 'reactstrap'

import AssetDetail from './AssetDetail/AssetDetail'

class AssetDetailView extends Component{
    render(){
        return(
            <Row className="mv4">
                <Col className=""  md="12" xl="8">
                    <AssetDetail />
                </Col>
            </Row>
        )
    }
}

export default AssetDetailView
