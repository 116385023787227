import gql from 'graphql-tag'

/* QUERIES */
const ASSETS_QUERY = gql`
 query($term: String!) {
  searchResult(term: $term) {
     isFailed,
     results
 {
   type,
   id,
   metadata
    {
       key,
       value
    }
 }
}
}
`

const Queries = {
    ASSETS_QUERY
  }


export {Queries}