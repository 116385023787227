import React, {Component} from 'react'
import {Collapse, NavLink, CardHeader, Card, CardBody, Col, Row} from 'reactstrap'
import {translate} from 'react-i18next'
import {Config} from '../../config'

class FAQ extends Component {
  constructor(props) {
    super(props)
    this.state = {collapse: 0}
  }

  toggle = (e) => {
    e.preventDefault()

    let event = e.target.dataset.event
    this.setState({collapse: this.state.collapse === Number(event) ? 0 : Number(event)})
  }

  componentDidMount() {
    this.setState({collapse: 1})
  }

  render() {
    const {t} = this.props
    const {collapse} = this.state

    return (
      <div className="pt4">
        <Row className="justify-content-center">
          <Col md={7}>
            <div className="f2 mb4 text-center">{t('title')}</div>
            <div className="mb4 tj">
              <p className="f4 fw5 mb0">{t('p1')}</p>
              <p className="f4 fw5 mb0">{t('p2')}</p>
              <p className="f4 fw5">{t('p3')}</p>
            </div>
            {
              [...Array(Number(Config.faqCount) || 0).keys()].map((item) => {
                return (
                  <Card className="mb-2 wg-box-shadow animated fadeIn" key={item + 1}>
                    <CardHeader onClick={this.toggle}>
                      <NavLink onClick={this.toggle} href="#" className="f5 mb0 pa0 black" data-event={item + 1}>
                        <b data-event={item + 1}>{t('q' + (item + 1).toString())}</b>
                      </NavLink>
                    </CardHeader>
                    <Collapse isOpen={collapse === item + 1}>
                      <CardBody className="tj">
                        <span dangerouslySetInnerHTML={{__html: t('a' + (item + 1).toString())}} />
                      </CardBody>
                    </Collapse>
                  </Card>
                )
              })
            }
            <div className="f2 mt5 mb3 text-center">{t('contact')}</div>
            <div className="f4 fw5">{t('email')} <a href="mailto:support.portal@oms-pruefservice.de">support.portal@oms-pruefservice.de</a></div>
            <div className="f4 fw5">{t('tel')}  <a href="tel:+49 (0)7172 / 1839218">+49 (0)7172 / 1839218</a></div>
            <div className="f6 fw5">{t('schedule')}</div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default translate('faq', {wait: true})(FAQ)
