import gql from 'graphql-tag'

/* QUERIES */

const GET_ERROR_STATE = gql`
query ErrorState {
  ErrorState @client {
    isInError,
    error {
      title,
      name,
      message
    },
  }
}
`

const Queries = {
  GET_ERROR_STATE,
}

const Mutations = {
}

export {Queries, Mutations}

