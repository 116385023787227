import React, {Component} from 'react'
import StaffMemberActivation from '../../../components/StaffMemberActivation/StaffMemberActivation'

class StaffActivation extends Component {
  render() {
    return (
      <StaffMemberActivation link={this.props.match.params.link} />
    )
  }
}

export default StaffActivation
