import React, { Component } from 'react'
import { translate } from 'react-i18next'
import { Card, CardHeader, Col, Row, CardBody } from 'reactstrap'

import ProjectSearch from '../ProjectSearch/ProjectSearch'
import ProjectList from './ProjectList'
import ProjectPagination from '../ProjectPagination/ProjectPagination'

class ProjectListCounter extends Component {
  render() {
    const { projectCount } = this.props
    return (
      ' (' + projectCount + ')'

    )
  }
}

class ProjectListContainer extends Component {
  render() {
    const { t, handleSetPage, handleProjectCount, projectCount, handleSetPageSize, page, pageSize } = this.props
    return (<div className="animated fadeIn">
      <Row>
        <Col xl={12}>
          <Card>
            <CardHeader>
              <h4>{t('project.list.title')} <ProjectListCounter projectCount={projectCount} /></h4>
            </CardHeader>
            <CardHeader>
              <ProjectSearch handleSetPage={handleSetPage}/>
            </CardHeader>
            <CardBody className="m-0">
              <ProjectList
                page={page}
                handleProjectCount={handleProjectCount}
                handleSetPageSize={handleSetPageSize}
              />
              <ProjectPagination
                projectCount={projectCount}
                handleSetPage={handleSetPage}
                page={page}
                pageSize={pageSize}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
    )
  }
}
export default translate()(ProjectListContainer)
