import React from "react";
import { Document, Page, pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


export default function PdfViewer(props) {

    const { url } = props.location.state
    console.log(url)
    return (
            <div className="d-flex justify-content-center">
                <Document
                    file={url}
                >
                    <Page className="PDFPage" pageNumber={1} scale={96 / 72} renderMode="svg" />
                </Document>
            </div>
    );
}
