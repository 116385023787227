import React, { Component } from "react";

class UrlRedirect extends Component {
  
    componentDidMount() {
    const { searchParams, url } = this.props;

    let uri = new URL(url);

    for (const paramName in searchParams) {
      uri.searchParams.append(paramName, searchParams[paramName]);
    }
    window.location.assign(uri.href);
  }

  render() {
    return <span data-test-component="UrlRedirect"></span>;
  }
}

export default UrlRedirect
