import React, {Component} from 'react'
import {Card, CardBody, CardGroup, Col, Container, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Row, NavLink} from 'reactstrap'
import {withRouter} from 'react-router-dom'
import {Form, Field} from 'react-final-form'
import {Mutation} from 'react-apollo'
import {translate} from 'react-i18next'
import {Mutations} from './ResetPasswordGQL'
import TextFieldAdapter from '../../base/Form/TextFieldAdapter'
import validators from '../../base/Form/Validators'
import LoadingButton from '../../base/LoadingButton'
import ButterToast, {CinnamonSugar} from 'butter-toast'

class ResetPassword extends Component {
  constructor(props) {
    super(props)

    this.state = {...this.buildState(), ...this.props.location.state}
    this.finalFormRef = React.createRef()
  }

  buildState = () => ({
    emailReset: false,
    err: null,
  })

  componentDidMount() {
    const _self = this //eslint-disable-line
    setTimeout(() => {
      if (_self.state.invalidActivationLink) {
        const toast = CinnamonSugar.crisp({
          theme: 'error',
          icon: 'exclamation',
          title: <div className="tj">{_self.props.t('resetpass.activationError')}</div>,
          toastTimeout: 14000,
          dismissOnClick: true,
        })

        ButterToast.raise(toast)

        _self.setState({invalidActivationLink: null})
      }
    })
  }

  componentDidUpdate() {
    if (this.state.err) {
      const toast = CinnamonSugar.crisp({
        theme: 'error',
        icon: 'exclamation',
        title: <div className="tj">{this.props.t('resetpass.error')}</div>,
        toastTimeout: 14000,
        dismissOnClick: true,
      })

      ButterToast.raise(toast)

      this.setState({err: null})
    }
  }

  render() {
    const {t} = this.props

    return (
      <Mutation mutation={Mutations.RESET_PASSWORD_MUTATION}>
        {(resetPassword, {loading, error}) => {
          return (
            <div className="app flex-row align-items-center login-cover">
              <Container>
                <Row className="justify-content-center">
                  <Col md={6}>
                    <CardGroup>
                      <Card className="p-4" style={{backgroundColor: 'transparent', border: 'none'}}>
                        <CardBody>
                          <div className="transparent-bg">

                            <Form
                              onSubmit={async values => {
                                this.setState({emailReset: false})
                                const {email} = values

                                try {
                                  await resetPassword({
                                    variables: {
                                      email: email,
                                    },
                                  })

                                  this.setState({emailReset: true})
                                } catch (e) {
                                  this.setState({err: e})
                                }
                              }}
                              render={({ handleSubmit, submitting, form }) => (
                                <form
                                  onSubmit={async e => {
                                    await handleSubmit(e);
                                    form.reset();
                                  }}
                                >
                                  <div className="h2 white tc mb2">{t('resetpass.title')}</div>
                                  <p className="white tc">{t('resetpass.instructions')}</p>
                                  <FormGroup className="pt-3">
                                    <InputGroup>
                                      <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                          <i className="icon-lock"></i>
                                        </InputGroupText>
                                      </InputGroupAddon>
                                      <Field
                                        disabled={submitting}
                                        name="email"
                                        id="email"
                                        type="email"
                                        placeholder={t('resetpass.emailPlaceholder')}
                                        component={TextFieldAdapter}
                                        validate={validators.required}
                                        onChange={e => this.setState({emailReset: false})}
                                      />
                                    </InputGroup>
                                  </FormGroup>
                                  <Row>
                                    <Col xs="12">
                                      <LoadingButton type="submit" color="primary" className="px-4" style={{width: '100%'}}
                                        loading={submitting || loading ? 'true' : undefined}>
                                        {t('common.submit')}
                                      </LoadingButton>
                                    </Col>
                                  </Row>
                                  {
                                    this.state.emailReset && !submitting && !loading &&
                                    <Row className="pt-2 pb-1">
                                      <Col xs="12">
                                        <p className="white"><b>{t('resetpass.successMessage')}</b></p>
                                      </Col>
                                    </Row>
                                  }
                                  {
                                    !submitting && !loading &&
                                    <Row className="pt-2">
                                      <Col xs="12">
                                        <NavLink className="white fw6 f5 underline" href="#"
                                          onClick={() => this.props.history.push('/login')}>{t('resetpass.loginNavLink')}
                                        </NavLink>
                                      </Col>
                                    </Row>
                                  }
                                </form>
                              )}
                            />
                          </div>
                        </CardBody>
                      </Card>
                    </CardGroup>
                  </Col>
                </Row>
              </Container>
              <ButterToast trayPosition="top-center"/>
            </div>
          )
        }}
      </Mutation>
    )
  }
}

export default translate()(withRouter(ResetPassword))
