import React, {Component} from 'react'
import {Col, Row} from 'reactstrap'
import ProjectResultGroupFiles from './ProjectResultGroupFiles'

class ProjectResultGroup extends Component {
  render() {
    return (
      <Row>
        <Col xl={12} lg={12} md={12} sm={12}>
          <ProjectResultGroupFiles
            uploadedFiles={this.props.approvedRevision.uploadedFiles}
            generatedFiles={this.props.approvedRevision.generatedFiles} />
        </Col>
      </Row>
    
    )
  }
}

export default ProjectResultGroup
