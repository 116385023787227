import React, { Component } from "react";
import { Table } from "hyperio.react.library";

import { SvgIcon, notification, info } from "hyperio.react.library";
import StatusIcon from "../../Helpers/StatusIcon/StatusIcon";
import { pageSize } from "../../Helpers/CsmConfig";

const cols = [
  {
    id: 1,
    header: "Status",
    key: "status",
    component: row => (
      <StatusIcon status={row.statusDesc} visible={row.notificationSent} />
    ),
    sortable: true
  },
  {
    id: 2,
    header: "THRESHOLD VALUE VIOLATION IN %",
    key: "threshold",
    sortable: true
  },
  {
    id: 3,
    header: "Location",
    key: "location",
    sortable: true
  },

  {
    id: 5,
    header: "Notification Send",
    key: "notificationsent",
    sortable: true,
    component: row => (
      <div
        style={{
          display: "inline-block",
          marginLeft: "10px"
        }}
      >
        {row.notificationSent ? (
          <SvgIcon style={{ color: "#00a3e0" }} icon={notification} />
        ) : (
          "-"
        )}
      </div>
    )
  },
  {
    id: 6,
    header: "",
    key: "info",
    sortable: false,
    component: <SvgIcon style={{ color: "#00a3e0" }} icon={info} />
  }
];

const data = [
  {
    status: "E",
    statusDesc: "error",
    threshold: "551,74%",
    location: "Heidelberg/Building/1/Kitchen/UV4/Plug 15",
    notificationSent: true
  },
  {
    status: "E",
    statusDesc: "error",
    threshold: "140,30%",
    location: "Heidelberg/Building/1/ Kitchen/UV4/Plug 12",
    notificationSent: false
  },
  {
    status: "E",
    statusDesc: "error",
    threshold: "163,12%",
    location: "Heidelberg/Building/2/ Camp/UV 4",
    notificationSent: true
  },
  {
    status: "E",
    statusDesc: "error",
    threshold: "111.30%",
    location: "Heidelberg/Building/2/ Camp/UV 5",
    notificationSent: false
  },
  {
    status: "E",
    statusDesc: "error",
    threshold: "234,43%",
    location: "Mannheim/Building/1/Kitchen/UV3/Plug 5",
    notificationSent: false
  },
  {
    status: "E",
    statusDesc: "error",
    threshold: "163,12%",
    location: "Mannheim/Building/1/ Lab/UV5/Plug 6",
    notificationSent: true
  },
  {
    status: "E",
    statusDesc: "error",
    threshold: "114,30%",
    location: "Mannheim/Building/1/ Lab/UV5/Plug 7",
    notificationSent: true
  },
  {
    status: "E",
    statusDesc: "error",
    threshold: "334,43%",
    location: "Mannheim/Building/1/ Office 1/UV3",
    notificationSent: false
  },
  {
    status: "E",
    statusDesc: "error",
    threshold: "211.30%",
    location: "Mannheim/Building/1/ Meeting room 1/UV5",
    notificationSent: false
  },
  {
    status: "E",
    statusDesc: "error",
    threshold: "334,43%",
    location: "Mannheim/Building/1/ Meeting room 1/UV5/Plug 10",
    notificationSent: false
  }
];

export default class EventsTable extends Component {
  render() {
    return (
      <div>
        <Table columns={cols} data={data} pageSize={pageSize.eventsTable} />
      </div>
    );
  }
}
