import './polyfill'
import React from 'react'
import { render } from 'react-dom'
import './index.css'
import App from './App'
import { Config } from './config'
import Consts from './constants'

import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: Config.launchDarklyKey,
    user: {
      "key": localStorage.getItem("Username") || "",
      "name": localStorage.getItem("Username") || "",
      "email": localStorage.getItem(Consts.USER_EMAIL) || ""

    },
    options: { /* ... */ }
  });

  render(
    <LDProvider>
      <App />
    </LDProvider>,
    document.getElementById('root'),
  );
})();