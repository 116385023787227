
import React from 'react'
import {Row, Button} from 'reactstrap'
import {translate} from 'react-i18next'
import {withRouter} from 'react-router-dom'
import {UserSnap} from '../../integrations'
import Consts from '../../constants'

import {GoogleAnalytics} from '../../config'

class Error extends React.Component {
  render() {
    const {t, error} = this.props

    GoogleAnalytics.set({
      page: '/error',
      error: error.message,
    })
    GoogleAnalytics.exception({
      description: error.message,
      fatal: true,
    })

    return (
      <React.Fragment>
        <div className="bg-white w-auto-l vh-100-l">
          <Row>
            <div className="error-gif"></div>
          </Row>
          <Row className="flex-column items-center nt6">
            <div className="f2 fw6 mb2">{t(error.title)}</div>

            <div className="f4 fw4 mt1">{t(error.message)}</div>
            {
              error.message === Consts.SERVER_ERROR && (
                <React.Fragment>
                  < div className="f4 fw4 mt1" > {t(error.message + '1')}</div>
                  <div className="f4 fw4 mt1">
                    <Button color="link" className="f4 fw4 w-15" onClick={() => UserSnap.api.open()}>Support</Button>
                    {t(error.message + '2')}
                  </div>
                  < div className="f4 fw4 mt1">{t(error.message + '3')}</div>
                </React.Fragment>
              )

            }


          </Row>
          <Row className="mt-4 pt-3 pb-3 justify-center">
            <Button color="primary" className="btn-lg w-15 mr-1" onClick={() => window.location.reload()}>
              <i className="fa fa-refresh"></i><span className="ml-1">{t('windowreload')}</span>
            </Button>
            <Button color="primary" className="btn-lg  w-15 mr-1" onClick={() => this.props.history.push('/login')}>
              <i className="fa fa-sign-in"></i><span className="ml-1">{t('gologin')}</span>
            </Button>
          </Row>
        </div>
      </React.Fragment >
    )
  }
}

export default translate('errors', {wait: true})(withRouter(Error))
