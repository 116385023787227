import React, { useEffect, useRef } from 'react'
import {
    Row, Col, Card, CardHeader, CardBody,
    FormGroup,
    Label,
    Input,
    Alert,
    Button
} from 'reactstrap'

import LoadingButton from '../../base/LoadingButton'


const FormStepTwo = ({ t, comments, submitting, questions, formStep, valid, handleCommentButton, handleQuestionChange,
    handleCommentChange, getComment, handleBackButton, handleSubmit }) => {

    const isFirstTime = useRef(true)

    useEffect(() => {

        if (isFirstTime.current) {

            try {
                window.scroll(1,0)
            }
            catch (e) {
                window.scroll(1, 0)
            }
            isFirstTime.current = false
        }
    })

    return (
        <div>
            <Card>
                <CardHeader>
                    <Row>
                        <Col>
                            <p>
                                <span className="badge badge-blue">&#10003;</span>
                                <span className="badge badge-blue">2</span>
                                <span>{t('questions')}</span>
                            </p>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <h3 className="f4 mb4">Ordnungsprüfung</h3>
                    <Question title="1. CE-Kennzeichnung bzw. CE-Konformität ist vorhanden"
                        id="mark"
                        t={t}
                        visibleComment={comments['mark'].visible}
                        handleQuestionChange={(e) => handleQuestionChange(e)}
                        handleCommentButton={(name) => handleCommentButton(name)}
                        handleCommentChange={(e, name) => handleCommentChange(e, name)}
                        commentText={comments['mark'].text}
                        answer={questions['mark']}
                    />

                    <Question title="2. Betriebsanleitung zugänglich"
                        id="operation"
                        t={t}
                        visibleComment={comments['operation'].visible}
                        handleQuestionChange={(e) => handleQuestionChange(e)}
                        handleCommentButton={(name) => handleCommentButton(name)}
                        handleCommentChange={(e, name) => handleCommentChange(e, name)}
                        commentText={comments['operation'].text}
                        answer={questions['operation']}
                    />
                    <Question title="3. Betriebsmittel gemäß Herstellerangaben für Tätigkeit und Einsatzort geeignet"
                        id="suitable"
                        t={t}
                        visibleComment={comments['suitable'].visible}
                        handleQuestionChange={(e) => handleQuestionChange(e)}
                        handleCommentButton={(name) => handleCommentButton(name)}
                        handleCommentChange={(e, name) => handleCommentChange(e, name)}
                        commentText={comments['suitable'].text}
                        answer={questions['suitable']}
                    />

                    <Question title="4. Betriebsmittel wird entsprechend den Herstellerangaben aufgestellt und in Betrieb genommen"
                        id="setup"
                        t={t}
                        visibleComment={comments['setup'].visible}
                        handleQuestionChange={(e) => handleQuestionChange(e)}
                        handleCommentButton={(name) => handleCommentButton(name)}
                        handleCommentChange={(e, name) => handleCommentChange(e, name)}
                        commentText={comments['setup'].text}
                        answer={questions['setup']}
                    />

                    <h3 className="f4 mb4">Inaugenscheinnahme</h3>
                    <Question title="1. Aufschriften sind in ordnungsgemäßen Zustand"
                        id="inscription"
                        t={t}
                        visibleComment={comments['inscription'].visible}
                        handleQuestionChange={(e) => handleQuestionChange(e)}
                        handleCommentButton={(name) => handleCommentButton(name)}
                        handleCommentChange={(e, name) => handleCommentChange(e, name)}
                        commentText={comments['inscription'].text}
                        answer={questions['inscription']}
                    />

                    <Question title="2. Gehäuse ist in ordnungsgemäßen Zustand"
                        id="housing"
                        t={t}
                        visibleComment={comments['housing'].visible}
                        handleQuestionChange={(e) => handleQuestionChange(e)}
                        handleCommentButton={(name) => handleCommentButton(name)}
                        handleCommentChange={(e, name) => handleCommentChange(e, name)}
                        commentText={comments['housing'].text}
                        answer={questions['housing']}
                    />

                    <Question title="3. Leitung ist in ordnungsgemäßen Zustand"
                        id="line"
                        t={t}
                        visibleComment={comments['line'].visible}
                        handleQuestionChange={(e) => handleQuestionChange(e)}
                        handleCommentButton={(name) => handleCommentButton(name)}
                        handleCommentChange={(e, name) => handleCommentChange(e, name)}
                        commentText={comments['line'].text}
                        answer={questions['line']}
                    />
                    <Question title="4. Stecker ist in ordnungsgemäßen Zustand"
                        id="plug"
                        t={t}
                        visibleComment={comments['plug'].visible}
                        handleQuestionChange={(e) => handleQuestionChange(e)}
                        handleCommentButton={(name) => handleCommentButton(name)}
                        handleCommentChange={(e, name) => handleCommentChange(e, name)}
                        commentText={comments['plug'].text}
                        answer={questions['plug']}
                    />

                    <h3 className="f4 mb4">Funktionsprüfung</h3>
                    <Question title="1. Alle Schutzeinrichtungen wurden erfolgreich erprobt"
                        id="protective"
                        t={t}
                        visibleComment={comments['protective'].visible}
                        handleQuestionChange={(e) => handleQuestionChange(e)}
                        handleCommentButton={(name) => handleCommentButton(name)}
                        handleCommentChange={(e, name) => handleCommentChange(e, name)}
                        commentText={comments['protective'].text}
                        answer={questions['protective']}
                    />

                    <Question title="2. Funktionen des Betriebsmittel wurde erfolgreich erprobt"
                        id="function"
                        t={t}
                        visibleComment={true}
                        handleQuestionChange={(e) => handleQuestionChange(e)}
                        handleCommentButton={(name) => handleCommentButton(name)}
                        handleCommentChange={(e, name) => handleCommentChange(e, name)}
                        commentText={comments['function'].text}
                        answer={questions['function']}
                    />

                    <FormGroup>
                        <Label className="b" htmlFor="generalComment">{t('generalComment')}(optional)</Label>
                        <Input type="text" id="generalComment" name="generalComment"
                            value={comments['general'].text}
                            onChange={(e) => handleCommentChange(e, 'general')}
                        />
                    </FormGroup>

                    {
                        formStep ? valid ?
                            <Alert color="success">
                                {t('testPassed')}
                            </Alert>
                            :
                            <Alert color="danger">
                                {t('testFailed')}
                            </Alert>
                            :
                            null
                    }

                    <Button onClick={(e) => handleBackButton(e)} className="width-130" outline color="info">{t('back')}</Button>
                    <LoadingButton type="submit" color="primary" className="ml2 width-130"

                        onClick={(e) => handleSubmit(e)}
                        loading={submitting ? 'true' : undefined} >{t('finish')}</LoadingButton>
                </CardBody>
            </Card>
        </div>

    )

}


export default FormStepTwo

const Question = ({ t, title, id, answer, handleQuestionChange, visibleComment,
    handleCommentButton,
    handleCommentChange,
    commentText }) =>
    <FormGroup>
        <Label>{title}</Label>
        <FormGroup check className="radio, radio-item radio-item-green">
            <Input className="form-check-input" type="radio" id={`${id}1`} name={id} value="Ja"
                checked={answer}
                onChange={(e) => handleQuestionChange(e)}
            />
            <Label check className="form-check-label" htmlFor={`${id}1`}>Ja</Label>
        </FormGroup>
        <FormGroup check className="radio radio-item radio-item-red">
            <Input className="form-check-input" type="radio" id={`${id}2`} name={id} value="Nein"
                checked={!answer}
                onChange={(e) => handleQuestionChange(e)}

            />
            <Label check className="form-check-label" htmlFor={`${id}2`}>Nein</Label>
        </FormGroup>
        {
            visibleComment ?
                <FormGroup className='mv3 ml3'>
                    <Label htmlFor={`${id}Comment`}>{t('comment')} (optional)</Label>
                    <Input
                        value={commentText}
                        onChange={(e) => handleCommentChange(e, id)}
                        type="text"
                        id={`${id}Comment`}
                        name={`${id}Comment`} />
                </FormGroup>
                :
                <FormGroup>
                    <div className="mv3">
                        <a className="text-primary" color="primary"
                            
                            onClick={() => handleCommentButton(id)}
                        >
                            {t('addComment')}</a>
                    </div>
                    <FormGroup id={`${id}Group`} className='dn ml3'>
                        <Label htmlFor={`${id}Comment`}>{t('comment')} (optional)</Label>
                        <Input
                            value={commentText}
                            onChange={(e) => handleCommentChange(e, id)}
                            type="text"
                            id={`${id}Comment`}
                            name={`${id}Comment`} />
                    </FormGroup>
                </FormGroup>
        }


    </FormGroup>