import React, { useState, useEffect } from 'react'
import { compose, graphql, Mutation } from 'react-apollo'
import {
    Row, Col, Card, Label, Input,
    CardHeader, CardBody, FormGroup,
    Button, Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Spinner from '../../../base/Spinner'
import { Queries, Mutations } from '../RiskAssessmentGQL'
import TestInterval from './TestInterval'
import LoadingButton from '../../../base/LoadingButton'


function EditRiskAssessmentForm({ t, isEdit, setIsEdit, isCustomerMigrated, selectedRiskAssessment, handleSetSelectedRiskAssessment, questionQuery, riskAssessmentQuery }) {

    if (riskAssessmentQuery.error) return riskAssessmentQuery.error
    if (questionQuery.error) return questionQuery.error

    if (riskAssessmentQuery.loading || questionQuery.loading || selectedRiskAssessment === null) return <Spinner />

    const { title: currentTitle, history } = riskAssessmentQuery.riskAssessment

    const getVersionFromRevision = (revision) => {
        const revisionComponents = revision.split('-');
        return  parseInt(revisionComponents[revisionComponents.length - 1], 10) 
    }

    const versions = history.map(h => getVersionFromRevision(h.revision));   

    const latestVersion = versions[versions.length - 1]

    const [version, setVersion] = useState(getVersionFromRevision(selectedRiskAssessment));
    const [currentVersion] = useState(latestVersion)

    const choices = (isEdit && version === currentVersion) ? questionQuery.answeredQuestion.answeredQuestions : riskAssessmentQuery.riskAssessment.choices;
    const [questions, setQuestions] = useState(choices);

    useEffect(() => {
        setQuestions(choices);
    }, [isEdit, selectedRiskAssessment])

    const [title] = useState(currentTitle)
    const [generalComment, setGeneralComment] = useState('')
    const [interval, setInterval] = useState(0)
    const [submitting, setSubmiting] = useState(false)
    const [showModal, setShowModal] = useState(false)

    const handleConfirmUpdate = createRiskAssessment => {
        handleSave(createRiskAssessment)
        toggle()

    }

    const toggle = () => {
        setShowModal(!showModal)
    }

    const getRevisionFromVersion = (version) => {

        const revisionComponents = selectedRiskAssessment.split('-')
        revisionComponents[revisionComponents.length - 1] = version
        return revisionComponents.join('-')
    }

    const handleVersionChange = e => {
        setVersion(parseInt(e.target.value, 10))
        setIsEdit(false)
        handleSetSelectedRiskAssessment(getRevisionFromVersion(e.target.value));
    }

    const success = () => {
        return toast.success(`${t("successMessage.line1")}\n${t('successMessage.line2')}`, {

            onClose: () => {
                setSubmiting(true)
                window.location.href = '/riskAssessment'
            }
        });
    }

    const errorToast = message => {
        return toast.error(message, {});
    }
    const handleQuestionChange = (e, questionIndex) => {
        const { value } = e.target
        const currentQuestion = questions[questionIndex]

        currentQuestion.selectedAnswer = parseInt(value, 10)
        const newQuestions = [...questions.slice(0, questionIndex), currentQuestion, ...questions.slice(questionIndex + 1, questions.length)]
        setQuestions(newQuestions)

    }

    const getQuestions = (questions) => {
        return questions.map(item => {
            return (
                {
                    revision: item.question.revision,
                    selectedAnswer: item.selectedAnswer,
                }
            )
        })
    }
    const handleSave = async createRiskAssessment => {

        // validateForm()
        const inputQuestions = getQuestions(questions)

        // if (interval === 0 || title === '') return

        setSubmiting(true)

        try {

            const { data } = await createRiskAssessment({
                variables: {
                    data: {
                        basedOnAssessment: selectedRiskAssessment,
                        title: title.trim(),
                        comment: generalComment.trim(),
                        questions: inputQuestions,
                        interval
                    }
                }
            })
            const { errors } = data.createRiskAssessment
            if (errors && errors.length) {
                errorToast(errors[0].message || 'There was an error submitting the test result.')
            } else {
                success()
            }

        } catch (e) {
            console.log('error:', e)
        }

    }
    const containerStyle = {
        zIndex: 1999
    }
    return (
        <Mutation mutation={Mutations.CREATE_RISK_ASSESSMENT}>
            {(createRiskAssessment, { loading, error }) => {

                if (loading) return <Spinner />
                if (error) return <p>{error}</p>

                // setCreateRiskAssessment(createRiskAssessment)

                return (
                    <Row>
                        <ToastContainer position="top-right" autoClose={5000} style={containerStyle} />
                        <Modal isOpen={showModal} toggle={toggle}>
                            <ModalHeader toggle={toggle}>{t("confirmationTitle")}</ModalHeader>
                            <ModalBody>
                                {t("confirmationBody")}
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary" onClick={() => handleConfirmUpdate(createRiskAssessment)}>{t("update")}</Button>{' '}
                                <Button color="secondary" onClick={toggle}>{t("cancel")}</Button>
                            </ModalFooter>
                        </Modal>
                        <Col xs="12">
                            <Card>
                                <CardHeader>
                                    <Row>
                                        <Col xs="8">
                                            <div>{t('riskAssessmentForm')}</div>
                                        </Col>
                                        <Col xs="4" className="tr">
                                            {
                                                                                  
                                                isEdit ? <Button
                                                    className="ttu"
                                                    color="primary"
                                                    name="cancelButton"

                                                    onClick={() => setIsEdit(false)}
                                                >
                                                    {t('cancel')}</Button>
                                                    :
                                                    isCustomerMigrated ?
                                                    <div/> :
                                                    <Button
                                                        className="ttu"
                                                        color="primary"
                                                        name="updateButton"
                                                        disabled={version !== latestVersion}

                                                        onClick={() => setIsEdit(true)}
                                                    >
                                                        {t('update')}</Button>
                                            }


                                        </Col>
                                    </Row>

                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col xs="12">
                                            <FormGroup>
                                                <Input type="select" id="project"
                                                    // disabled={!isEdit}
                                                    value={version}
                                                    onChange={
                                                        (e) => handleVersionChange(e)
                                                    }
                                                >
                                                    {
                                                        versions.map((version, i) => <option key={i}
                                                            value={version}>{t("version")} - {version} {version === currentVersion ? t('current') : ''}</option>)
                                                    }
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <Label htmlFor="title">{t('title')}</Label>
                                                <Input
                                                    id="title"
                                                    type="text"
                                                    name="title"
                                                    value={title}
                                                    disabled
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    {
                                        questions.map(({ question, selectedAnswer }, i) => {

                                            const { title, answers } = question

                                            return <Question
                                                t={t}
                                                index={i}
                                                title={title}
                                                isEdit={isEdit}
                                                answers={answers}
                                                version={version}
                                                submitting={submitting}
                                                key={question.revision}
                                                selectedAnswer={selectedAnswer}
                                                currentVersion={currentVersion}
                                                handleQuestionChange={handleQuestionChange}
                                            />
                                        })
                                    }

                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <Label className="b" htmlFor="generalComment">
                                                    {t('generalComment')}(optional)
                                        </Label>
                                                <Input
                                                    type="text"
                                                    disabled={!isEdit}
                                                    id="generalComment"
                                                    name="generalComment"
                                                    value={generalComment}
                                                    className={submitting ? "disabled-form" : ""}
                                                    onChange={(e) => setGeneralComment(e.target.value)}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Label className="mb2">{t('suggestedInterval')}</Label><br></br>
                                            <TestInterval questions={questions}
                                                setInterval={setInterval}
                                            />

                                        </Col>
                                    </Row>
                                    <Row className="mt3">
                                        <Col>
                                            <LoadingButton type="submit" color="primary" className="ml2 width-130"
                                                disabled={!isEdit || currentVersion !== version}
                                                onClick={e => toggle(e, createRiskAssessment)}

                                                loading={submitting ? 'true' : undefined} >{t('save')}
                                            </LoadingButton>

                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                )
            }
            }
        </Mutation>
    )
}
export default compose(
    graphql(Queries.QUESTION,
        {
            name: "questionQuery",
            options: (props) => ({
                variables: {
                    revision: props.selectedRiskAssessment
                }
            })
        }
    ),
    graphql(
        Queries.EDIT_RISK_ASSESSMENT,
        {
            name: "riskAssessmentQuery",
            options: (props) => ({
                variables: {
                    revision: props.selectedRiskAssessment
                }
            })
        }
    )

)(EditRiskAssessmentForm)

export const Question = ({ index, title, answers, handleQuestionChange,
    selectedAnswer,
    submitting, isEdit,
    version, currentVersion }) => {

    return (<Row key={index}>
        <Col>

            <FormGroup>
                <Label>{index + 1}. {title}</Label>
                {
                    answers.map(({ answerText, answerId }, i) => <Answer
                        key={i}
                        id={answerId}
                        isEdit={isEdit}
                        version={version}
                        questionIndex={index}
                        answerText={answerText}
                        submitting={submitting}
                        selectedAnswer={selectedAnswer}
                        currentVersion={currentVersion}
                        handleQuestionChange={handleQuestionChange} />
                    )
                }
            </FormGroup>
        </Col>
    </Row>)
}


export const Answer = ({ questionIndex, id, answerText, selectedAnswer, handleQuestionChange, submitting, isEdit, version, currentVersion }) => {

    return (

        <FormGroup check className="radio" >
            <Input
                value={id}
                type="radio"
                disabled={!isEdit || version !== currentVersion}
                name={questionIndex}
                checked={selectedAnswer === id}
                id={`radio-${questionIndex}-${id}`}
                onChange={(e) => handleQuestionChange(e, questionIndex)}
                className={submitting ? "disabled-form" : "form-check-input"}
            />
            <Label check
                htmlFor={`radio-${questionIndex}-${id}`}
                className={submitting ? "disabled-form" : "form-check-label"}
            >
                {answerText}
            </Label>
        </FormGroup>
    )
}