import gql from 'graphql-tag'

const ASSETDETAIL_QUERY = gql`
query($assetId: String!){
    assetDetail(assetId: $assetId){
        assetType,
        assetManufacturer,
        id,
        testDate,
        nextTestDate,
        operatorName,
        operatorAddress,
        operatorPostCode,
        operatorCity,
        assetSite,
        assetBuilding,
        assetFloor,
        assetRoom,
        testResult,
        testSuccessful,
        testRegulation,
        assetSafetyClass,
        certificate {
            filename,
            fileExtension,
            fileSize,
            downloadFileId,
            downloadType
        },
        riskAssessment {
            filename,
            fileExtension,
            fileSize,
            downloadFileId,
            downloadType
        }
    }
}
`

const Queries = {
    ASSETDETAIL_QUERY
}

const Mutations = {}

export {Queries, Mutations}