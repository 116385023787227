import {apps, initializeApp, firestore} from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'
import {Config} from '../config'

(function() {
  const config = {
    apiKey: Config.FIREBASE_API_KEY,
    authDomain: 'customer-portal-notifications.firebaseapp.com',
    databaseURL: 'https://customer-portal-notifications.firebaseio.com',
    projectId: 'customer-portal-notifications',
    storageBucket: 'customer-portal-notifications.appspot.com',
    messagingSenderId: '581800006030',
    appId: '1:581800006030:web:e9956cc6dda153ed',
  }

  if (!apps.length) {
    initializeApp(config)
  }
})()

export default firestore()
