import React, {Component} from 'react'
import {Col, Container, Row} from 'reactstrap'
import {translate} from 'react-i18next'

class Page500 extends Component {
  render() {
    const {t} = this.props

    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="6">
              <span className="clearfix">
                <h1 className="float-left display-3 mr-4">500</h1>
                <h4 className="pt-3">{t('500.title')}</h4>
                <p className="text-muted float-left">{t('500.message')}</p>
              </span>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export default translate()(Page500)
