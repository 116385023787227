const defaults = {
  Project: {
    __typename: 'PROJECT_CACHE',
    selectedProject: null,
    searchString: null,
    pager: {
      __typename: 'PROJECT_CACHE_PAGER',
      page: 1,
      pageSize: 6,
      totalCount: 0,
    },
  },
  ErrorState: {
    __typename: 'ERRORSTATE_CACHE',
    isInError: false,
    error: {
      __typename: 'ERROR',
      title: null,
      name: null,
      message: null,
    },
  },
}

export default defaults
