import React, { Component } from "react";
import { Query } from "react-apollo";
import { Queries } from "../Queries/CsmGQL";
import { Table, SvgIcon, info } from "hyperio.react.library";
import StatusIcon from "../../Helpers/StatusIcon/StatusIcon";
import { pageSize } from "../../Helpers/CsmConfig";

const cols = [
  {
    id: 1,
    header: "Status",
    key: "status",
    component: row => (
      <StatusIcon status={row.statusDesc} visible={row.notificationSent} />
    ),
    sortable: false
  },

  {
    id: 2,
    header: "rooms",
    key: "rooms",
    sortable: true
  },
  {
    id: 3,
    header: "LIVE DATA",
    key: "currentMeasure",
    sortable: true
  },
  {
    id: 4,
    header: "THRESHOLD VIOLATIONS",
    key: "thresholdViolation",
    sortable: true
  },
  {
    id: 5,
    header: "THRESHOLD",
    key: "threshold",
    sortable: true
  },
  {
    id: 6,
    header: "MODBUS REGISTER",
    key: "modbusRegister",
    sortable: true
  },
  {
    id: 7,
    header: "THRESHOLD VALUE VIOLATION IN %",
    key: "threshold",
    sortable: true
  },
  {
    id: 8,
    header: "",
    key: "info",
    sortable: false,
    component: <SvgIcon style={{ color: "#00a3e0" }} icon={info} />
  }
];

export default class DetailGrid extends Component {
  render() {
    return (
      <div>
        <Query
          query={Queries.CABINETSDETAILS_QUERY}
          variables={{ term: this.props.selectedCabinet }}
        >
          {({ data, loading }) => {
            if (
              loading ||
              !data ||
              !data.cabinetsDetails ||
              data.cabinetsDetails.length === 0
            ) {
              return null;
            }

            const { cabinetsDetails } = data;

            return (
              <Table
                columns={cols}
                data={cabinetsDetails}
                pageSize={pageSize.detailGrid}
              />
            );
          }}
        </Query>
      </div>
    );
  }
}
