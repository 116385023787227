import React, { Component } from "react";
import DetailGrid from "./DetailGrid";

export default class DetailCard extends Component {
  render() {
    return (
      <div>
        <DetailGrid
          key={this.props.selectedCabinet}
          selectedCabinet={this.props.selectedCabinet}
        />
      </div>
    );
  }
}
