import React, { Component } from 'react'
import { Input, InputGroup } from 'reactstrap'
import { Mutations } from '../ProjectGQL'
import { graphql, compose } from 'react-apollo'
import { translate } from 'react-i18next'

const onClickHandler = graphql(Mutations.PERSIST_SEARCH_PROJECT_STRING_STATE_MUTATION, {
  name: 'onProjectSearch',
})

class ProjectSearch extends Component {
  constructor(props) {
    super(props)
    this.state = {
      searchString: '',
    }
  }
  render() {
    const { t, handleSetPage } = this.props

    return (
      <InputGroup>
        <Input type="search"
          placeholder={t('project.searchProject.placeholder')}
          onChange={(e) => {
            this.setState({ searchString: e.target.value })
          }}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              this.props.onProjectSearch({ variables: { searchString: this.state.searchString } })
              handleSetPage(0)
            }
          }} />
      </InputGroup>
    )
  }
}
export default compose(onClickHandler)(translate()(ProjectSearch))


