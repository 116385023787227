import React, {Component} from 'react'
import {Config} from '../../config'

export default class Wootric extends Component {

  componentDidMount() {
    let setupElement = document.createElement('script')
    setupElement.type = 'text/javascript'
    setupElement.async = true
    setupElement.innerHTML = `
    window.wootricSettings = {
       created_at: 1234567890,
       account_token: "${Config.wootricKey}"
   };
    `
    document.body.appendChild(setupElement)

    let wootricLibrary = document.createElement('script')
    wootricLibrary.type = 'text/javascript'
    wootricLibrary.async = true
    wootricLibrary.src = 'https://disutgh7q0ncc.cloudfront.net/beacon.js'

    document.body.appendChild(wootricLibrary)

  }
  render() {
    return (
      <div></div>
    )
  }
}
