const typeDefs = `
  type Error {
    title: String
    name: String
    stack: String
    message: String!
  }
`

export default typeDefs
