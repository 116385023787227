import GA from 'react-ga'

export default class GoogleAnalytics {
  constructor(Config) {
    if (GoogleAnalytics.instance) {
      return GoogleAnalytics.instance
    }

    this.initialize(Config.googleAnalyticsKey)

    GoogleAnalytics.instance = GA
    return GA
  }

  initialize = (key) => {
    GA.initialize(key || 'UA-0000000-0')
  }
}
