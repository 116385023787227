import React, { Component } from "react";
import classnames from "classnames";

import BellIcon from "./BellIcon";

import Truncate from "react-truncate";

import "./CabinetCard.css";

export default class CabinetCard extends Component {
  handleClick = () => {
    this.props.onCabinetCardClick(this.props.index, this.props.data.id, this.props.recordsNo);
  };

  render() {
    return (
      <div className="col-sm-6 col-md-4">
        <div
          className={classnames(
            "card",
            "cabinet-card",
            this.props.data.statusDesc,
            { active: this.props.active }
          )}
        >
          <div className="card-body" onClick={this.handleClick}>
            <div className="grid-container-row1">
              <div className="H3-Rubik-Regular cabinetName">
                {this.props.data.cabinetName}
              </div>
              <div className="rooms">
                <Truncate lines={2} ellipsis={<span>...</span>}>
                  {this.props.data.rooms}
                </Truncate>
              </div>

              <div>
                <BellIcon NotificationSent={this.props.data.notificationSent} />
              </div>
            </div>
            <div className="grid-container-row2">
              <div>
                <div className="Caption-Rubik-Regular label">CURRENT</div>
                <div
                  className={classnames(
                    "Numbers",
                    "current-value",
                    `${this.props.data.statusDesc}font`
                  )}
                >
                  {this.props.data.currentMeasure}
                </div>
              </div>
              <div>
                <div className="Caption-Rubik-Regular label">MAXIMUM</div>
                <div className="Numbers---small blackhighempasis maximum-value">
                  {this.props.data.highestMeasure}
                </div>
                <div className="Caption-Rubik-Regular labelhr">
                  {this.props.data.highestMeasureTime} AGO
                </div>
              </div>
              <div>
                <div className="Caption-Rubik-Regular label ">
                  IN THE LAST 24 HRS
                </div>
                <div
                  className={classnames("normalfont", {
                    errorfont: this.props.data.errorsCount > 0
                  })}
                >
                  <span className="Numbers---small">
                    {this.props.data.errorsCount}
                  </span>
                  <span className="Caption-Rubik-Regular"> ERROR</span>

                  {this.props.data.errorsCount !== 1 && (
                    <span className="Caption-Rubik-Regular">S</span>
                  )}
                </div>
              </div>

              <div className="Button-Rubik-Medium seedetails">SEE DETAILS</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
