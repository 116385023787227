import gql from 'graphql-tag'


const TEST_RESULT_UPLOAD = gql`
mutation testResultUpload($data:[TestResultInput]!){
    testResultUpload(data: $data){
        uploadid
        errors {
            result
            testResultId
          }
    }
}
`
const Queries = {}
const Mutations = { TEST_RESULT_UPLOAD }

export { Queries, Mutations }