import React, { useState } from 'react'
import { Row, Col } from 'reactstrap'
import ProjectList from './ProjectList/ProjectListHOC'
import ProjectDetails from './ProjectDetails/ProjectDetails'
import { translate } from 'react-i18next'
// import { t } from 'i18next/dist/commonjs'
import SalesContact from '../SalesContact/SalesContact'

function ProjectView({ t }) {

  const [page, setPage] = useState(1)
  const [projectCount, setProjectCount] = useState(0)
  const [pageSize, setPageSize] = useState(6)

  const handleSetPageSize = pageSize => {
    setPageSize(pageSize)
  }

  const handleProjectCount = count => {
    setProjectCount(count)
  }

  const handleSetPage = page => {
    setPage(page)
  }


  return (
    <React.Fragment>
      <h1 className="pl2 mv4 fw4">{t('project.pageTitle')}</h1>
      <Row>
        <Col lg="4">
          <ProjectList
            handleSetPage={handleSetPage}
            handleProjectCount={handleProjectCount}
            handleSetPageSize={handleSetPageSize}
            page={page}
            pageSize={pageSize}
            projectCount={projectCount}
          />
        </Col>
        <Col lg="8">
          <ProjectDetails />
          <SalesContact />
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default translate('common', { wait: true })(ProjectView)
