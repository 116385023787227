import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {translate} from 'react-i18next'
import {withRouter} from 'react-router-dom'
import {Button} from 'reactstrap'

const propTypes = {
  children: PropTypes.node,
}

const defaultProps = {}

class DefaultFooter extends Component {
  render() {

    // eslint-disable-next-line
    const {t, history, children, ...attributes } = this.props;

    return (
      <React.Fragment>
        <div className="max-width">
          <span>{t('layout.footer.portal')} &copy; 2018. </span>
          <span className="ml-auto"></span>
          <div className="inline footer-links fr">
            <span>
              <Button className="pa0" color="link" onClick={() => history.push('/gdpr')}>{t('layout.footer.gdpr')}</Button>
            </span>
            <span className="ml1 mr1">|</span>
            <span>
              <Button className="pa0" color="link" onClick={() => history.push('/imprint')}>{t('layout.footer.imprint')}</Button>
            </span>
            <span className="ml3 mr1 omg-link">
              <Button className="pa0" color="link" onClick={() => window.open('https://oms-pruefservice.de/', '_blank')}>
                {t('layout.footer.company')}
              </Button>
            </span>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

DefaultFooter.propTypes = propTypes
DefaultFooter.defaultProps = defaultProps

export default translate()(withRouter(DefaultFooter))
