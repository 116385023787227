import React, { Component } from 'react'
import { Container, Row, Col, Media } from 'reactstrap'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'


import logo from '../../../assets/img/brand/oms-logo.png'
import close from '../../../assets/img/close/close.svg'

import Search from '../../../components/Search'
import SearchContainer from '../../../components/SearchContainer'

class SearchPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isMobile: false
        }
    }
    updatePredicate = () => {
        this.setState({
            isMobile: window.innerWidth < 768
        })
    }
    componentDidMount() {
        this.updatePredicate()
        window.addEventListener("resize", this.updatePredicate)
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.updatePredicate)
    }

    handleClose() {
        this.props.history.goBack()
    }

    render() {
        const { flags } = this.props
        return (
            flags.searchAssets ?
                <div className="app bg-white">
                    <Container className="app">
                        <Row className="pt4">
                            <Col></Col>
                            <Col>
                                <div className="text-center">
                                    <Media object src={logo} style={{ height: 30 }} />
                                </div>
                            </Col>
                            <Col className="pt1">
                                <div className="pull-right">
                                    <Media className="text-center" onClick={() => this.handleClose()} object src={close} style={{ height: 24 }} />
                                </div>
                            </Col>
                        </Row>
                        <SearchContainer>
                            <Search width={this.state.isMobile ? 320 : 450} isMobile={this.state.isMobile} />
                        </SearchContainer>
                    </Container>
                </div>
                : null
        )
    }
}

export default withLDConsumer()(SearchPage)