import React, { Component } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import routes from './routes'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'

import { Dashboard, GDPR, FAQ, Imprint } from '../../../../components'
import DefaultLayout from '../DefaultLayout'
import CsmDashboard from '../../CsmDashboard/CsmDashboard'
import UserDashboard from '../../../../components/UserDashboard'
import AssetDetailView from '../../../../components/AssetDetail/AssetDetailView'
import ProvisionInspection from '../../../../components/ProvisionInspection/ProvisionInspection'
import RiskAssessment from '../../../../components/RiskAssessment/RiskAssessment'
import Asset from '../../../../components/Asset/AssetView'

class ComponentRouter extends Component {

  getComponent(component, props, name, id, role, flags) {

    component = component.replace('routes:', '')

    switch (component) {
      case 'home':
        return <DefaultLayout />
      case 'provision':
        return <ProvisionInspection {...props} username={name} id={id} role={role} flags={flags} />
      case 'riskgroup':
        return <RiskAssessment {...props} username={name} id={id} role={role} flag={flags} />
      case 'userdashboard':
        return <UserDashboard />
      case 'csm':
        return <CsmDashboard />
      case 'faq':
        return <FAQ />
      case 'imprint':
        return <Imprint />
      case 'gdpr':
        return <GDPR />
      case 'projects':
        return <Dashboard {...props} />
      case 'assetDetail':
        return <AssetDetailView />
      default:
        return <Asset {...props} />

    }
  }
  render() {
    const { flags, name, id, role } = this.props
    return (
      <Switch>
        {
          routes.map((route, idx) => {
            return route.component ? (flags.csmMainComponent === route.path) ? null :
              (<Route key={idx} path={route.path} exact={route.exact} name={route.name}
                render={(props) => (
                  this.getComponent(route.name, props, name, id, role, flags)
                )}
              />) :
              (null)
          })
        }
        <Redirect from="/" to="/projects" />
      </Switch>
    )
  }
}

export default withLDConsumer()(ComponentRouter)


