

import React, { Component } from 'react'
import Project from './Project'
import List from '../../../base/ListHoc'
import { Queries, Mutations } from '../ProjectGQL'
import { graphql, compose } from 'react-apollo'
const onClickHandler = graphql(Mutations.PERSIST_SELECTED_PROJECT_STATE_MUTATION, {
  name: 'onClick',
})

class ProjectList extends Component {

  render() {
    const { handleProjectCount,handleSetPageSize } = this.props

    const hasQueryStarted = this.props.projectQuery || null
    if (!hasQueryStarted) {
      return (null)
    } else {
      const data = !this.props.projectQuery.userProjects ?
        {
          projects: [], pager: {
            totalCount: 0
          }
        } :
        this.props.projectQuery.userProjects

      this.props.totalCountChanged({variables: {totalCount: data.pager.totalCount}})

      handleProjectCount(data.pager.totalCount)
      handleSetPageSize(data.pager.pageSize)

      const ProjectList = compose(onClickHandler)(List(
        data.projects,
        Project,
        this.props.projectQuery.loading
      ))
      return <ProjectList />
    }
  }
}
export default (compose(
  graphql(Queries.GET_PROJECT_PARAMS, {
    name: 'searchParams',
  }),
  graphql(Queries.PROJECTS_QUERY, {
    name: 'projectQuery',
    skip: ({ searchParams }) => {
      return !searchParams || !searchParams.Project
    },
    options: ({ searchParams, page }) => (
      {
        variables: {
          params: {
            searchString: searchParams.Project.searchString,
            pager: { page, pageSize: searchParams.Project.pager.pageSize },
          },
        },
      }),
  }),
  graphql(Mutations.PERSIST_PROJECT_LIST_COUNT_STATE_MUTATION, {
    name: 'totalCountChanged',
  }),
)(ProjectList))
