import React, {Component} from 'react'

class SearchContainer extends Component {
    render() {
        return (
            <div className="searchContainer">
                {this.props.children}
            </div>
        )
    }

}
export default SearchContainer

/*            <div style = {{display:'inline-flex',
                    flexGrow:'100',
                    alignItems:'center',
                    justifyContent:'center',
                    height:'45'}}
            >*/