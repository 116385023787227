import React from 'react'
import {Query} from 'react-apollo'
import {Queries} from './SalesContactGQL'
import {translate} from 'react-i18next'
import Spinner from '../../base/Spinner'
import Errors from '../../errors'
import Consts from '../../constants'

const BranchCard = ({t, projectId, onRender}) => (
  <Query query={Queries.BRANCH_QUERY} variables={{projectId: projectId}}>
    {({data, error, loading}) => {

      if (error) {
        throw Object.assign({}, {...Errors.NotFoundError}, {type: Consts.ENTITIES.Branch})
      }

      if (loading) {
        return (
          <div className="branch-card mt5">
            <Spinner />
          </div>
        )
      } else if (!data || !data.projectBranch) {
        return (null)
      }

      return (
        <React.Fragment>
          <div className="f4 ma0"><b>{t('contactInfo.branch.headerQuestion')}</b></div>
          <div className="f5 pt2">{t('contactInfo.branch.invite', { name: data.projectBranch.name})}:</div>        
          <div className = "container ml0">
            <div className="row">
            {
              data.projectBranch.email &&
              <div className="col-sm pl-0">
                <span className = "icon-new-envelope-closed envelope-closed-3"/>
                <a href={'mailto:' + data.projectBranch.email}>{data.projectBranch.email}</a>
              </div>
            }
            {
              data.projectBranch.phoneNumber && data.projectBranch.phoneNumber.length !== 0 &&
              <div className="phone col-sm">
                <span className = "icon-new-phone envelope-closed-3"/>
                {
                  data.projectBranch.phoneNumber.map((phone, idx) => {
                    const commaNeeded = idx !== data.projectBranch.phoneNumber.length - 1
                    return <span key={'phoneIdx' + idx}>
                      <a href={'tel:' + phone.number}>{phone.number}</a>
                      <span className={commaNeeded ? '' : 'hidden'}>, </span>
                    </span>
                  })
                }
              </div>
            }
            </div>
          </div>
        </React.Fragment>
      )
    }}
  </Query>
)

export default translate()(BranchCard)
