import gql from 'graphql-tag'

/* QUERIES */

const GET_AUTHENTICATION_STATUS = gql`
query AuthenticationStatus {
  AuthenticationStatus @client {
    expired
  }
}
`

const Queries = {
  GET_AUTHENTICATION_STATUS,
}

const Mutations = {
}

export {Queries, Mutations}

