import React, { Component } from 'react'
import { Card, CardBody, CardGroup, Col, Container, Row } from 'reactstrap'
import { withRouter } from 'react-router-dom'
import { translate } from 'react-i18next'

import logo from '../../assets/img/brand/oms-logo-white.png'

class StaffMemberActivated extends Component {

    render() {
        const { t } = this.props
        return (

            <div className="app flex-row align-items-center login-cover">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={6}>
                            <CardGroup>
                                <Card className="p-4" style={{ backgroundColor: 'transparent', border: 'none' }}>
                                    <div className="text-center p-4"><img src={logo} width="200px" height="auto" alt="OMS Prüfservice GmbH - Logo" /></div>
                                    <CardBody>
                                        <div className="transparent-bg">
                                            <React.Fragment>
                                                <div className="h2 white tc mb4">{t('login.title')}</div>
                                                <p className="white tc f5">{t('staffActivation.message')}</p>
                                            </React.Fragment>
                                        </div>
                                    </CardBody>
                                </Card>
                            </CardGroup>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default translate()(withRouter(StaffMemberActivated))
