import React, {Component} from 'react'
import {translate} from 'react-i18next'
import {compose, graphql} from 'react-apollo'
import {Queries} from './SalesContactGQL'
import {Card, CardBody, CardHeader, CardFooter} from 'reactstrap'
import BranchCard from './BranchCard'
import SalesPersonCard from './SalesPersonCard'
import Errors from '../../errors'
import Consts from '../../constants'

class SalesContact extends Component {
  constructor(props) {
    super(props)
    this.state = {
      salesPersonRendered: true,
      branchRendered: true,
      selectedProject: null,
    }
  }

  static getDerivedStateFromProps(props, currState) {

    if (props.selectedProjectClientQuery && currState.selectedProject !== props.selectedProjectClientQuery.Project.selectedProject) {
      return {
        selectedProject: props.selectedProjectClientQuery.Project.selectedProject,
        salesPersonRendered: true,
        branchRendered: true,
      }
    }
    return null
  }

  componentDidCatch(error) {
    if (error.name === Errors.NotFoundError.name) {
      switch (error.type) {
        case Consts.ENTITIES.SalesPerson:
          this.setState({salesPersonRendered: false})
          break

        case Consts.ENTITIES.Branch:
          this.setState({branchRendered: false})
          break

        default:
          break
      }
    } else {
      throw error
    }
  }

  render() {

    const {t, id} = this.props
    const {salesPersonRendered, branchRendered} = this.state
    let selectedProject

    if (id) {
      selectedProject = id
    } else {

      if (!this.props.selectedProjectClientQuery ||
        this.props.selectedProjectClientQuery.loading) {
        return (null)
      }

      selectedProject = this.props.selectedProjectClientQuery.Project ?
        this.props.selectedProjectClientQuery.Project.selectedProject :
        null
    }

    return (
      selectedProject && (salesPersonRendered || branchRendered) &&
        <Card>
          <CardHeader>
            <h4>{t('contactInfo.title')}</h4>
          </CardHeader>
          <CardBody>
            <div className="sales-info">
              {
                salesPersonRendered &&
                <SalesPersonCard projectId={selectedProject} salesPersonRendered={salesPersonRendered}/>
              }

            </div>
          </CardBody>
          <CardFooter className="footer-divider">
          {
            branchRendered &&
            <BranchCard projectId={selectedProject} />
          }
          </CardFooter>
        </Card>
    )
  }
}


export default compose(
  graphql(Queries.GET_SELECTED_PROJECT, {
    name: 'selectedProjectClientQuery',
    skip: (props) => {
      return props.id
    },
  }))(translate()(SalesContact))
