import React from 'react'
import { Query } from 'react-apollo'
import { Label } from 'reactstrap'
import { Queries } from '../../RiskAssessment/RiskAssessmentGQL'
import Spinner from '../../../base/Spinner'

const TestInterval = ({ questions, setInterval }) => {


    const questionNotAnswered = questions.filter(q => q.selectedAnswer === 0)

    if (questionNotAnswered.length !== 0) return null

    const q = questions.map(item => ({ revision: item.question.revision, selectedAnswer: item.selectedAnswer }))

    return (<Query query={Queries.CALCULATE_INTERVAL} options={{ fetchPolicy: 'network-only' }} variables={{ questions: q }}>
        {
            ({ data, error, loading }) => {

                if (loading) return <Spinner/>

                let interval = data && data.calculateInterval
                setInterval(interval)


                if (error) {
                    console.log('err:', error)
                }
                const year = Math.floor(interval / 12)
                const month = interval % 12
                const yearText = year ? <span><span className="b f4">{year}</span><span>&nbsp;&nbsp;&nbsp;{year > 1 ? 'Years' : 'Year'}&nbsp;&nbsp;&nbsp;</span></span> : ''
                const monthText = month > 0 ? <span><span className="b f4">{month}</span><span>&nbsp;&nbsp;&nbsp;{month > 0 ? 'Months' : 'Month'}</span></span> : ''

                return (
                    <Label>
                        {
                            yearText
                        }
                        {
                            monthText
                        }
                    </Label>
                )

            }
        }

    </Query >)

}

export default TestInterval