import React from 'react'

import Pagination from '../../../base/NewPagination'


function ProjectPagination({ projectCount, page, pageSize, handleSetPage }) {

  const pageClickHandler = (page) => {
    handleSetPage(page)
  }

  return (
    <Pagination onChangePage={pageClickHandler} totalCount={projectCount} page={page} pageSize={pageSize} />
  )
}

export default ProjectPagination

