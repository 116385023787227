import React, { Component } from 'react'
import { Button, Label } from 'reactstrap'
import { translate } from 'react-i18next'


function Tab({ currentYear, val, size, onYearClick, index, total, t }) {
    const year = val
    let link = year === currentYear ?
        <div className="flex" style={{position:"relative"}}>
            <div className="flex flex-column items-center">
                <div>
                    <Button color="info" value={year} onClick={onYearClick} >{year}</Button>
                </div>
                <div style={{position:"absolute", top: 50,minWidth:"200px", textAlign:"center"}}>
                    <Label className="ttu">{total} {t("layout.header.assets")}</Label>
                </div>
            </div>
            {
                index < size - 1 ? <span className="hr mt3"  ></span> : <span className="hrt mt3" ></span>
            }
        </div>
        :
        <div className="flex">
            <div className="flex flex-column items-center">
                <Button color="light" value={year} onClick={onYearClick} >{year}</Button>
            </div>
            {
                index < size - 1 ? <span className="hr mt3" ></span> : <span className="hrt mt3" ></span>
            }
        </div>

    return link
}
class TabByYear extends Component {

    render() {

        const { years, currentYear, onYearClick, total, t } = this.props
        var links = years.map((item, i) => <Tab t={t} total={total} index={i} key={i} currentYear={currentYear} val={item} size={years.length} onYearClick={onYearClick} />
        )
        return (
            <div>
                <div className="flex w-100 justify-content-center mb3">
                    {links}
                </div>
            </div>


        )
    }
}

export default translate('common')(TabByYear)