import React from 'react'
import PropTypes from 'prop-types'
import {Pagination, PaginationLink, PaginationItem } from 'reactstrap'

const propTypes = {
  totalCount: PropTypes.number,
  page: PropTypes.number,
  pageSize: PropTypes.number,
  onChangePage: PropTypes.func.isRequired,
}

const defaultProps = {
  page: 1,
  pageSize: 2,
  totalCount: 0,
}

class NewPagination extends React.Component {
  getPager(totalItems, currentPage, pageSize) {
    // default to first page
    currentPage = currentPage || 1

    // default page size is 10
    pageSize = pageSize || 10

    // calculate total pages
    let totalPages = Math.ceil(totalItems / pageSize)

    let startPage, endPage
    if (totalPages <= 10) {
      // less than 10 total pages so show all
      startPage = 1
      endPage = totalPages
    } else {
      // more than 10 total pages so calculate start and end pages
      if (currentPage <= 6) {
        startPage = 1
        endPage = 10
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9
        endPage = totalPages
      } else {
        startPage = currentPage - 5
        endPage = currentPage + 4
      }
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1)

    // create an array of pages to repeat in the pager control
    let pages = [...Array((endPage + 1) - startPage).keys()].map(i => startPage + i)

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages,
    }
  }

  render() {
    const { totalCount, page, pageSize, onChangePage } = this.props
    const pager = this.getPager(totalCount, page, pageSize)

    if (!pager.pages || pager.pages.length <= 1) {
      // don't display pager if there is only 1 page
      return (null)
    }

    return (
      <div className="pagination_block mt3 ph2">
        <Pagination className="custom-pagination">
              <PaginationItem>
                {
                  pager.currentPage === 1 ? (
                    <PaginationLink disabled tag="button">{"Prev"}</PaginationLink>
                  ) : (
                      <PaginationLink previous tag="button" onClick={() => onChangePage(pager.currentPage - 1)}>{"Prev"}</PaginationLink>
                    )
                }
              </PaginationItem>
              {
                pager.pages.map((page, index) => {
                  if (pager.currentPage === page) {
                    return (
                      <PaginationItem key={index} active>
                        <PaginationLink>{page}</PaginationLink>
                      </PaginationItem>

                    )
                  } else {
                    return (
                      <PaginationItem key={index}>
                        <PaginationLink onClick={() => onChangePage(page)}>{page}</PaginationLink>
                      </PaginationItem>
                    )
                  }
                })
              }
              <PaginationItem>
                {
                  pager.currentPage === pager.totalPages ? (
                    <PaginationLink disabled tag="button">{"Next"}</PaginationLink>
                  ) : (
                      <PaginationLink tag="button" onClick={() => onChangePage(pager.currentPage + 1)}>{"Next"}</PaginationLink>
                    )
                }
              </PaginationItem>
        </Pagination>

      </div>

    )
  }
}

NewPagination.propTypes = propTypes
NewPagination.defaultProps = defaultProps

export default NewPagination