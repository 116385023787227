import React, { Component } from 'react'
import { Bar } from 'react-chartjs-2'
import { Card, CardHeader, CardBody, Input } from 'reactstrap'
import { translate } from 'react-i18next'
import { graphql, compose } from 'react-apollo'

import Spinner from '../../base/Spinner'
import { Queries } from '../Asset/AssetGQL'

import TabByYear from '../TabByYear/TabByYear'

const bar = {
    labels: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
    datasets: [
        {
            label: '',
            backgroundColor: '#B2E3F4',
            borderColor: 'rgba(255,0,0,0)',
            borderWidth: 0,
            hoverBackgroundColor: '#00A3E0',
            hoverBorderColor: 'rgba(255,99,132,1)',
            data: [],
        },
    ],
}
const options = {
    legend: {
        display: false
    },
    tooltips: {
        enabled: true
    },
    maintainAspectRatio: false,
}


class DueDate extends Component {

    constructor(props) {
        super(props)
        this.state = {
            location: props.t("allLocations"),
            year: null,
            isMobile: false,
        }
    }

    updatePredicate = () => {
        this.setState({
            isMobile: window.innerWidth < 768
        })
    }
    componentDidMount() {
        this.updatePredicate()
        window.addEventListener("resize", this.updatePredicate)
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.updatePredicate)
    }

    handleLocationChange(e) {
        this.setState({ location: e.target.value })
    }

    handleYearClick(e,t) {
        this.setState({ year: e.target.value, location: t("allLocations") })
    }
    getOptions(years, totalByYearAndMonth, t) {
        return years.map((year, i) => <option key={i} value={year}>
            {year} - {totalByYearAndMonth[year].reduce((prev, current) => prev += current)}&nbsp;{t("assets")}
        </option>)
    }
    getSites(dueDates) {
        const sites = []
        if(!dueDates.find(y => y.year === this.state.year) || !dueDates.find(y => y.year === this.state.year).dueDateMonths)return sites
        dueDates.find(y => y.year === this.state.year).dueDateMonths.map(dds => dds.dueDates.map(dueDate => sites.indexOf(dueDate.site) === -1 ? sites.push(dueDate.site) : null))

        return sites
    }

    getTotalByYearAndMonth(years, dueDates, t) {
        const totalByYearAndMonth = {}
        for (let index = 0; index < years.length; index++) {
            const year = years[index];
            const yearDates = dueDates.find(y => y.year === year)
            totalByYearAndMonth[year] = []

            for (let index = 0; index < yearDates.dueDateMonths.length; index++) {
                const currentDueDate = yearDates.dueDateMonths[index].dueDates
                let total = 0
                for (let j = 0; j < currentDueDate.length; j++) {
                    if (this.state.location === t("allLocations") || this.state.location === currentDueDate[j].site) {
                        total += currentDueDate[j].count;
                    }
                }
                totalByYearAndMonth[year].push(total)
            }
        }
        return totalByYearAndMonth
    }

    render() {
        const { t, dueDatesQuery } = this.props
        const { isMobile } = this.state
        const hasQueryStarted = dueDatesQuery ||  null
        const isQueryLoading = hasQueryStarted && dueDatesQuery.loading

        if (isQueryLoading) {
            return (
                <React.Fragment>
                    <Spinner />
                </React.Fragment>
            )
        } else {

            const { dueDates } = dueDatesQuery

            const years = dueDates.map(year => year.year);

            const totalByYearAndMonth = this.getTotalByYearAndMonth(years, dueDates, t)

            const currentYear = this.state.year != null ? this.state.year : years.length > 0 ? years[0] : ""
           
          
            bar.datasets[0].data = totalByYearAndMonth[currentYear]

            const sites = this.getSites(dueDates)

            const sitesLink = sites.map((site, i) => <option key={i} value={site}>{site}</option>)

            const total = totalByYearAndMonth[currentYear] ? totalByYearAndMonth[currentYear].reduce((prev, current) => prev += current): 0

            const yearOptions = isMobile ? this.getOptions(years, totalByYearAndMonth, t) : null

            return (

                <Card className="safety-status-card1">
                    <CardHeader>
                        <span className="daue-dates">{t('dueDates')}</span>
                    </CardHeader>
                    <CardBody>
                        <div className="col-lg-3 col-m-4 col-sm-4 mb2">
                            <p className="ttu">{t("filterBy")}</p>

                            <Input type="select" className="mb2 f5" style={{ marginBottom: 30 }} value={this.state.location} onChange={e => this.handleLocationChange(e)}>
                                <option key={sitesLink.length} value={t("allLocations")}>{t("allLocations")}</option>
                                {
                                    sitesLink
                                }
                            </Input>
                        </div>
                        <div style={{ marginBottom: 60 }}>
                            {
                                isMobile ?
                                    <div className="col-lg-3 col-m-4 col-sm-4 mb2">
                                        <Input type="select" className="mb2 ttu f5" style={{ marginBottom: 30, textOverflow: 'ellipsis' }} value={this.state.year} onChange={e => this.handleYearClick(e,t)} >
                                            {
                                                yearOptions
                                            }
                                        </Input>
                                    </div>
                                    : 
                                    <TabByYear total={total} currentYear={currentYear} years={years} onYearClick={(e) => this.handleYearClick(e,t)} />
                            }
                        </div>
                        <div className="chart-wrapper mt3" style={{ height: 400 }}>
                            <Bar data={bar} options={options} />
                        </div>
                    </CardBody>
                </Card>
            )
        }
    }
}

export default translate("dueDates")(compose(
    graphql(Queries.DUEDATES_QUERY, {
        name: 'dueDatesQuery'
    })
)(DueDate))