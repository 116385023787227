import React, {Component} from 'react'
import Alert from './Alert'
import db from '../../helpers/firebaseHelper'

const style = {
  width: '60%',
  position: 'absolute',
  top: '20px',
  left: '20%',
  zIndex: 10000
}

const styleInApp = {
  position: 'relative',
  // top: '-25px',
  width: '100%',
}

class Notification extends Component {
  constructor(props) {
    super(props)

    this.state = {
      messages: [],
    }
  }

  handleClose = id => {
    const currentAlert = this.state.messages.find(alert => alert.id === id)
    if (currentAlert) {
      currentAlert.active = !currentAlert.active
      const newAlerts = [...this.state.messages.filter(alert => alert.id !== id), currentAlert]
      this.setState({messages: newAlerts})
    }
  }

  componentWillUnmount() {
    this.isCancelled = true
    if (this.unsubscribe) {this.unsubscribe()}
  }

  componentDidMount() {
    this.loadMessages()
  }

  unsubscribe = null

  loadMessages = async() => {
    let messages = []
    try {
      const domain = window.location.host

      this.unsubscribe = await db.collection('notification').onSnapshot(snapshot => {
        messages = snapshot.docs.map(doc => {return {id: doc.id, ...doc.data()}})
          .filter(m => m.domain && m.domain.includes(domain))

        !this.isCancelled && this.setState({messages, loading: false})
      })


    } catch (err) {
      console.log('err:', err)
    }

  }

  render() {
    const alerts = this.state.messages.map((alert) => {
      return alert.active ? (
        <Alert name={alert.name} description={alert.description} key={alert.id} id={alert.id} handleClose={this.handleClose} />
      )
        : null
    })
    return (
      <div style={this.props.inApp ? styleInApp : style}>
        {alerts}
      </div>
    )
  }
}


export default Notification
