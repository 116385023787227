import React from 'react'
import {Redirect} from 'react-router-dom'
import {Query} from 'react-apollo'
import {Queries} from './ContactUserActivationGQL'
import ContactUserActivationForm from './ContactUserActivationForm'
import Spinner from '../../base/Spinner'
import Consts from '../../constants'

const ContactUserActivation = ({link}) => (

  <Query query={Queries.CHECK_USER_ACTIVATION_QUERY} variables={{link: link}}>
    {({data, loading, client}) => {
      if (loading) {
        return <Spinner />
      }
      if (!data || !data.checkUserActivation) {
        const location = {
          pathname: '/resetpassword',
          state: {invalidActivationLink: true},
        }
        return (
          <Redirect to={location}></Redirect>
        )
      } else if (data.checkUserActivation.activationStatus !== Consts.ACTIVATION_LINK_STATE.VALID) {
        const location = {
          pathname: data.checkUserActivation.activationStatus === Consts.ACTIVATION_LINK_STATE.EXPIRED ? '/resetpassword' : '/login',
          state: {invalidActivationLink: true},
        }
        return (
          <Redirect to={location}></Redirect>
        )
      }

      const activationData = {
        activationLink: link,
        username: data.checkUserActivation.username,
        passwordRules: data.checkUserActivation.passwordRules,
      }

      return (
        <ContactUserActivationForm {...activationData}></ContactUserActivationForm>
      )
    }}
  </Query>
)

export default ContactUserActivation
