import React, {Component} from 'react'
import {Query} from 'react-apollo'
import {Queries} from '../ProjectGQL'
import AccordionList from '../../../base/AccordionListHoc'
import ProjectResultGroup from './ProjectResultGroup'
import Spinner from '../../../base/Spinner'

class ProjectResultGroups extends Component {
  render() {
    const idArray = this.props.resultGroups.map(item => {return item.id})

    if (idArray && idArray.length > 0) {
      return (
        <Query query={Queries.PROJECT_RESULT_GROUPS} variables={{resultGroups: idArray}}>
          {({data, loading, client}) => {
            if (loading || !data) {
              return <Spinner />
            }

            if (data && data.resultGroups.length === 0) {
              return (null)
            }

            const AccList = AccordionList(data.resultGroups, ProjectResultGroup, 'caption')
            return <AccList />
          }}
        </Query>
      )
    } else {return (null)}
  }
}

export default ProjectResultGroups
