import React, {Component} from 'react'
import {Card, CardBody, Col} from 'reactstrap'


export default class AssetCard extends Component {
  render() {
    const {location, count, failed} = this.props.asset
  
    return (
      <Col xs="12" sm="4" md="3">
        <Card className="card-accent-info">
          <CardBody className="text-center">
            <p className="b">{location}</p>
            <div className="f1">
              <span className="f1 m1">{count}</span>
              &nbsp;<span className="pb1 gray">|</span>&nbsp;
              <span className={ `f1 m1 ${failed > 0 ? 'red' : 'green'}`}>{failed}</span>
            </div>
          </CardBody>
        </Card>
      </Col>
    )
  }
}

