import React, { Fragment, Component } from "react";

import CabinetCard from "./CabinetCard.js";
import "./CabinetOverview.css";
import { Button } from "hyperio.react.library";
import DetailCard from "../DetailCabinetView/DetailCard.js";

import { Query } from "react-apollo";
import { Queries } from "../Queries/CsmGQL";
import { mediaQuerySizes } from "../../Helpers/CsmConfig";

class CabinetOverview extends Component {
  cabinetsCount = 0;
  constructor(props) {
    super(props);
    this.state = {
      VisibleCardsCount: 6,
      ShowGrid: false,
      SelectedIndex: -1,
      ShowAfterIndex: -1,
      SelectedCabinet: ""
    };

    this.loadMore = this.loadMore.bind(this);
    this.showGridFunc = this.showGridFunc.bind(this);
  }

  loadMore() {
    this.setState(prev => {
      return { VisibleCardsCount: prev.VisibleCardsCount + 3 };
    });
  }

  showGridFunc(cardIndex, cardID, recordsNo) {
    this.setState(prev => {
      var colsCount;
      if (window.innerWidth >= mediaQuerySizes.mediumScreenSize) {
        colsCount = 3;
      } else if (window.innerWidth >= mediaQuerySizes.smallScreenSize) {
        colsCount = 2;
      } else {
        colsCount = 1;
      }

      var afterIndex =
        parseInt(cardIndex / colsCount, 10) * colsCount + colsCount - 1;

      afterIndex = afterIndex > recordsNo - 1 ? recordsNo - 1 : afterIndex;

      return {
        ShowGrid: prev.SelectedIndex === cardIndex ? false : true,
        SelectedIndex: prev.SelectedIndex !== cardIndex ? cardIndex : -1,
        ShowAfterIndex: prev.SelectedIndex !== cardIndex ? afterIndex : -1,
        SelectedCabinet: cardID
      };
    });
  }

  render() {
    return (
      <Query query={Queries.CABINETS_QUERY}>
        {({ data, loading }) => {
          if (
            loading ||
            !data ||
            !data.cabinets ||
            data.cabinets.length === 0
          ) {
            return null;
          }
          const { cabinets } = data;
          this.cabinetsCount = cabinets.length;

          return (
            <div className="cabinetoverview">
              <div className="row">
                <Fragment>
                  {cabinets
                    .slice(0, this.state.VisibleCardsCount)
                    .map((data, index, recordsNo) => (
                      <Fragment key={index}>
                        <CabinetCard
                          onCabinetCardClick={this.showGridFunc}
                          data={data}
                          recordsNo={cabinets.length}
                          index={index}
                          active={
                            !this.state.ShowGrid ||
                            (this.state.ShowGrid &&
                              this.state.SelectedIndex === index)
                          }
                        />
                        {this.state.ShowGrid &&
                        index === this.state.ShowAfterIndex ? (
                          <div className="col-sm-12 detailCard">
                            <button
                              onClick={() => {
                                this.showGridFunc(
                                  this.state.SelectedIndex,
                                  this.state.SelectedCabinet
                                );
                              }}
                              type="button"
                              className="close"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>

                            <DetailCard
                              selectedCabinet={this.state.SelectedCabinet}
                            />
                          </div>
                        ) : null}
                      </Fragment>
                    ))}
                </Fragment>
              </div>
              <div className="loadMore">
                {this.cabinetsCount > this.state.VisibleCardsCount && (
                  <Button type="loadmore" onClick={this.loadMore}>
                    LOAD MORE
                  </Button>
                )}
              </div>
            </div>
          );
        }}
      </Query>
    );
  }
}

export default CabinetOverview;
