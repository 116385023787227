import React, { Component } from 'react'
import AssetList from './AssetList/AssetList'
import { translate } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import FailedAssetsGrid from './FailedAssets/FailedAssetsGrid/FailedAssetsGrid'

class AssetView extends Component {
  render() {
    const { t } = this.props

    return (
      <div>
        <div>
          <div className="bg-white dark-gray pv2 ph3 mb4 mt4">
            <h5 className="pt3">
              {t('assetView.assetsPerLocation.title')}
            </h5>
            <AssetList />
            <br></br>
            <FailedAssetsGrid />

          </div>
        </div>
      </div>

    )
  }
}

//export default AssetView
export default translate('assets', { wait: true })(withRouter(AssetView))
