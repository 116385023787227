import React, {Component} from 'react'
import {Media, CardHeader, Card, CardBody, Col, Row} from 'reactstrap'
import {translate} from 'react-i18next'

import logo from '../../assets/img/brand/oms.svg'

class Imprint extends Component {
  render() {
    const {t} = this.props
    return (
      <div className="pt4">
        <Row>
          <Col>
            <Card>
              <CardHeader>
                <i className="fa fa-info"></i><strong>{t('mainheader')}</strong>
              </CardHeader>
              <CardBody className="tj pt4 pb4 pr7 pl7">
                <Media>
                  <Media left href="#">
                    <Media object src={logo} className="pull-left" alt="oms log" style={{height: '100px'}} />
                  </Media>
                  <Media body className="text-right">
                    <Media heading>
                      <i>{t('common.portal')}</i>
                    </Media>
                    <h1><b>{t('common.protection')}</b></h1>
                  </Media>
                </Media>
                <hr className="gdpr-hr"/>
                <p className="pt4 mb0">{t('addr1')}</p>
                <p className="mb0">{t('addr2')}</p>
                <p className="mb0">{t('addr3')}</p>
                <p className="mb0">{t('addr4')}</p>
                <p className="mb0">{t('tel1')}</p>
                <p className="mb0">{t('tel2')}</p>
                <p>{t('email1')} <a href="mailto:oms-pruefservice.de">oms-pruefservice.de</a></p>
                <p className="pt2 mb0"><b>{t('pers1')}</b></p>
                <p className="mb0">{t('pers2')}</p>
                <p className="mb0">{t('pers3')}</p>
                <p>{t('pers4')}</p>
                <h1 className="pt2">{t('p1')}</h1>
                <h3 className="pt1">{t('p1sp1')}</h3>
                <p className="pt1">{t('p1sp1p1')}</p>
                <p>{t('p1sp1p2')}</p>
                <h3 className="pt1">{t('p1sp2')}</h3>
                <p className="pt1">{t('p1sp2p1')}</p>
                <p>{t('p1sp2p2')}</p>
                <h3 className="pt1">{t('p1sp3')}</h3>
                <p className="pt1">{t('p1sp3p1')}</p>
                <p>{t('p1sp3p2')}</p>
                <p className="pt3"><a href="https://oms-pruefservice.de/wp-content/uploads/%C3%96ffentliches-Verfahrensverzeichnis-V2.pdf"
                  target="_target"
                  rel="noopener noreferrer"
                  alt="source-link">{t('link')}</a>
                </p>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

export default translate('imprint', {wait: true})(Imprint)
