import React, { Component } from 'react'
import { ErrorBoundary } from '../../../components'
import ButterToast from 'butter-toast'

import SelfTestedAssetDetailView from '../../../components/SelfTestedPublicAssetDetail/SelfTestedPublicAssetDetailView'

class SelfTestedPublicAssetDetail extends Component {
    render() {
        return (
            <div>
                <ErrorBoundary>
                    <SelfTestedAssetDetailView />
                    <ButterToast trayPosition="top-center" />
                </ErrorBoundary>
            </div>
        )
    }
}

export default SelfTestedPublicAssetDetail