import React from 'react'
import { withRouter } from 'react-router-dom'
import { translate } from 'react-i18next'
import { Row, Col } from 'reactstrap'
import Consts from '../../constants'

const Viewer = ({ t, history, name, avatar, client }) => {

  const _logOut = async (client) => {
    localStorage.removeItem(Consts.AUTH_TOKEN)
    client.resetStore()
    history.push('/login')
  }
  //initialize wootric
  const initializeWootric = () => {
    const showWootric = localStorage.getItem(Consts.SHOW_WOOTRIC)
    if (showWootric === 'true') {
      localStorage.setItem(Consts.SHOW_WOOTRIC, false)
      if (window.wootric) {
        window.wootricSettings.email = localStorage.getItem(Consts.USER_EMAIL)
        window.wootric('run')
      }
    }
  }
  initializeWootric()

  return (

    <div className="sl-nav">
      <ul>
        <li>
          <img src={'data:image/png;base64,' + avatar}
            className="img-avatar"
            alt="avatar" />
          <i className="fa fa-angle-down" aria-hidden="true"></i>
          <ul>
            <li className="disabled11"><div className="b-sm-hidden" style={{ minWidth: 100 }}>{name}</div></li>
            <li onClick={() => _logOut(client)}>
              <Row>
                <Col xs="6"> {t('viewer.logout')}</Col>
                <Col xs="6" className="tr"><span><i className="cui-account-logout">
                  <div id="lock"></div>
                </i></span></Col>
              </Row>

            </li>
          </ul>
        </li>
      </ul>
    </div>
  )
}

export default translate()(withRouter(Viewer))
