import React from 'react'
import { graphql, compose } from 'react-apollo'
import { ListGroup, ListGroupItem } from 'reactstrap'

import { Queries } from '../RiskAssessmentGQL.js'
import Spinner from '../../../base/Spinner'
import Pagination from '../../../base/NewPagination'



function ListContainer({ t, page, setPage, pageSize, totalCount,
    riskGroupQuery,
    setRiskAssessmentCount,
    selectedRiskAssessment,
    setSelectedRiskAssessment
}) {
    const { loading, error, customerRiskAssessment } = riskGroupQuery

    if (loading) return <Spinner />
    if (error) return <div>{error}</div>

    const { riskGroups, pager } = customerRiskAssessment
    pageSize = pager.pageSize
    totalCount = pager.totalCount

    setRiskAssessmentCount(pager.totalCount)

    if (selectedRiskAssessment == null && riskGroups.length > 0) {
        setSelectedRiskAssessment(riskGroups[0].revision)
    }

    return (
        <React.Fragment>
            <RiskAssessmentList
                t={t}
                page={page}
                riskGroups={riskGroups}
                selectedRiskAssessment={selectedRiskAssessment}
                setSelectedRiskAssessment={setSelectedRiskAssessment} />
            <RiskAssessmentPagination page={page} totalCount={totalCount} pageSize={pageSize} pageClickHandler={setPage} />
        </React.Fragment>
    )
}
export default compose(
    graphql(Queries.RISK_ASSESSMENT_QUERY, {
        name: 'riskGroupQuery',
        options: (props) => ({
            variables: {
                params: {
                    searchString: props.searchTerm,
                    pager: {
                        page: props.page,
                        pageSize: 6
                    }
                }
            }
        })

    }),

)(ListContainer)

function RiskAssessmentPagination({ pageClickHandler, totalCount, page, pageSize }) {
    return (
        <div>
            <Pagination onChangePage={pageClickHandler} totalCount={totalCount} page={page} pageSize={pageSize} />
        </div>
    )
}


function RiskAssessmentList({ t, riskGroups, selectedRiskAssessment, setSelectedRiskAssessment }) {
    return (
        <ListGroup>
            {
                riskGroups.map((rg, i) => {
                    return <RiskAssessmentItem
                        t={t}
                        key={i}
                        index={i}
                        riskGroup={rg}
                        selectedRiskAssessment={selectedRiskAssessment}
                        setSelectedRiskAssessment={setSelectedRiskAssessment}
                    />
                })

            }
        </ListGroup>
    )
}

function RiskAssessmentItem({ t, riskGroup, selectedRiskAssessment, setSelectedRiskAssessment }) {

    const { title, revision, testInterval } = riskGroup
    return (
        <ListGroupItem
            className={revision === selectedRiskAssessment ? "no-br ttc active" : "no-br ttc"}
            onClick={() => {
                setSelectedRiskAssessment(revision)
            }
            }
            action
        >
            <h3>{title}</h3>
            <span>{t("interval")}: {testInterval}</span><br />
        </ListGroupItem>
    )
} 