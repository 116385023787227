import gql from 'graphql-tag'

/* QUERIES */

const FAILEDASSETS_QUERY = gql`
 query{
     failedAssets{
        id
        type
        location
        testDate
        failureReason
     }
 }
`
const OVERDUEASSETS_QUERY = gql`
query{
  overdueAssets
}
`

const PROJECTS_QUERY = gql`
 query($params:ProjectParams) {
  userProjects(params: $params) {
    projects {
      id,
      caption,
      crmId,
      branchOfficeName,
      customerName,
      status,
      statistics {
        totalExported,
        totalFailed,
        totalWithComments
      },
      testDates
    },
    pager {
      page,
      pageSize,
      totalCount
    }
  }
}
`
const Queries = {
  OVERDUEASSETS_QUERY,
  FAILEDASSETS_QUERY,
  PROJECTS_QUERY
}

const Mutations = {}

export {Queries, Mutations}
