import React, { Component } from 'react'
import { Label, Row, Col, Input, FormGroup, Button, Card, CardHeader, CardBody } from 'reactstrap'
import AutoSuggestion from './AutoSuggestion'

import Consts from '../../constants'
import AssetFeedback from './AssetFeedback'

class FormStepOne extends Component {

    render() {
        const { t, assetId, assetType, manufacturer, username, project, projects,
            testInterval, handleProjectChange, handleTestIntervalChange, handleNext,
            handleInputChange, handleLocationChange, locations, site, building, floor, room } = this.props

            console.log('projects:', projects)

        return (
            <Card>
                <CardHeader>
                    <Row>
                        <Col xs="8"> <p><span className="badge badge-blue">1</span> {t("detailTitle")}</p></Col>
                        <Col xs="4" className="tr"> <span className="badge badge-gray">2</span></Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col xs="12">
                            <FormGroup>
                                <Label htmlFor="testerName">{t('testerName')}</Label>
                                <Input type="text" id="testerName" disabled
                                    value={username}
                                    required />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12">
                            <FormGroup>
                                <Label htmlFor="project">{t('project')}</Label>
                                <Input type="select" id="project" required
                                    value={project}
                                    onChange={
                                        (e) => handleProjectChange(e)
                                    }
                                >
                                    {
                                        projects.length > 1 ? <option value="">{t("select")}</option> : null

                                    }
                                    {
                                        projects.map((item, i) => <option key={i}
                                            value={item.id}>{item.caption}</option>)
                                    }
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs="12">
                            <FormGroup>
                                <Label htmlFor="assetId">{t('assetId')}</Label>
                                <Input type="text" className="mb2"
                                    value={assetId}
                                    onChange={e => handleInputChange(e)}
                                    id="assetId"
                                    name="assetId" required />
                                <AssetFeedback assetId={assetId} t={t} id="error-message" />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs="12">
                            <FormGroup>
                                <Label htmlFor="assetType">{t('assetType')}</Label>
                                <Input type="text"
                                    value={assetType}
                                    onChange={(e) => handleInputChange(e)}
                                    name="assetType"
                                    id="assetType"
                                    required />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12">
                            <FormGroup>
                                <Label htmlFor="manufacturer">{t('manufacturer')}</Label>
                                <Input type="text"
                                    value={manufacturer}
                                    onChange={(e) => handleInputChange(e)}
                                    name="manufacturer"
                                    id="manufacturer"
                                    required />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12">
                            <FormGroup>
                                <Label htmlFor="site">{t('site')} (optional)</Label>
                                <AutoSuggestion
                                    val={site}
                                    name={Consts.SITE}
                                    options={locations ? locations[`${Consts.SITE}s`] : null}
                                    handleLocationChange={(name, value) => handleLocationChange(name, value)} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12">
                            <FormGroup>
                                <Label htmlFor="building">{t('building')} (optional)</Label>
                                <AutoSuggestion
                                    val={building}
                                    name={Consts.BUILDING}
                                    options={locations ? locations[`${Consts.BUILDING}s`] : null}
                                    handleLocationChange={(name, value) => handleLocationChange(name, value)} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12">
                            <FormGroup>
                                <Label htmlFor="floor">{t('floor')} (optional)</Label>
                                <AutoSuggestion
                                    val={floor}
                                    name={Consts.FLOOR}
                                    options={locations ? locations[`${Consts.FLOOR}s`] : null}
                                    handleLocationChange={(name, value) => handleLocationChange(name, value)} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12">
                            <FormGroup>
                                <Label htmlFor="room">{t('room')}</Label>
                                <AutoSuggestion
                                    val={room}
                                    name={Consts.ROOM}
                                    options={locations ? locations[`${Consts.ROOM}s`] : null}
                                    handleLocationChange={(name, value) => handleLocationChange(name, value)} />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs="12">
                            <FormGroup>
                                <Label htmlFor="testInterval">{t('testInterval')}</Label>
                                <Input type="select" id="testInterval" required
                                    value={testInterval}
                                    onChange={
                                        (e) => handleTestIntervalChange(e)
                                    } >
                                    <option value="">{t("select")}</option>
                                    <option value="6">6 {t("months")}</option>
                                    <option value="12">1 {t("year")}</option>
                                    <option value="24">2 {t("years")}</option>
                                    <option value="36">3 {t("years")}</option>
                                    <option value="48">4 {t("years")}</option>
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row >
                        <Col className="tr mv4">
                            <Button color="primary" onClick={(e) => handleNext(e)}>{t('next')}</Button>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        )

    }
}

export default FormStepOne