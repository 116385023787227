import gql from "graphql-tag";

/* QUERIES */

const CABINETS_QUERY = gql`
  query {
    cabinets {
      id
      cabinetName
      rooms
      currentMeasure
      highestMeasure
      highestMeasureTime
      errorsCount
      status
      statusDesc
      notificationSent
    }
  }
`;

const EVENTS_QUERY = gql`
  query {
    events {
      status
      threshold
      location
      notificationSent
    }
  }
`;

const CABINETSDETAILS_QUERY = gql`
query($term: String!) {
    cabinetsDetails (term: $term) {
      id
      channelName
      status
      statusDesc
      rooms
      modbusRegister
      currentMeasure
      highestMeasure
      highestMeasureTime
      errorsCount
      notificationSent
      thresholdViolation
      threshold
    }
  }
`;


const Queries = {
  CABINETS_QUERY,
  EVENTS_QUERY,
  CABINETSDETAILS_QUERY
};

const Mutations = {};

export { Queries, Mutations };
