import React, {Component} from 'react'

export default class Alert extends Component {
  render() {
    const {id, handleClose, name, description} = this.props
    return (
      <div className="alert alert-warning alert-dismissible fade show" role="alert">
        <button type="button" className="close" aria-label="Close" onClick={() => handleClose(id)}><span aria-hidden="true">×</span></button>
        <h4 className="alert-heading">{name}</h4>
        <p>{description}</p>
      </div>
    )
  }
}
