import {Queries as ProjectQueries} from '../components/Project/ProjectGQL'

const resolvers = {
  Mutation: {
    SetSelectedProject: (_, variables, {cache, getCacheKey}) => {
      const {Project} = cache.readQuery({query: ProjectQueries.GET_SELECTED_PROJECT})

      cache.writeQuery({
        query: ProjectQueries.GET_SELECTED_PROJECT,
        data: {
          __typename: 'PROJECT_CACHE',
          Project: Object.assign({}, Project, {selectedProject: variables.id}),
        },
      })

      return null
    },
    SetSearchString: (_, variables, {cache, getCacheKey}) => {
      const {Project} = cache.readQuery({query: ProjectQueries.GET_PROJECT_PARAMS})

      Project.pager = Object.assign({}, Project.pager, {page: 1, totalCount: 0})
      Project.searchString = variables.searchString

      cache.writeQuery({
        query: ProjectQueries.GET_PROJECT_PARAMS,
        data: {
          __typename: 'PROJECT_CACHE',
          Project: Project,
        },
      })

      return null
    },
    SetProjectPage: (_, variables, {cache, getCacheKey}) => {
      const {Project} = cache.readQuery({query: ProjectQueries.GET_PROJECT_PARAMS})

      Project.pager = Object.assign({}, Project.pager, {...variables})

      cache.writeQuery({
        query: ProjectQueries.GET_PROJECT_PARAMS,
        data: {
          __typename: 'PROJECT_CACHE',
          Project: Project,
        },
      })

      return null
    },
    SetProjectCount: (_, variables, {cache, getCacheKey}) => {
      const {Project} = cache.readQuery({query: ProjectQueries.GET_PROJECT_LIST_PAGER_DATA})

      Project.pager = Object.assign({}, Project.pager, {...variables})

      cache.writeQuery({
        query: ProjectQueries.GET_PROJECT_LIST_PAGER_DATA,
        data: {
          __typename: 'PROJECT_CACHE',
          Project: Project,
        },
      })

      return null
    },
  },
}

export default resolvers
