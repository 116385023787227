import React, {Component} from 'react'
import {Config} from '../../../config'

import {ListGroupItem, ListGroup, Row, Col, NavLink} from 'reactstrap'

import FileSaver from 'file-saver'
import _ from 'lodash'
import {translate} from 'react-i18next'

import axios from 'axios'
import Consts from '../../../constants'
import ButterToast, {CinnamonSugar} from 'butter-toast'

class ProjectResultGroupFiles extends Component {
  render() {
    const {t, uploadedFiles, generatedFiles} = this.props

    let generatedAndUploaded = [...generatedFiles, ...uploadedFiles]

    return (
      <div className="animated fadeIn">
        <Row>
          <Col xl={12}>

            <div className={generatedAndUploaded.length ? 'pb3' : 'hidden'}>
              <CategorizedFileList files={generatedAndUploaded} t={t} />
            </div>

          </Col>
        </Row>
      </div>
    )
  }
}

class CategorizedFileList extends Component {
  render() {
    const {t, files} = this.props
    return (
      _.uniq(files.map(item => {return item.category})).map((cat, i) => {
        const catFiles = files.filter(catFile => {return catFile.category === cat})

        if (catFiles.length === 0) {return (null)}

        return (
          <React.Fragment key={cat + i}>
            <div className="mt3 db silver ttu"><b>{t('project.details.downloads.categories.' + cat)}</b></div>
            <Row>
              <Col xl={12}>
                <ListGroup className="list-style">
                  {
                    catFiles.map((item, i) => {
                      return (
                        <ListGroupItem key={i} className="bn pa0 fn-line-height">
                          <File file={item} t={t}/>
                        </ListGroupItem>
                      )
                    })
                  }
                </ListGroup>
              </Col>
            </Row>
          </React.Fragment>
        )
      })
    )
  }
}

class File extends Component {
  constructor(props) {
    super(props)

    this.state = {...this.initialState()}
  }

  initialState = () => ({
    downloading: false,
    downloadedBytes: 0,
  })

  bytesToString = bytes => {
    const sufixes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    if (!bytes) {return '0 B'}

    const i = Math.floor(Math.log(bytes) / Math.log(1024))
    return (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sufixes[i]
  }

  progress = () => {
    if (!this.props.file.fileSize) return '...'

    return Math.floor((this.state.downloadedBytes / this.props.file.fileSize) * 100) + '%'
  }

  downloadFile = async(e) => {
    e.preventDefault()

    this.setState({downloading: true})

    const fileListB64 = Buffer.from(JSON.stringify([this.props.file])).toString('base64')

    axios({
      url: `${Config.gqlHttp}downloadFiles?fileList=${fileListB64}`,
      method: 'GET',
      responseType: 'blob',
      headers: {Authentication: localStorage.getItem(Consts.AUTH_TOKEN)},
      onDownloadProgress: progressEvent => {
        this.setState({downloadedBytes: progressEvent.loaded})
      },
    }).then(response => {
      FileSaver.saveAs(response.data, this.props.file.filename)

      this.setState({...this.initialState()})
    }).catch(e => {
      const toast = CinnamonSugar.crisp({
        theme: 'error',
        icon: 'exclamation',
        title: <div className="tj">{this.props.t('project.details.downloads.error')}</div>,
        toastTimeout: 15000,
        dismissOnClick: true,
      })

      ButterToast.raise(toast)

      this.setState({...this.initialState()})
    })
  }

  render() {
    const {file} = this.props

    const extensionMap = {
      '.xls': 'custom-icon icon-file-excel',
      '.xlsx': 'custom-icon icon-file-excel',
      '.doc': 'fa fa-file-word-o',
      '.docx': 'fa fa-file-word-o',
      '.ppt': 'fa fa-file-powerpoint-o',
      '.pptx': 'fa fa-file-powerpoint-o',
      '.zip': 'custom-icon icon-file-archive',
      '.pdf': 'custom-icon icon-file-pdf',
      '.bmp': 'fa fa-file-image-o',
      '.jpeg': 'fa fa-file-image-o',
      '.svg': 'fa fa-file-image-o',
      '.png': 'fa fa-file-image-o',
      '.jpg': 'fa fa-file-image-o',
      '.xml': 'fa fa-file-text-o',
      '.txt': 'fa fa-file-text-o',
      '.dwl': 'fa fa-download-o',
    }

    const fileName = (file.filename && file.filename.lastIndexOf('.') > 0 ?
      file.filename.substring(0, file.filename.lastIndexOf('.')) :
      file.filename) + ' (' + (file.fileExtension || 'N/A').toUpperCase() + ')'
    const fileIcon = extensionMap[file.fileExtension] || 'fa fa-file-o'
    const downloadDisabled = this.state.downloading ? 'download-disabled' : ''

    return (
      <Row  className="col-li" onClick={this.downloadFile}>
        <Col md={6}  className="pr0">
          <Row className="mb0">
            <NavLink className={'di ' + downloadDisabled} disabled={this.state.downloading} href="#">
              <i className={'mr3 ' + fileIcon}></i> {fileName}
            </NavLink>
          </Row>
        </Col>
        <Col md={3}>
          {
            this.state.downloading &&
            <span className="text-info db pt2 pb2">{this.progress()}</span>
          }
        </Col>
        <Col md={3} className="">
            <div md="3" className="pt2"><i className="custom-icon icon-download"></i> <span className="text-info ml3">{this.bytesToString(file.fileSize)}</span></div>
        </Col>
      </Row>
    )
  }
}

export default translate()(ProjectResultGroupFiles)
