import React, { useState, useEffect, useRef } from 'react'
import { compose, graphql, Mutation } from 'react-apollo'
import {
    Row, Col, Card, Label, Input,
    CardHeader, CardBody, FormGroup,
    Button
} from 'reactstrap'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Spinner from '../../../base/Spinner'
import { Queries, Mutations } from '../RiskAssessmentGQL'
import TestInterval from './TestInterval'
import LoadingButton from '../../../base/LoadingButton'

import Consts from '../../../constants'



function NewRiskAssessmentForm({ t, setIsCreate, questionQuery }) {

    const { loading, error } = questionQuery

    if (error) return error

    if (loading) return <Spinner />

    const { answeredQuestions } = questionQuery.answeredQuestion

    const isFirstRun = useRef(true)
    const [questions, setQuestions] = useState(answeredQuestions)
    const [title, setTitle] = useState('')
    const [generalComment, setGeneralComment] = useState('')
    const [interval, setInterval] = useState(0)
    const [submitting, setSubmiting] = useState(false)

    useEffect(() => {
        if (!isFirstRun.current) {
            toggleCss('title')

        } else {
            isFirstRun.current = false
        }
    }, [title])

    const handleCancel = () => {
        setIsCreate(false)
    }
    const success = () => {
        return toast.success(`${t("successMessage.line1")}\n${t('successMessage.line2')}`, {

            onClose: () => {
                setSubmiting(true)
                window.location.href = '/riskAssessment'
            }
        });
    }

    const errorToast = message => {
        return toast.error(message, {});
    }
    const handleQuestionChange = (e, questionIndex) => {
        const { value } = e.target
        const currentQuestion = questions[questionIndex]

        currentQuestion.selectedAnswer = parseInt(value, 10)
        const newQuestions = [...questions.slice(0, questionIndex), currentQuestion, ...questions.slice(questionIndex + 1, questions.length)]
        setQuestions(newQuestions)

    }

    const toggleCss = (name) => {

        const elm = document.getElementById(name)

        if (title === "") {
            elm.classList.add(Consts.INVALID_CLASS_NAME)
        }
        else {
            elm.classList.remove(Consts.INVALID_CLASS_NAME)
        }
    }

    const validateForm = () => {
        toggleCss("title")
    }

    const getQuestions = (questions) => {
        return questions.map(item => {
            return (
                {
                    revision: item.question.revision,
                    selectedAnswer: item.selectedAnswer,
                }
            )
        })
    }
    const handleSave = async (e, createRiskAssessment) => {
        validateForm()
        const inputQuestions = getQuestions(questions)

        if (interval === 0 || title === '') return

        setSubmiting(true)

        try {

            const { data } = await createRiskAssessment({
                variables: {
                    data: {
                        title: title.trim(),
                        comment: generalComment.trim(),
                        questions: inputQuestions,
                        interval
                    }
                }
            })
            const { errors } = data.createRiskAssessment
            if (errors && errors.length) {
                errorToast(errors[0].message || 'There was an error submitting the test result.')
            } else {
                success()
            }

        } catch (e) {
            console.log('error:', e)
        }

    }
    const containerStyle = {
        zIndex: 1999
    }

    return (
        <Mutation mutation={Mutations.CREATE_RISK_ASSESSMENT}>
            {(createRiskAssessment, { loading, error }) => {

                if (loading) return <Spinner />
                if (error) return <p>{error}</p>

                return (
                    <Row>
                        <ToastContainer position="top-right" autoClose={5000} style={containerStyle} />
                        <Col xs="12">
                            <Card>
                                <CardHeader>
                                    <Row>
                                        <Col xs="8">
                                            <div>{t('riskAssessmentForm')}</div>
                                        </Col>
                                        <Col xs="4" className="tr">
                                            <Button
                                                className="ttu"
                                                color="primary"
                                                onClick={handleCancel}
                                            >
                                                {t('cancel')}</Button>

                                        </Col>
                                    </Row>

                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <Label htmlFor="title">{t('title')}</Label>
                                                <Input
                                                    id="title"
                                                    type="text"
                                                    name="title"
                                                    value={title}
                                                    onChange={e => setTitle(e.target.value)}
                                                    className={submitting ? "disabled-form" : "mb2"}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    {
                                        questions.map(({ question, selectedAnswer }, i) => {

                                            const { title, answers } = question

                                            return <Question
                                                t={t}
                                                index={i}
                                                title={title}
                                                answers={answers}
                                                submitting={submitting}
                                                key={question.revision}
                                                selectedAnswer={selectedAnswer}
                                                handleQuestionChange={handleQuestionChange}
                                            />
                                        })
                                    }
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <Label className="b" htmlFor="generalComment">
                                                    {t('generalComment')}(optional)
                                        </Label>
                                                <Input
                                                    type="text"
                                                    id="generalComment"
                                                    name="generalComment"
                                                    value={generalComment}
                                                    className={submitting ? "disabled-form" : ""}
                                                    onChange={(e) => setGeneralComment(e.target.value)}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Label className="mb2">{t('suggestedInterval')}</Label><br></br>
                                            <TestInterval questions={questions}
                                                setInterval={setInterval}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mt3">
                                        <Col>
                                            <LoadingButton type="submit" color="primary" className="ml2 width-130"
                                                onClick={(e) => handleSave(e, createRiskAssessment)}
                                                loading={submitting ? 'true' : undefined} >{t('save')}
                                            </LoadingButton>

                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                )
            }
            }
        </Mutation>
    )
}
export default compose(
    graphql(
        Queries.QUESTION,
        {
            name: "questionQuery",
            options: {}
        })

)(NewRiskAssessmentForm)

export const Question = ({ index, title, answers, handleQuestionChange, selectedAnswer, submitting }) => {

    return (<Row key={index}>
        <Col>

            <FormGroup>
                <Label>{index + 1}. {title}</Label>
                {
                    answers.map(({ answerText, answerId }, i) => <Answer
                        key={i}
                        id={answerId}
                        questionIndex={index}
                        answerText={answerText}
                        submitting={submitting}
                        selectedAnswer={selectedAnswer}
                        handleQuestionChange={handleQuestionChange} />
                    )
                }
            </FormGroup>
        </Col>
    </Row>)
}


export const Answer = ({ questionIndex, id, answerText, selectedAnswer, handleQuestionChange, submitting }) => {

    return (

        <FormGroup check className="radio" >
            <Input
                value={id}
                type="radio"
                name={questionIndex}
                checked={selectedAnswer === id}
                id={`radio-${questionIndex}-${id}`}
                onChange={(e) => handleQuestionChange(e, questionIndex)}
                className={submitting ? "disabled-form" : "form-check-input"}
            />
            <Label check
                htmlFor={`radio-${questionIndex}-${id}`}
                className={submitting ? "disabled-form" : "form-check-label"}
            >
                {answerText}
            </Label>
        </FormGroup>
    )
}