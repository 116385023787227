import React, { Component } from 'react'
import { withApollo } from 'react-apollo'
import { Queries } from './SearchGQL'
import { translate } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import icon from '../../assets/img/search/vector.svg'

class Search extends Component {
    _isMounted = false
    state = {
        results: [],
    }
    constructor(props) {
        super(props);
        this.inputText = '';
        this.txtBoxRef = React.createRef();
        this.controlRef = React.createRef();
        this.dropdownRef = React.createRef();
        this.fadeRef = React.createRef();
        this.textHintRef = React.createRef();
        this.handleMouseOver = this.handleMouseOver.bind(this);
        this.handletxtBoxHasFocus = this.handletxtBoxHasFocus.bind(this);
        this.handletxtBoxChange = this.handletxtBoxChange.bind(this);
        this.controlClassname = 'search-default';
        this.popupTop = '';
        this.popupLeft = '';
        this.hasResults = false;
        this.dict = {};
        this.dictIndices = {};
        this.currentSelected = {};
    }

    componentDidMount() {
        this._isMounted = true

        // let rect = this.controlRef.current.getBoundingClientRect();
        // this.popupTop = (rect.bottom + 10)
        // this.popupLeft = rect.left;
        // this.dropdownRef.current.style.left = this.popupLeft.toString() + 'px';
        // this.dropdownRef.current.style.top = this.popupTop.toString() + 'px';
        window.addEventListener('resize', this.resize);
        window.addEventListener('mousedown', this.handleMouseDown);
    }

    componentWillUnmount() {
        this._isMounted = false
        window.removeEventListener('resize', this.resize);
        window.removeEventListener('mousedown', this.handleMouseDown);
    }

    componentDidUpdate() {
        // let rect = this.controlRef.current.getBoundingClientRect();
        // this.popupTop = (rect.bottom + 10)
        // this.popupLeft = rect.left;
        // this.dropdownRef.current.style.left = this.popupLeft.toString() + 'px';
        // this.dropdownRef.current.style.top = this.popupTop.toString() + 'px';
    }

    handleMouseOver(item, Over) {

        if (this.inputText.length === 0) {
            if (Over) {
                this.controlClassname = 'search-shadow';
            }
            else {
                this.controlClassname = 'search-default';
            }
        }
        this.forceUpdate();

    }

    handletxtBoxHasFocus(HasFocus) {
        if (this.inputText.length === 0) {
            if (HasFocus) {
                this.textHintRef.current.style.visibility = 'hidden';
            }
            else {
                this.textHintRef.current.style.visibility = 'visible';
            }
        }
    }
    handletxtBoxChange() {
        this.inputText = this.txtBoxRef.current.value;
        if (this.inputText.length > 0) {
            this._executeSearch();
        }
        else {
            this.setState({ results: [] });
        }

    }

    handleMouseDown = (event) => {
        if (!document.getElementById("searchTopComponent").contains(event.srcElement)) {
            this.setState({ results: [] });
        }

    }

    handleKeyDown(keyCode, history) {
        if (keyCode === 27) {
            this.hasResults = false;
            this.setState({ results: [] });
            return;
        }
        if (this.currentSelected !== "" && this.inputText.length > 0 && keyCode === 13) {
            this.setState({ results: [] })
            history.push("/assets/" + this.currentSelected)
            return
        }
        if (this.currentSelected !== "" && this.inputText.length > 0) {
            if (keyCode === 38) { //Up
                if (this.dictIndices[this.currentSelected] > 0) {
                    this.handleItemMouseOver(Object.keys(this.dictIndices)[this.dictIndices[this.currentSelected] - 1], true, null)
                }
                else {
                    this.handleItemMouseOver(Object.keys(this.dictIndices)[Object.keys(this.dictIndices).length - 1], true, null)
                }
                return
            }
            if (keyCode === 40) { //Down
                if (Object.keys(this.dictIndices).length > this.dictIndices[this.currentSelected] + 1) {
                    this.handleItemMouseOver(Object.keys(this.dictIndices)[this.dictIndices[this.currentSelected] + 1], true, null)
                }
                else {
                    this.handleItemMouseOver(Object.keys(this.dictIndices)[0], true, null)
                }

            }

        }
    }

    handleOnClick(id, history) {
        this.setState({ results: [] })
        history.push("/assets/" + id)
    }

    handleItemMouseOver(itemId, Over, eventObject) {
        if (Over) {
            if (this.currentSelected !== itemId) {
                if (this.currentSelected !== '') {
                    this.dict[this.currentSelected].current.attributes["active"].value = "false"
                }
                this.dict[itemId].current.attributes["active"].value = "true"
                this.currentSelected = itemId
            }

        }
        else {
            if (!this.dict[this.currentSelected].current.contains(document.elementFromPoint(eventObject.pageX, eventObject.pageY))) {
                this.dict[this.currentSelected].current.attributes["active"].value = "false"
                this.currentSelected = ""
            }
        }

    }

    render() {
        const { t, history, width } = this.props;
        history.listen((location, action) => {
            if (this._isMounted) {
                this.setState({ results: [] })
                if (this.txtBoxRef !== undefined && this.txtBoxRef !== null && this.txtBoxRef.current !== null && this.txtBoxRef.current !== undefined) {
                    this.txtBoxRef.current.value = ''
                }
            }

        })
        return (
            <div style={{ width: width }} ref={this.controlRef} id="searchTopComponent">
                <div style={{ width: width }} className={this.controlClassname}
                    onMouseOut={() => this.handleMouseOver(this, false)}
                    onMouseOver={() => this.handleMouseOver(this, true)}
                >
                    <div style={{ width: width }} className="input-container">
                        <span style={{ width: width }} ref={this.textHintRef} className="px400 def-font">{t('project.search.placeholder')}</span>
                        <input style={{ width: width }} autoFocus ref={this.txtBoxRef} className="asset-search def-font"
                            onFocus={() => this.handletxtBoxHasFocus(true)}
                            onBlur={() => this.handletxtBoxHasFocus(false)}
                            onChange={() => this.handletxtBoxChange()}
                            onKeyDown={(e) => {
                                if (e.keyCode === 27 || e.keyCode === 13 || e.keyCode === 38 || e.keyCode === 40) {
                                    this.handleKeyDown(e.keyCode, history);
                                }
                            }}
                        ></input>
                    </div>
                    <img src={icon} className="Vector" alt="" />
                </div>
                <div style={{ width: width}} ref={this.dropdownRef} className={(this.state.results.length > 0 && this.inputText.length > 0) ? "dropdown-results-visible" : "dropdown-results"}>
                    <ul className='results'>
                        {
                            this.state.results.map((item, i) => {
                                const isactive = ((i === 0) ? "true" : "false")
                                this.dict[item.id] = React.createRef()
                                this.dictIndices[item.id] = i
                                if (isactive === "true") {
                                    this.currentSelected = item.id
                                }
                                if (item.type === "0") {
                                    return (
                                        <React.Fragment key={item.id}>
                                            <li className="result-line def-font"><span className="result">{item.transl('project.search.notFound')}</span>
                                            </li>
                                        </React.Fragment>
                                    )
                                }
                                return (
                                    <React.Fragment key={item.id}>
                                        <li className="result-line def-font" active={isactive} ref={this.dict[item.id]}
                                            onClick={() => this.handleOnClick(item.metadata[3].value, history)}
                                            onMouseOut={(e) => this.handleItemMouseOver(item.id, false, e)}
                                            onMouseOver={(e) => this.handleItemMouseOver(item.id, true, e)}>
                                            <span className="result">
                                                <span className="result">{item.metadata[0].value}</span>
                                                <span className="result-match">{item.metadata[1].value}</span>
                                                <span className="result">{item.metadata[2].value}</span>
                                            </span>
                                            <span className="def-font font-result-type">{item.metadata[6].value}</span>
                                        </li>
                                    </React.Fragment>
                                )
                            })}
                    </ul>
                </div>
            </div>
        )
    }

    resize = () => this.forceUpdate()

    _executeSearch = async () => {
        const result = await this.props.client.query({
            query: Queries.ASSETS_QUERY,
            variables: { term: this.inputText },
            fetchPolicy: 'network-only'
        })
        const results = result.data.searchResult;
        const {isMobile} = this.props
        this.dict = {};
        this.dictIndices = {};
        this.currentSelected = {};
        if (results.isFailed === false) {
            if (results.results.length > 0 && this.inputText.length > 0) {
                this.hasResults = true;

                if(isMobile && results.results.length > 5){
                    this.setState({results: results.results.slice(0,5)})
                }
                else if (results.results.length > 10) {
                    this.setState({ results: results.results.slice(0, 10) });
                }
                else {
                    this.setState({ results: results.results });
                }
            }
            else {
                var { t } = this.props;
                this.hasResults = false;
                const resultObj = {
                    type: "0",
                    id: "",
                    transl: t
                }
                this.setState({ results: [resultObj] });
            }
        }
        else {
            this.setState({ results: [] });
        }
    }
}
export default translate('common', { wait: true })(withApollo(withRouter(Search)))