import React, { Component } from 'react'
import { Container } from 'reactstrap'
import { ErrorBoundary } from '../../../components'
import ButterToast from 'butter-toast'
import Notification from '../../../components/Notification/Notification'

import {
  AppAside,
  AppFooter,
  AppHeader,
  //AppAside,
  AppSidebar,
} from '@coreui/react'

import DefaultSidebar from '../DefaultLayout/DefaultSidebar'
import DefaultFooter from '../DefaultLayout/DefaultFooter'
import DefaultHeader from '../DefaultLayout/DefaultHeader'
import DefaultBreadCrumb from '../DefaultLayout/DefaultBreadCrumb'
import AssetView from '../../../components/Asset/AssetView'

import { ViewerProvider } from '../../Contexts/Viewer.context'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'

class Asset extends Component {
  render() {
    const { flags } = this.props
    return (
      <div className="app">
        <ErrorBoundary>
          <ViewerProvider>
            <AppHeader fixed>
              <div className="max-width flex">
                <DefaultHeader />
              </div>
            </AppHeader>
            <div className="app-body">
              <AppSidebar display="lg" className="d-none">
                <DefaultSidebar />
              </AppSidebar>
              <main className="main">
                <DefaultBreadCrumb />
                <Notification inApp={true} />
                <Container fluid>
                  <div className="max-width">
                    {/* <ComponentRouter /> */}
                    {
                      flags.assetNavigationTab ?
                        <AssetView />
                        :
                        null

                    }
                  </div>
                  <ButterToast trayPosition="top-center" />
                </Container>
              </main>
              <AppAside />
              {/* Whenever right side hand side menu is necessary can be switched on <AppAside fixed hidden><DefaultAside /></AppAside>*/}
            </div>
            <AppFooter>
              <DefaultFooter />
            </AppFooter>
          </ViewerProvider>
        </ErrorBoundary>
      </div>
    )
  }
}

export default withLDConsumer()(Asset)
