import React, {Component} from 'react'
import {Route, Redirect} from 'react-router-dom'
import {Query} from 'react-apollo'
import {Queries} from './ProtectedRouteGQL'
import Consts from '../../constants'

export default class ProtectedRoute extends Component {
  render() {
    const {...rest} = this.props
    return (
      <Query query={Queries.GET_AUTHENTICATION_STATUS}>
        {({data, loading, client}) => {
          if (!localStorage.getItem(Consts.AUTH_TOKEN)) {
            return <Redirect to="/login" />
          }

          if (!loading && data.AuthenticationStatus.expired) {
            return <Redirect to="/login" />
          }

          return <Route {...rest} />
        }}
      </Query>
    )
  }
}
