
import React from 'react'
import {Redirect} from 'react-router-dom'
import {Query} from 'react-apollo'

import {Queries} from './ErrorBoundaryGQL'
import Errors from '../../errors'
import Error from '../Error'


class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isInError: false,
      errors: [],
    }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      isInError: true,
      errors: [...this.state.errors, Object.assign({}, {...Errors.ReactError}, {stack: error.stack})],
    })
  }

  render() {
    if (this.state.isInError) {
      const err = this.state.errors[0]
      switch (err.name) {
        case Errors.AuthenticationError.name:
        case Errors.AuthorizationError.name: {
          return <Redirect to="/login" />
        }
        default: {
          return <Error error={err} />
        }
      }
    } else {
      return (
        <Query query={Queries.GET_ERROR_STATE}>
          {({data, loading}) => {
            if (!loading && data.ErrorState && data.ErrorState.isInError) {
              this.setState({isInError: true, errors: [...this.state.errors, data.ErrorState.error]})
            }

            return this.props.children
          }}
        </Query>
      )
    }
  }
}

export default ErrorBoundary
