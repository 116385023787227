import React, {Component} from 'react'

import SelfTestedPublicAssetDetail from './SelfTestedPublicAssetDetail'

class SelfTestedPublicAssetDetailView extends Component{
    render(){
        return(
            <SelfTestedPublicAssetDetail />
        )
    }
}

export default SelfTestedPublicAssetDetailView
