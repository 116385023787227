import React, { Component } from 'react'
import { Card, CardBody, CardHeader, Button, Table } from 'reactstrap'
import { translate } from 'react-i18next'
import { compose, graphql } from 'react-apollo'
import { Queries } from './SafetyStatusGQL'
import { withRouter } from 'react-router-dom'
import Spinner from '../../base/Spinner'

class LatestProjectResultsCard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isMobile: false
        }
    }
    updatePredicate = () => {
        this.setState({
            isMobile: window.innerWidth <= 768
        })
    }
    componentDidMount() {
        this.updatePredicate()
        window.addEventListener("resize", this.updatePredicate)
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.updatePredicate)
    }

    render() {
        const hasQueryStarted = this.props.projectsQuery !== null
        const hasQueryFinished = hasQueryStarted ? !this.props.projectsQuery.loading : false
        if (!hasQueryFinished) {
            return (
                <React.Fragment>
                    <Card className="latest-project-results-card1">
                        <Spinner />
                    </Card>
                </React.Fragment>
            )
        } else {
            const { t } = this.props
            const { isMobile } = this.state
            const data = this.props.projectsQuery.userProjects.projects

            return (
                <React.Fragment>
                    <Card className="latest-project-results-card1">
                        <CardHeader>
                            <div className="latest-project-Results-header">{t('latestProjectResults')}</div>
                        </CardHeader>
                        <CardBody>
                            <Table responsive striped className="latest-project-results-table">
                                <thead>
                                    <tr>
                                        {
                                            !isMobile && <th className="date">{t('date')}</th>
                                        }
                                        <th className="project-name">{t('projectName')}</th>
                                        <th className="assets">{t('assets')}</th>
                                        {
                                            !isMobile && <th className="error-rate-pct">{t('errorRatePct')}</th>
                                        }
                                        <th className="passed">{t('passed')}</th>
                                        {
                                            !isMobile && <th className="with-comments">{t('withComments')}</th>
                                        }

                                        <th className="failed">{t('failed')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map(function (item, i) {
                                        const percentage = (item.statistics.totalExported && item.statistics.totalFailed ?
                                            (item.statistics.totalFailed / item.statistics.totalExported * 100) : 0).toFixed(2)
                                        const passedAssets = item.statistics.totalExported - item.statistics.totalFailed - item.statistics.totalWithComments
                                        return (
                                            <tr key={item.id}>
                                                {
                                                    !isMobile && <td>
                                                        {item.testDates.split("-")[0]}
                                                    </td>
                                                }

                                                <td>
                                                    {item.caption}
                                                </td>
                                                <td>
                                                    {item.statistics.totalExported}
                                                </td>
                                                {
                                                    !isMobile && <td>{percentage} %</td>
                                                }
                                                <td>
                                                    {passedAssets}
                                                </td>
                                                {
                                                    !isMobile && <td> {item.statistics.totalWithComments}</td>
                                                }
                                                <td>
                                                    {item.statistics.totalFailed}
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </CardBody>
                        <div className="d-flex justify-content-center mb3">
                            <Button type="button" outline color="primary" className={'btn'} onClick={() => this.props.history.push('/projects')}>{t('seeallprojectresults')}</Button>
                        </div>
                    </Card>
                </React.Fragment>
            )
        }
    }
}

export default translate('dashboard')(compose(
    graphql(Queries.PROJECTS_QUERY, {
        name: 'projectsQuery',
        options: (
            {
                variables: {
                    params: {
                        pager: { page: 0, pageSize: 5 },
                    },
                },
            }),
    }),
)(withRouter(LatestProjectResultsCard)))
