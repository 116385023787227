import gql from 'graphql-tag'

/* QUERIES */

const IS_ASSET_TESTED = gql`
query($assetId: String!){
  isAssetTested(assetId:$assetId)
}
`

const ASSET_QUERY = gql`
 query{
     assets{
        location
        count
        failed
     }
 }
`

const FAILEDASSETS_QUERY = gql`
 query{
     failedAssets{
        id
        type
        location
        testDate
        failureReason
     }
 }
`
const DUEDATES_QUERY = gql`
 query{
  dueDates{
    year
    dueDateMonths{
      month
      dueDates{
        site
        count
      }
    }
  }
 }
`
const Queries = {
  ASSET_QUERY,
  FAILEDASSETS_QUERY,
  DUEDATES_QUERY,
  IS_ASSET_TESTED
}

const Mutations = {}

export { Queries, Mutations }
