import TagManager from 'react-gtm-module'

export default class GoogleTagManager {
  constructor(Config) {
    if (GoogleTagManager.instance) {
      return GoogleTagManager.instance
    }

    this.initialize(Config.googleTagManagerKey, Config.googleTagManagerAuth, Config.googleTagManagerPreview)

    GoogleTagManager.instance = TagManager
    return TagManager
  }

  initialize = (key, auth, preview) => {
    const tagManagerArgs = {
      gtmId: key || 'UA-0000000-0',
      auth: auth || 'na',
      preview: preview || 'na',
      dataLayerName: 'info'
    }

    TagManager.initialize(tagManagerArgs)
  }
}