export default class Consts {
  static get SERVER_ERROR() { return 'srvErrMsg' }
  static get SHOW_WOOTRIC() { return 'SHOW_WOOTRIC' }
  static get USER_EMAIL() { return 'USER_EMAIL' }
  static get AUTH_TOKEN() { return 'CCP_AUTH_TOKEN' }
  static get I18N_LANGUAGE() { return 'I18N_LANGUAGE' }
  static get CONTACT_ROLE() { return 'CONTACT' }
  static get NO_ANSWER_GERMAN() { return 'Nein' }
  static get PROJECT() { return 'project' }
  static get TEST_INTERVAL() { return 'testInterval' }
  static get INVALID_CLASS_NAME() { return 'invalid' }
  static get DISPLAY_NONE() { return 'dn' }
  static get DISPLAY_BLOCK() { return 'db' }
  static get ASSET_ID() { return 'assetId' }
  static get ASSET_TYPE() { return 'assetType' }
  static get MANUFACTURER() { return 'manufacturer' }
  static get SITE() { return 'site' }
  static get BUILDING() { return 'building' }
  static get FLOOR() { return 'floor' }
  static get ROOM() { return 'room' }
  static get PROVISION() { return 'ProvisionInspection' }
  static get SELFTESTING() { return 'SelfTesting'}
  static get PUBLICASSETPAGE(){ return 'PublicAssetPage'}

  static get ACTIVATION_LINK_STATE() {
    return Object.freeze({
      VALID: 'Valid',
      EXPIRED: 'Expired',
      ACTIVATED: 'Activated',
    })
  }
  static get ENTITIES() {
    return Object.freeze({
      SalesPerson: 'SalesPerson',
      Branch: 'Branch',
    })
  }
}
