import React, { useState, useEffect } from "react";
import { Label } from "reactstrap";
import axios from 'axios';
import { Config } from "../../config";
import Consts from "../../constants";

function AssetFeedback({ assetId, t, id }) {
 
    const [istested, setIstested] = useState(false);

    useEffect(() => {
        SetQueryIsTested(assetId);
    }, [assetId]);
 

    const getData = async (asset) => {        

        const ASSET_QUERY = `{isAssetTested(assetId:"${asset}")}`;
        try{
         
            const { data } = await axios(
                {
                    method: "POST",
                    url:Config.gqlHttp,
                    data: JSON.stringify({ query: ASSET_QUERY }),          
                    headers: {  
                        "Content-Type": "application/json", 
                        "Authentication": `Bearer ${localStorage.getItem(Consts.AUTH_TOKEN)}`
                    }
                });
                
                if (data)
                {                   
                    console.log('asset ', asset,  ' is tested: ', data.data && data.data.isAssetTested);
                    setIstested(data.data && data.data.isAssetTested);
                      
                }
                else
                {
                    console.log( "no data returned");
                }                 
         
        }
        catch(e) {
            console.log('err:', e)
            }
    
    };

    const SetQueryIsTested = (asset) => { 
    
        if (asset && asset.trim().length > 0){
            getData(asset);
        }
    

  };

  return (
    <Label id={id} style={{ color: "red" }}>
      {istested && t('errors.assetAlreadyTested')}
    </Label>
  );
}
export default AssetFeedback;